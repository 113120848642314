
import React, { useState, useRef } from "react";
import { Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import SwitchBox from '../../pages/Profiler/SwitchBox';
import Header from './Header';
import Sidebar from './Sidebar';

export const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '4px',
            backgroundColor: '#CAD0D9',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#FFFF',
            borderRadius: '15px',
            outline: '4px solid #FFFF',
        },

    },
}));
function DashBordLayout(props) {
    const style = useStyles();
    const [contextMenu, setContextMenu] = useState(false);
    return (
        <Grid
            style={{ height: '100%', width: '100%', backgroundColor: '#F4F5F7', }}>
            <Header openProfile={() => { setContextMenu(true) }} />
            <Grid style={{ height: `calc(100% - 90px)`, margin: '12px 8px', display: 'flex', flexDirection: 'row' }}>
                <Sidebar />
                <Grid container style={{ height: '100%', overflow: 'auto', margin: '0px 12px' }}>
                    {props.children}
                </Grid>
            </Grid>
            <SwitchBox test={contextMenu} close={() => setContextMenu(!contextMenu)} />
        </Grid>
    );
}

export default DashBordLayout;
