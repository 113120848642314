import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import StarRateIcon from '@mui/icons-material/StarRate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VideoThumbnail from '../../assets/dashboard/VideoThumbnail.png';
import CommonUtil from '../../Util/CommonUtils';

export default function EventItem(props) {
  let info = props.info || {};
  return (
    <Grid
      style={{
        height: '115px',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '6px 0px',
        alignItems: 'stretch',
      }}
    >
      <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Grid
          style={{
            backgroundColor: '#36C96D',
            margin: '4px 8px',
            width: '120px',
            height: '90px',
            color: '#FFFFF',
            fontFamily: 'cursive',
            fontSize: '38px',
            borderRadius: '4px',
            opacity: '0.7',
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={VideoThumbnail}
            alt=''
          />
        </Grid>
        <Grid>
          <Typography style={{ color: '#304669', fontSize: '16px' }}>{CommonUtil.getLocalDate(info.createdAt)}</Typography>
          <Typography style={{ color: '#96A1B3', fontSize: '12px' }}>front door device</Typography>
          <Typography style={{ color: '#96A1B3', fontSize: '12px' }}>Site A</Typography>
          <Typography style={{ color: '#96A1B3', fontSize: '12px' }}>A kitchen zone...</Typography>
          <Typography style={{ color: '#304669', fontSize: '16px' }}>Motion detection</Typography>
        </Grid>
      </Grid>

      <Grid style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: '12px',
          }}
        >
          <CircleIcon
            style={{
              fontSize: '8px',
              color: 'red',
              marginRight: '8px',
            }}
          ></CircleIcon>
          <StarRateIcon
            style={{
              fontSize: '10px',
              color: '#36C96D',
            }}
          ></StarRateIcon>
        </Grid>
        <Grid
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <ArrowForwardIosIcon
            onClick={() => {
              props.itemClick('xx');
            }}
            sx={{ color: '#304669' }}
            fontSize='12'
          ></ArrowForwardIosIcon>
        </Grid>
      </Grid>
    </Grid>
  );
}
