import { Box, Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReactComponent as Eye } from '../../assets/eye.svg';
import { ReactComponent as Eye2 } from '../../assets/Eye2.svg';
import CustomInput from '../../components/CustomInput';
import MessageConfirm from '../../components/MessageConfirm';
import { REACT_LOGIN, REACT_RESET_PASSWORD_PROFILE_SUCCESS } from '../../constants/ReactEndPoints';
import { loggedUser, updatePassword } from '../../slices/User';
import CommonUtil from '../../Util/CommonUtils';

export default function ResetPasswordProfile(props) {
  const [visibility, setVisibility] = React.useState(true);
  const [visibilityCP, setVisibilityCP] = React.useState(true);
  const [visibilityOP, setVisibilityOP] = React.useState(true);
  const [payload, setPayload] = React.useState({ user: '', oldPassword: '', newPassword: '', confirmPassword: '' });
  const [error, setError] = React.useState({ oldPassword: '', newPassword: '', confirmPassword: '', common: '' });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [success, setSuccess] = React.useState(false);
  const logged = useSelector((state) => state.user.logged);

  useEffect(() => {
    if (localStorage.getItem('USER')) {
      dispatch(loggedUser());
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(logged)) {
      setPayload({
        ...payload,
        user: logged.id,
      });
    }
  }, [logged]);

  const handleClick = () => {
    history.push(REACT_LOGIN);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
      common: '',
    });
  };

  const validatePayload = () => {
    if (CommonUtil.isEmptyString(payload.oldPassword)) {
      setError({
        ...error,
        oldPassword: t('SIOT00001'),
      });
      return false;
    } else if (!CommonUtil.isValidPassword(payload.oldPassword)) {
      setError({
        ...error,
        oldPassword: t('SIOT00028'),
        common: t('SIOT00069'),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(payload.newPassword)) {
      setError({
        ...error,
        newPassword: t('SIOT00001'),
      });
      return false;
    } else if (!CommonUtil.isValidPassword(payload.newPassword)) {
      setError({
        ...error,
        newPassword: t('SIOT00028'),
        common: t('SIOT00069'),
      });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: t('SIOT00001'),
      });
      return false;
    } else if (!CommonUtil.isValidPassword(payload.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: t('SIOT00028'),
        common: t('SIOT00069'),
      });
      return false;
    }
    if (payload.newPassword !== payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t('SIOT00071'),
      });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validatePayload()) {
      dispatch(updatePassword(payload))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0005') {
            setSuccess(true);
            props.ScreenChangeTo('ResetPassword_Successfull', 'Default');
          } else {
            if (data.code === 'UASE0023') {
              setError({
                ...error,
                oldPassword: t('SIOT00070'),
              });
            } else {
              setError({
                ...error,
                newPassword: '',
                confirmPassword: '',
                common: data.message,
              });
            }
            dispatch(loggedUser());
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const FieldCurrentPassword = (key) => (
    <CustomInput
      required
      type={visibilityOP ? 'password' : 'text'}
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={error.oldPassword}
      name='oldPassword'
      label={t('SIOT00065')}
      value={payload.oldPassword}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.oldPassword}
      validation='password'
      InputProps={{
        endAdornment: visibilityOP ? (
          <Eye2
            onClick={() => {
              setVisibilityOP(!visibilityOP);
            }}
          />
        ) : (
          <Eye
            onClick={() => {
              setVisibilityOP(!visibilityOP);
            }}
          />
        ),
      }}
    />
  );

  const FieldConfirmPassword = (key) => (
    <CustomInput
      required
      type={visibility ? 'password' : 'text'}
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={error.newPassword}
      name='newPassword'
      label={t('SIOT00066')}
      value={payload.newPassword}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.newPassword}
      validation='password'
      InputProps={{
        endAdornment: visibility ? (
          <Eye2
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ) : (
          <Eye
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ),
      }}
    />
  );

  const FieldReConfirmPassword = (key) => (
    <CustomInput
      required
      type={visibilityCP ? 'password' : 'text'}
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={error.confirmPassword}
      name='confirmPassword'
      label={t('SIOT00067')}
      value={payload.confirmPassword}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.confirmPassword}
      validation='password'
      InputProps={{
        endAdornment: visibilityCP ? (
          <Eye2
            onClick={() => {
              setVisibilityCP(!visibilityCP);
            }}
          />
        ) : (
          <Eye
            onClick={() => {
              setVisibilityCP(!visibilityCP);
            }}
          />
        ),
      }}
    />
  );

  const ResetPassowrd = (key) => (
    <>
      <Grid style={{ margin: '4px 0px 24px 0px' }}>
        <Grid className='app-text-header'>{'Reset password,'}</Grid>
      </Grid>
      {FieldCurrentPassword()}
      <br />
      {FieldConfirmPassword()}
      <br />
      {FieldReConfirmPassword()}
      <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff', margin: '8px 0px' }}>
        <a href='ResetPasswordProfileSuccessPage'></a>
        {t('Reset Password')}
      </Button>

      <Grid item xs={12}>
        <Box display='flex' justifyContent='center' m={1} p={1}>
          <Grid style={{ color: 'red' }}>{error.common}</Grid>
        </Box>
      </Grid>
    </>
  );
  return <>{success ? <MessageConfirm header='Confirmation,' message='Your password has been successfully reset.' /> : ResetPassowrd()}</>;
}
