import React, { useEffect, useState } from 'react';
import { Grid, Link, Divider, Button, Typography, Avatar } from '@mui/material';
import { ReactComponent as IotLogo } from '../../assets/UserIcon.svg';
import { styled } from '@mui/material/styles';
import { REACT_LOGIN } from '../../constants/ReactEndPoints';
import { filterUser } from '../../slices/User';
import { useDispatch } from 'react-redux';
import CommonUtil from '../../Util/CommonUtils';
import defaultProfile from '../../assets/defaultProfile.png';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import TranslateIcon from '@mui/icons-material/Translate';
import SettingsIcon from '@mui/icons-material/Settings';
import ConfirmDialog from '../../components/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProfileMain(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState();
  const [profile, setProfile] = React.useState(defaultProfile);
  const [user, setUser] = useState({
    companyCode: '',
    lastName: '',
    companyType: '',
    user_name: '',
    roleId: '',
    companyName: '',
    userId: '',
    client_id: '',
    firstName: '',
    lastLoginCompanyId: '',
    companyId: '',
    phone: '',
    roleCode: '',
    roleName: '',
    id: '',
    email: '',
  });

  const [state, setState] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    profilePhoto: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    if (localStorage.getItem('USER')) {
      setUser({
        ...user,
        ...JSON.parse(localStorage.getItem('USER')),
      });
    }
  }, [localStorage.getItem('USER')]);

  useEffect(() => {
    if (localStorage.getItem('USER')) {
      var userId = JSON.parse(localStorage.getItem('USER')).id;
      dispatch(filterUser({ ids: userId }))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0000') {
            setState({
              ...state,
              ...data.data.users[0],
            });
            if (!CommonUtil.isEmptyString(data.data.users[0].profilePhoto)) {
              setProfile('data:image/jpeg;base64,' + data.data.users[0].profilePhoto);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);


  const logout = () => {
    history.push(REACT_LOGIN);
  };

  const handleLogoutConfirmDialog = (confirm) => {
    if (confirm) {
      logout();
    }
    setOpen(false);
  };

  const CustomGrid = styled(Grid)(() => ({
    width: `calc(100% + 24px)`,
    display: 'flex',
    margin: '12px 0px',
    backgroundColor: '#F4F5F7',
    color: '#304669',
    height: '48px',
    alignItems: 'center',
    fontFamily: 'Microsoft YaHei',
    transition: '0.2s',
    marginLeft: '-12px',
    '&:hover': {
      background: '#36C96D',
      color: '#FFFF',
    },
  }));
  return (
    <Grid style={{ height: '100%', width: '100%', backgroundColor: '#F4F5F7' }}>
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <Grid style={{ width: '20%' }}>
          <Avatar alt={user.firstName} src={profile} sx={{ width: 56, height: 56 }} />
        </Grid>
        <Grid>
          <Typography style={{ fontSize: '14px', color: '#304669' }}>{user.firstName + ' ' + user.lastName}</Typography>
          <Typography style={{ fontSize: '14px', color: '#304669' }}>{user.user_name}</Typography>
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex', flexDirection: 'column', padding: '18px' }}>
        <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }} onClick={() => props.ScreenChangeTo('Current account profile', 'Default', false)}>
          Current account profile
        </Link>
        <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }}>Login to other account</Link>
        <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }} onClick={() => setOpen(true)}>
          Log out current account
        </Link>
        <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }} emailId={user.user_name} onClick={() => { props.ScreenChangeTo('Reset password', 'Default', false) }}>Reset this account password</Link>
      </Grid>
      <Divider style={{ margin: '12px 0px' }} />
      <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <Grid style={{ width: '20%' }}>
          <IotLogo />
        </Grid>
        <Grid>
          <Typography style={{ fontSize: '14px', color: '#97A2B4' }}>Current organization</Typography>
          <Typography style={{ fontSize: '16px', color: '#304669' }}>{user.companyName}</Typography>
          <Typography style={{ fontSize: '14px', color: '#97A2B4' }}>You are</Typography>
          <Typography style={{ fontSize: '16px', color: '#304669' }}>{user.roleCode}</Typography>
        </Grid>
      </Grid>
      <Grid>
        {['Organization profile', 'Switch organization', 'Create organization'].map((item) => (
          <CustomGrid
            onClick={() => {
              props.ScreenChangeTo(item.toString(), item === 'Organization profile' ? 'HalfScreen' : 'Default', false);
            }}
          >
            <Button style={{ width: '22%' }}>
              <CorporateFareIcon style={{ color: '#304669' }} />
            </Button>
            <Grid style={{ textDecoration: 'none', cursor: 'pointer', fontFamily: 'Microsoft YaHei' }}>{item} </Grid>
          </CustomGrid>
        ))}
        <Divider />

        <CustomGrid onClick={() => props.ScreenChangeTo('Switch language', 'Default', false)}>
          <Button style={{ width: '22%' }}>
            <TranslateIcon style={{ color: '#304669' }} />
          </Button>
          <Grid style={{ textDecoration: 'none', cursor: 'pointer', fontFamily: 'Microsoft YaHei' }}>Switch language</Grid>
        </CustomGrid>
        <CustomGrid
        >
          <Button style={{ width: '22%' }}>
            <SettingsIcon style={{ color: '#304669' }} />
          </Button>
          <Grid style={{ textDecoration: 'none', cursor: 'pointer', fontFamily: 'Microsoft YaHei' }}>Settings</Grid>
        </CustomGrid>
      </Grid>
      <ConfirmDialog
        PrimaryButton={t('SIOT00063')}
        SecondaryButton={t('SIOT00064')}
        open={open}
        onClick={(confirm) => handleLogoutConfirmDialog(confirm)}
        message={t('SIOT00062')}
      />
    </Grid>
  );
}
export default ProfileMain;
