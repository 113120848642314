
import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function Sidebarcontent(props) {
    const [highlight, setHighlight] = React.useState('');
    const CustomSidebarGrid = styled(Grid)(() => ({
        fontSize: '18px',
        color: '#304669',
        padding: '12px',
        margin: '4px 0px',
        cursor: 'pointer',
    }));

    const About = (key) => (
        <>
            <Grid style={{ fontSize: '22px', padding: '34px 0px', color: '#304669' }}>Me</Grid>
            {['Device List', 'Album', 'About'].map(item => <CustomSidebarGrid style={{ backgroundColor: highlight == item ? '#F4F5F7' : "#FFFF" }} onClick={() => setHighlight(item)}>{item} </CustomSidebarGrid>)}
        </>
    );
    const OrganizationManagement = () => (
        <>
            <Grid style={{ fontSize: '22px', padding: '34px 0px', color: '#304669' }}> Organization management</Grid>
            {['Group or department', 'Person', 'Administrator', 'Site management'].map(item => <CustomSidebarGrid style={{ backgroundColor: highlight == item ? '#F4F5F7' : "#FFFF" }} onClick={() => setHighlight(item)}>{item} </CustomSidebarGrid>)}
        </>
    )
    return (
        <Grid style={{ padding: '4px' }}>
            {props.content === 'About' ? About() : props.content === 'Organizaton' ? OrganizationManagement() : <></>}
        </Grid>
    );
}