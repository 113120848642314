import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
const CustomGrid = styled(Grid)(() => ({
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
    display: 'flex',
    flexDirection: 'row',
    margin: '12px 0px',
    backgroundColor: '#F4F5F7',
    borderBottom: '0.5px solid #C0C0C0',
    color: '#304669',
    cursor: 'pointer'
}));
export default function SwitchLanguage(props) {
    const [language, Setlanguage] = React.useState('Global English');
    const handleClose = () => {
        props.ScreenChangeTo('DefaultProfile', 'Default')
    }
    function CustomeRadio(props) {
        return (
            <Grid style={{ width: '20px', height: '20px', backgroundColor: '#36c96d', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', display: props.languages === language ? 'flex' : 'none' }}>
                <CheckIcon fill='#FFFF' style={{ width: '16px', height: '16px', color: '#FFFF' }} />
            </Grid>
        );
    }
    const handlelanguage = (item) => {
        Setlanguage(item)
    };

    return (
        <Grid style={{ backgroundColor: '#F4F5F7' }}>
            {['Global English', '简体中文', '繁体中文-Taiwan'].map(item => <CustomGrid onClick={() => handlelanguage(item)}>
                <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', minWidth: '100%' }}>
                    <Typography>{item}</Typography>
                    <CustomeRadio languages={item} />
                </Grid>
            </CustomGrid>)}
        </Grid>
    );
}
