import React, { useState, useEffect } from "react";
import ic_cloud_storage from "../../assets/dashboard/ic_cloud_storage.png";
import ic_smart_detection from "../../assets/dashboard/ic_smart_detection.png";
import { Grid, Card, Typography } from "@mui/material";
export default function Subscribe(props) {
  return (
    <Grid>
      <Card sx={{ padding: "10px",display:'flex' ,alignItems: 'center'}}>
        <Grid>
          <img alt="" src={ic_cloud_storage}></img>
        </Grid>
        <Grid sx={{
            marginLeft:'10px'
        }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            Cloud event
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#000000",
              marginTop: "10px",
            }}
          >
            Safely store events in the cloud and view at any time without fear
            of device loss.
          </Typography>
        </Grid>
      </Card>
      <Card sx={{ marginTop: "30px", display:'flex',alignItems: 'center', padding: "10px" }}>
        <Grid>
          <img alt="" src={ic_smart_detection}></img>
        </Grid>
        <Grid sx={{
            marginLeft:'10px'
        }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            Smart detection
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#000000",
              marginTop: "10px",
            }}
          >
            Event types and certain objects can be accurately analyzed from
            video stream.
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
}
