export default class Recorder {
  mediaRecorder = null;
  videwData = [];
  mediaStream=null
  options={}
  constructor(mediaStream, options) {
      this.mediaStream=mediaStream
      this.options = options
  }
  start(lengthInMS=1000) {
    this.mediaRecorder = new MediaRecorder(this.mediaStream, this.options);
    this.videwData = [];
    this.mediaRecorder.start(lengthInMS);
    this.mediaRecorder.ondataavailable = (e) => {
      this.videwData.push(e.data);
    };
  }
  stop() {
    this.mediaRecorder.stop();
  }
  resume() {
    //取消暂停
    this.mediaRecorder.resume();
  }
  pause() {
    this.mediaRecorder.pause();
  }
  requestData(){
    this.mediaRecorder.requestData()
  }
  download() {
    let blob = new Blob(this.videwData, {
      type: "video/mp4",
    });
    let videoUrl = window.URL.createObjectURL(blob);
    let downloadElement = document.createElement("a");
    downloadElement.href = videoUrl;
    downloadElement.download = "aa.mp4";
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    //资源释放
    window.URL.revokeObjectURL(videoUrl);
  }
}
