export const capture = (videoDom) => {
  videoDom.setAttribute("crossOrigin", "anonymous"); //添加srossOrigin属性，解决跨域问题
  var canvas = document.createElement("canvas");
  canvas.width = videoDom.clientWidth;
  canvas.height = videoDom.clientHeight;
  canvas
    .getContext("2d")
    .drawImage(videoDom, 0, 0, canvas.width, canvas.height); //截
  var dataURL = canvas.toDataURL("image/png"); //将图片转成base64格式
  var save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
  save_link.href = dataURL;
  save_link.download = "aa.png";
  var event = document.createEvent("MouseEvents");
  event.initMouseEvent(
    "click",
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  save_link.dispatchEvent(event);
};

export const downloadVideo = (href,fileName=new Date().getTime()+'.mp4') => {
    let downloadElement = document.createElement('a');
    downloadElement.href = href;
    downloadElement.download = fileName
    downloadElement.target="_blank"
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
};
