import React, { useState, useEffect } from "react";
import { Grid, Typography, Switch,Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const SwitchButton = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Trail(props) {
  return (
    <Grid>
      <Grid
        container
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#304669",
          }}
        >
          Try 7-day Cloud events storage for one month
        </Typography>
        <Grid>
          <SwitchButton sx={{ m: 1 }} defaultChecked />
        </Grid>
      </Grid>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#304669",
          marginTop:'16px'
        }}
      >
        If you do not allow this, this device will NOT upload videos to cloud
        story.
      </Typography>

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#30466980",
          marginTop:'13px'
        }}
      >
        Subscribe Cloud events storage:
      </Typography>

      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#30466980",
          marginTop:'13px'
        }}
      >
        1. Video stream of this device will be securely stored in the cloud for
        7 day.
      </Typography>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#30466980",
        }}
      >
        2. Playback the video of previous 7 days at any time within a month.{" "}
      </Typography>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: "#304669",
          marginTop:'24px'
        }}
      >
          <span style={{
              color:'#36C96D'
          }}>Subscribe</span> for more value-added features
      </Typography>
    </Grid>
  );
}
