import React, { useState, useEffect } from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import DeviceDemo2 from "../../assets/DeviceDemo2.svg";
import download from "../../assets/event/ic_download.png";
import video from "../../assets/event/btn_video.png";
import taking_pictures from "../../assets/event/btn_capture.png";
import { makeStyles } from "@material-ui/core";
import btn_mute from "../../assets/event/btn_mute.png";
import ic_voice_ban from "../../assets/dashboard/ic_voice_ban.png";
import ic_xingbiao from "../../assets/event/ic_xingbiao.png";
import { capture, downloadVideo } from "./utils/utils.js";
import Recorder from "../../Util/Recorder.js";
import { MediaPlayer } from "dashjs";
import { getStreamClip } from "../../services/EventService";

function BtnGrid(props) {
  return (
    <Grid
      sx={{
        width: "60px",
        textAlign: "center",
        fontSize: "12px",
        position: "relative",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <img
        onClick={() => {
          props.clickEvent();
        }}
        alt=""
        src={props.icon}
        style={{ height: "50px", width: "50px", borderRadius: "8px" }}
      />
      <div>{props.children}</div>
    </Grid>
  );
}

export const potint = makeStyles(() => ({
  root: {
    width: "5px",
    height: "5px",
    borderRadius: "5px",
    backgroundColor: "red",
    position: "absolute",
    top: "25px",
    left: "25px",
    animation: "$myEffect 500ms infinite",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

export default function EventVideo(props) {
  let event = props.event;
  let message = event.message;
  let eventInfo = {};
  message.split("#").forEach((item) => {
    let temp = item.split("_");
    eventInfo[temp[0]] = temp[1];
  });


  let eventDashUrl = props.eventDashUrl;
  let potintClass = potint();
  const [muted, setMuted] = useState(false);
  const [recording, setRecording] = useState(null);

  let clickEvent = (event) => {
    let eventVideo = document.getElementById("eventVideo");
    if (event === "capture") {
      capture(eventVideo);
    } else if (event === "muted") {
      let videoMuted = eventVideo.muted;
      setMuted(!videoMuted);
    } else if (event === "save") {
      getStreamClip({
        deviceId:eventInfo.deviceId,
        startTime:eventInfo.startTime*1000,
        endTime: eventInfo.endTime*1000
      }).then((res) => {
          let data = res.data
        if(data.code==="CVSI0000"){
          let result = data.data.url
          downloadVideo(result);
        }else{
          console.log(data.message)
        }
      });
    } else if (event === "video") {
      if (recording === null) {
        let recorderObj = new Recorder(eventVideo.captureStream(25), {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          mimeType: "video/webm",
        });
        recorderObj.start();
        setRecording(recorderObj);
      } else {
        recording.stop();
        recording.download();
        setRecording(null);
      }
    }
  };

  useEffect(() => {
   const video = document.querySelector("#eventVideo");
   const player = MediaPlayer().create();
   player.initialize(video, eventDashUrl, true);
  }, [eventDashUrl]);

  return (
    <Grid
      sx={{
        backgroundColor: "#ffffff",
        padding: "10px",
      }}
    >
      <Grid sx={{ flexWrap: "nowrap", marginBottom: "10px" }} container>
        <Grid>
          <img style={{
              width:'40px',
              height:'40px'
          }} src={DeviceDemo2} alt="" />
        </Grid>
        <Grid
          sx={{
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
          container
        >
          <Grid>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#304669",
              }}
            >
              front door device {muted + "xx"}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#30466980",
              }}
            >
              Site A
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#30466980",
              }}
            >
              Site A kitchen zone...
            </Typography>
            {/* <Typography
              style={{
                fontSize: '18px',
                fontWeight: 400,
                color: '#304669',
              }}
            >
              Motion detection
            </Typography> */}
          </Grid>
          <Grid
            style={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#30466980",
            }}
          >
            <Typography>09-Oct-2022</Typography>
            <Typography>12:22:22</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#000000",
          border: "1px solid #0AF861",
          boxSizing: "border-box",
        }}
      >
        <video
          id="eventVideo"
          autoPlay="autoplay"
          loop="loop"
          controls
          muted={muted}
          style={{
            width: "100%",
            height: "100%",
            maxHeight: props.videoHeight ? props.videoHeight : "350px",
          }}
        ></video>
      </Grid>
      <Grid
        container
        sx={{
          marginTop: "10px",
          justifyContent: "space-between",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <BtnGrid
          clickEvent={() => {
            clickEvent("save");
          }}
          icon={download}
        >
          Save
        </BtnGrid>
        <BtnGrid
          clickEvent={() => {
            clickEvent("video");
          }}
          icon={video}
        >
          {recording !== null ? <div className={potintClass.root}></div> : ""}
          Video
        </BtnGrid>
        <BtnGrid
          clickEvent={() => {
            clickEvent("capture");
          }}
          icon={taking_pictures}
        >
          Capture
        </BtnGrid>
        <BtnGrid
          clickEvent={() => {
            clickEvent("muted");
          }}
          icon={muted ? btn_mute : ic_voice_ban}
        >
          Mute
        </BtnGrid>
        {props.starTheEvent && (
          <BtnGrid clickEvent={() => {}} icon={ic_xingbiao}>
            Star this event
          </BtnGrid>
        )}
      </Grid>
    </Grid>
  );
}
