
import React from "react";
import { Grid } from "@mui/material";
import { ReactComponent as ReactLogo } from "../assets/body.svg";
import { ReactComponent as IotLogo } from "../assets/logo.svg";

export default function OnBordlayout(props) {
    return (
        <Grid sx={{ height: "100%", width: "100%" }}>
            <Grid sx={{ height: " 100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#fff" }}>
                <Grid sx={{
                    width: { xs: "100%", sm: "100%", md: "90%", lg: "70%" },
                    minHeight: "400px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                }}>
                    <Grid sx={{
                        width: { xs: "0%", sm: "0%", md: "50%", lg: "40%" },
                        display: { xs: "none", sm: "none", md: "block", lg: "block" },
                        position: "relative",
                        flexDirection: "column",
                        margin: "12px",
                    }}>
                        <Grid sx={{ width: "100%", display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, }}>
                            <IotLogo />
                        </Grid>
                        <Grid sx={{ width: "100%",  display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, height:'100%'}}>
                            <ReactLogo />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        width: { xs: "100%", sm: "100%", md: "50%", lg: "40%" },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        margin: "12px",
                        padding: "0px 12px",
                    }}>
                        <Grid sx={{
                            display: { xs: "block", sm: "block", md: "none", lg: "none" },
                        }}>
                            <IotLogo />
                        </Grid>
                        {props.children}
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{
                width: "100%",
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                bottom: 0,
                backgroundColor: "white",
            }}>
                <Grid sx={{
                    cursor: "default",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#7FFF00",
                    cursor: "default"
                }}>
                    Powered by
                </Grid>
                <Grid sx={{
                    cursor: "default",
                    textAlign: "center",
                    fontSize: "12px",
                    marginBottom: "12px",
                }}>
                    Copyright 2022 ZKTECO CO,LTD.ALL right resverved
                </Grid>
            </Grid>
        </Grid>

    );
}
