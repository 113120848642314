import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './Routes';
import { SnackbarProvider } from 'notistack';
import LoadingDialog from './components/LoadingDialog'
import AlterMessage from './components/AlterMessage'
function App() {
  return (
    <BrowserRouter basename='/'>
      <LoadingDialog></LoadingDialog>
      <AlterMessage></AlterMessage>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Routes />
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
