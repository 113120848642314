import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  showLoad: false,
  loadingMessage: "loading",
  showMessage: false,
  messageType: "success",
  message: "success",
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showLoad: (state, action) => {
      let info = action.payload;
      state.showLoad = info.showLoad;
      state.loadingMessage = info.loadingMessage;
    },
    showMessage: (state, action) => {
      let info = action.payload;
      state.showMessage = info.showMessage;
      state.messageType = info.messageType;
      state.message = info.message;
    },
  },
});

const { reducer } = commonSlice;
export const { showLoad, showMessage } = commonSlice.actions;
export default reducer;
