import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import DialogBox from '../../components/DilogBox';
import Playback from '../dashboard/Playback';
import Trail from './Trail';
import Setting from './Setting';
import Subscribe from './Subscribe';
import { ReactComponent as Ic_cloud_storage } from '../../assets/ic_cloud_storage.svg';
import { ReactComponent as SandClock } from '../../assets/SandClock.svg';
import { ReactComponent as Pir } from '../../assets/pir.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as DeviceDemo } from '../../assets/DeviceDemo.svg';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as Play } from '../../assets/ic_play.svg';
import { useHistory } from 'react-router-dom';
import { capture } from '../events/utils/utils';
import Recorder from '../../Util/Recorder.js';
import WebRTC from './WebRTC';
import { getSignalingChannel } from '../../services/DashboardServce';
import { makeStyles } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Successmsg from '../../components/SuccessMSG';

import { showLoad, showMessage } from '../../slices/Common';
import { useDispatch } from 'react-redux';

function BtnGrid(props) {
  return (
    <Grid
      sx={{
        width: '64px',
        textAlign: 'center',
        fontSize: '12px',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <img
        onClick={() => {
          props.clickEvent();
        }}
        alt=''
        src={props.icon}
        style={{ height: '50px', width: '50px', borderRadius: '8px' }}
      />
      <Typography
        style={{
          fontFamily: 'Bahnschrift',
          fontSize: '12px',
          color: '#000000',
          textAlign: 'center',
        }}
      >
        {' '}
        {props.lable}{' '}
      </Typography>
      {props.children}
    </Grid>
  );
}
export const useStyle = makeStyles(() => ({
  recording_indicator: {
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: 'red',
    position: 'absolute',
    bottom: '45px',
    left: '28px',
    animation: '$myEffect 500ms infinite',
  },
  play_icon_position: {
    position: 'absolute',
    backgroundColor: '#C0C0C0',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  play_icon_container: {
    position: 'relative',
    backgroundColor: '#C0C0C0',
    boxSizing: 'border-box',
    width: '100%',
  },
  disable_icon: {
    position: 'relative',
    transform: 'rotateZ(45deg)',
    width: '24px',
    bottom: '-12px',
  },
  video_frame: {
    maxHeight: '400px',
    width: '100%',
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
export default function WebRtcVideo(props) {
  const dispatch = useDispatch();
  const style = useStyle();
  const [playback, handlePlayback] = useState(false);
  const [Opensubscribe, setOpensubscribe] = useState(false);
  const [disable, setdisable] = useState(true);
  const [toggle, settoggle] = useState(false);
  const [PayIc, setPayIc] = useState(false);
  const [muted, setMuted] = useState(false);
  const [recording, setRecording] = useState(null);
  const [openTrail, setOpenTrail] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [intercom, setIntercom] = useState(true);
  const [webrtc, setWebrtc] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setmsg] = React.useState('');
  const [cameraRotate, setcameraRotate] = React.useState(false);
  const [initRtc, setInitRtc] = useState(false);

  const ic_taking_pictures = require('../../assets/dashboard/ic_taking_pictures.png');
  const ic_video = require('../../assets/dashboard/ic_video.png');

  const ic_intercom_ban = require('../../assets/dashboard/ic_intercom_ban.png');
  const ic_intercom = require('../../assets/dashboard/ic_intercom.png');
  const ic_voice_ban = require('../../assets/dashboard/ic_voice_ban.png');
  const ic_subscribe = require('../../assets/dashboard/ic_subscribe.png');
  const ic_trail = require('../../assets/dashboard/ic_trail.png');
  const ic_set = require('../../assets/dashboard/ic_set.png');
  const ic_fullscreen = require('../../assets/dashboard/ic_fullscreen.png');
  const ic_playback = require('../../assets/dashboard/ic_playback.png');
  const ic_alarm = require('../../assets/dashboard/ic_alarm.png');
  const btn_video = require('../../assets/event/btn_video.png');
  const ic_voice = require('../../assets/dashboard/ic_voice.png');

  const IcondLoop = [
    { lable: 'capture', icon: ic_taking_pictures },
    { lable: 'video', icon: ic_video },
    { lable: 'Intercom', icon: ic_intercom_ban },
    { lable: 'mute', icon: ic_voice_ban },
    { lable: 'Events', icon: ic_alarm },
  ];
  const IcondLoop2 = [
    { lable: 'playback', icon: ic_playback },
    { lable: 'FullScreen', icon: ic_fullscreen },
    { lable: 'Trail', icon: ic_trail },
    { lable: 'Subscribe', icon: ic_subscribe },
    { lable: 'Setting', icon: ic_set },
  ];
  const labelRef = React.createRef();
  const history = useHistory();
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  let clickEvent = (event) => {
    let eventVideo = document.getElementById('eventVideo');
    if (event === 'capture') {
      setOpen(true);
      setmsg('This photo has been saved to ');
      capture(eventVideo);
    } else if (event === 'video') {
      if (recording === null) {
        let recorderObj = new Recorder(eventVideo.captureStream(25), {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          mimeType: 'video/webm',
        });
        recorderObj.start();
        setRecording(recorderObj);
      } else {
        recording.stop();
        recording.download();
        setRecording(null);
      }
    } else if (event === 'Intercom') {
      let status = webrtc.getIntercomStatus();
      if (status) {
        webrtc.stopIntercom();
      } else {
        webrtc.startIntercom();
      }
      setIntercom(!status);
    } else if (event === 'muted') {
      let videoMuted = eventVideo.muted;
      setMuted(!videoMuted);
    } else if (event === 'Events') {
      history.push({ pathname: '/siot/event', search: 'deviceID=22222' });
    } else if (event === 'playback') {
      handlePlayback(true);
    } else if (event === 'FullScreen') {
      if (eventVideo.requestFullscreen) {
        eventVideo.requestFullscreen();
      } else if (eventVideo.mozRequestFullScreen) {
        eventVideo.mozRequestFullScreen();
      } else if (eventVideo.webkitRequestFullscreen) {
        /* Safari */
        eventVideo.webkitRequestFullscreen();
      } else if (eventVideo.msRequestFullscreen) {
        /* IE11 */
        eventVideo.msRequestFullscreen();
      }
    } else if (event === 'Trail') {
      setOpenTrail(true);
    } else if (event === 'Subscribe') {
      setOpensubscribe(!Opensubscribe);
    } else if (event === 'Setting') {
      setOpenSetting(true);
    }
  };

  const handleClick = (e) => {
    if (disable === true) {
      labelRef.current.style.borderTop = '0.5px solid #F0104D';
    } else {
      labelRef.current.style.borderTop = 'none';
    }
    setdisable(!disable);
  };
  const clickVideo = () => {
    let eventVideo = document.getElementById('eventVideo');
    let paused = eventVideo.paused;
    //if (!initRtc) { commented for testing purpose, once the video part start working uncomment this line
    if (false) {
      dispatch(
        showMessage({
          showMessage: true,
          messageType: 'error',
          message: 'Call not connected',
        })
      );
      return false;
    } else {
      if (paused) {
        eventVideo.play();
        setPayIc(true);
      } else {
        setPayIc(false);
        eventVideo.pause();
      }
    }
  };

  useEffect(() => {
    debugger;
    setPayIc(false);
    setInitRtc(false);
    if (webrtc) {
      webrtc.close();
    }
    dispatch(
      showLoad({
        showLoad: true,
        loadingMessage: 'Loading Channel',
      })
    );
    getSignalingChannel({
      deviceId: props.device.deviceId,
      channelRole: 'VIEWER',
    })
      .then((res) => {
        if (res.status === 200 && res.data.code === 'CVSI0000') {
          try {
            let result = res.data.data;
            let config = {};
            config.endpoints = result.endpoints.reduce((endpoints, endpoint) => {
              endpoints[endpoint.protocol] = endpoint.resourceEndpoint;
              return endpoints;
            }, {});
            config.signalingChannel = result.signalingChannel;
            config.iceServers = result.iceServers;
            config.tempCredential = result.tempCredential;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            let eventVideo = document.getElementById('eventVideo');
            let rtcObj = new WebRTC(config, eventVideo);
            rtcObj.setConnectionCallback(() => {
              setPayIc(true);
              setInitRtc(true);
            });
            rtcObj.init();
            setWebrtc(rtcObj);
          } catch (error) {
            dispatch(
              showMessage({
                showMessage: true,
                messageType: 'error',
                message: error,
              })
            );
          }
        } else {
          let message = res.message || res.data.message;
          dispatch(
            showMessage({
              showMessage: true,
              messageType: 'error',
              message: message,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          showMessage({
            showMessage: true,
            messageType: 'error',
            message: e.message,
          })
        );
      })
      .finally(() => {
        dispatch(
          showLoad({
            showLoad: false,
            loadingMessage: '',
          })
        );
      });
  }, [props.device]);

  return (
    <Grid
      sx={{
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        padding: '4px 0px',
      }}
    >
      <Grid container sx={{ flexWrap: 'nowrap' }}>
        <Grid container>
          <Grid
            sx={{
              width: '50px',
              display: 'flex',
              alignItems: 'center',
              ml: '10px',
              margin: '0px 12px',
            }}
          >
            <DeviceDemo />
          </Grid>
          <Grid>
            <Typography style={{ color: '#304669', fontSize: '14px' }}>{props.DemoInfo.siteA}</Typography>
            <Typography style={{ color: '#96A1B3', fontSize: '12px' }}>{props.DemoInfo.siteB}</Typography>
            <Typography style={{ color: '#96A1B3', fontSize: '12px' }}>{props.DemoInfo.siteC}</Typography>
          </Grid>
        </Grid>

        <Grid
          sx={{
            fontSize: '14px',
            color: '#96a1b3',
            width: '100%',
            textAlign: 'end',
          }}
        >
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Typography sx={{ fontSize: '16px', alignItems: 'center', color: '#304669', margin: '0px 8px' }}>{props.DemoInfo.Internetspeed}</Typography>
            <Typography sx={{ fontSize: '16px', alignItems: 'center', color: '#304669', margin: '0px 8px' }}>{props.DemoInfo.size}</Typography>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Grid
                style={{
                  width: '18px',
                  height: '12px',
                  padding: '2px',
                  backgroundColor: '#FFFF',
                  border: '0.5px solid #304669',
                  marginBottom:'2px'
                }}
              >
                <Grid
                  style={{
                    height: '100%',
                    width: `${props.DemoInfo.battery}`,
                    backgroundColor: '#304669',
                  }}
                ></Grid>
              </Grid>
              <Grid
                style={{
                  backgroundColor: '#304669',
                  height: '8px',
                  width: '1.7px',
                  marginBottom:'2px'
                }}
              ></Grid>
              <Typography sx={{ fontSize: '16px', margin: '0px 6px', color: '#304669' }}>{props.DemoInfo.battery}</Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              mt: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
              <SandClock style={{ margin: '4px 0px', height: '14px', width: '16px' }}  />
            <Typography sx={{ fontSize: '16px', margin: '0px 6px', color: '#304669' }}>{props.DemoInfo.duration}</Typography>
            <Grid onClick={handleClick}>
              <Grid ref={labelRef} className={style.disable_icon} />
              <Ic_cloud_storage style={{ height: '24px', width: '24px' }} />
            </Grid>
            <Pir  style={{ margin: '2px 6px', height: '20px', width: '20px'}} />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={style.play_icon_container}>
        <Grid className={style.play_icon_position} display={!PayIc ? 'flex' : 'none'}>
          <Play onClick={() => clickVideo('test')} />
        </Grid>

        <Grid container>
          <video autoPlay='autoplay' className={style.video_frame} muted={muted} onClick={clickVideo} id='eventVideo' loop='loop' style={{ maxHeight: '400px', width: '100%' }} />
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {toggle ? (
          <Grid
            style={{
              height: '100%',
              alignSelf: 'center',
              transform: 'rotate(90deg)',
              display: PayIc ? 'block' : 'none',
            }}
          >
            <IconButton disableRipple onClick={() => setcameraRotate(false)}>
              {' '}
              <Arrow
                style={{
                  margin: '0px 12px',
                  fill: cameraRotate ? 'black' : '#C0C0C0',
                }}
              />
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
        {PayIc ? (
          <Grid container style={{ backgroundColor: '#FFFFF' }}>
            {cameraRotate ? (
              <Grid
                style={{
                  textAlign: 'center',
                  width: '100%',
                  margin: '22px 0px',
                }}
              >
                <Grid container justifyContent='space-around'>
                  <Arrow
                    style={{
                      fill: 'rgb(54 201 109)',
                      transform: 'rotate(180deg)',
                    }}
                  />
                </Grid>
                <Grid container justifyContent='center' alignItems='center'>
                  <Grid>
                    <Arrow
                      style={{
                        fill: 'rgb(54 201 109)',
                        transform: 'rotate(90deg)',
                      }}
                    />
                  </Grid>
                  <Grid style={{ margin: '38px 76px' }} />
                  <Grid>
                    <Arrow
                      style={{
                        fill: 'rgb(54 201 109)',
                        transform: 'rotate(270deg)',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent='space-around'>
                  {' '}
                  <Arrow style={{ fill: 'rgb(54 201 109)' }} />
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent='space-around' margin='16px 0px'>
                {IcondLoop.map((item) =>
                  item.lable === 'video' ? (
                    <BtnGrid
                      clickEvent={() => {
                        clickEvent(item.lable);
                      }}
                      key={item.lable}
                      icon={btn_video}
                      lable={item.lable.toUpperCase()}
                      children={recording !== null ? <div className={style.recording_indicator} /> : ''}
                    />
                  ) : item.lable === 'mute' ? (
                    <BtnGrid
                      clickEvent={() => {
                        clickEvent(item.lable);
                      }}
                      key={item.lable}
                      icon={muted ? ic_voice : ic_voice_ban}
                      lable={item.lable.toUpperCase()}
                    />
                  ) : item.lable === 'Intercom' ? (
                    <BtnGrid
                      clickEvent={() => {
                        clickEvent(item.lable);
                      }}
                      key={item.lable}
                      icon={intercom ? ic_intercom_ban : ic_intercom}
                      lable={item.lable.toUpperCase()}
                    />
                  ) : (
                    <BtnGrid key={item.lable} clickEvent={() => clickEvent(item.lable)} icon={item.icon} lable={item.lable.toUpperCase()} />
                  )
                )}

                {toggle ? (
                  <Grid container justifyContent='space-around' margin='8px 0px'>
                    {IcondLoop2.map((item) => (
                      <BtnGrid key={item.lable} clickEvent={() => clickEvent(item.lable)} icon={item.icon} lable={item.lable.toUpperCase()} />
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            )}
          </Grid>
        ) : (
          <></>
        )}

        {PayIc ? (
          <Grid
            style={
              !cameraRotate
                ? {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: toggle ? '0px' : '8px',
                }
                : { alignSelf: 'center', marginTop: '-16px' }
            }
          >
            {cameraRotate ? (
              <></>
            ) : (
              <MoreVertIcon
                onClick={() => settoggle(!toggle)}
                style={{
                  width: '2em',
                  height: '2em',
                  fill: toggle ? '#C0C0C0' : '#36C96D',
                  alignSelf: 'center',
                  margin: '12px',
                }}
              />
            )}

            {toggle ? (
              <IconButton disableRipple onClick={() => setcameraRotate(true)}>
                {' '}
                <Arrow
                  style={{
                    marginTop: '12px',
                    fill: cameraRotate ? '#C0C0C0' : 'black',
                    transform: 'rotate(270deg)',
                  }}
                />{' '}
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <DialogBox size={'HalfScreen'} open={playback} HeaderContent={'Playback'} close={() => handlePlayback()} children={playback ? <Playback /> : ''} />
      <DialogBox open={Opensubscribe} HeaderContent={'Subscription'} close={() => setOpensubscribe(!Opensubscribe)} children={Opensubscribe ? <Subscribe /> : ''} />

      <DialogBox open={openTrail} HeaderContent={'Subscription'} close={() => setOpenTrail(false)} children={openTrail ? <Trail /> : ''} />

      <DialogBox open={openSetting} HeaderContent={'Setting'} close={() => setOpenSetting(false)} children={openSetting ? <Setting /> : ''} />

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{ position: 'fixed', top: '70px', right: '30px' }}>
          <Successmsg msg={msg} path={'/Downloads'} />
        </Alert>
      </Snackbar>
    </Grid>
  );
}
