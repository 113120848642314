import axios from 'axios';

const axiosHelper = (headers) => {
  return axios.create({
    headers: headers,
  });
};

const securedAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization: localStorage.getItem('USER_TOKEN'),
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
  });
};

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
};

export default api;
