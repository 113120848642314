import { SignalingClient, Role } from "amazon-kinesis-video-streams-webrtc";
import CommonUtil from "../../Util/CommonUtils";
import { getEnumerateDevices } from "./LiveService";


export default class ViewerSDK {
  _endpointsByProtocol = null;
  _peerConnection = null;
  signalingClient = null;
  localStream = null;
  isSetting = false;
  callback = null;

  channelARN = "";
  role = "";
  region = "";
  accessKeyId = "";
  secretAccessKey = "";
  iceServers = [];
  localView = null;
  remoteView = null;
  sessionToken = "";
  config = {};

  /**
   * 
   * @param {*} config Basic parameters acquired by cvs
   * @param {*} remoteView Display the view tag of remote video
   * @param {*} localView Display the view tag of the local video
   * @param {*} callback 
   */
  constructor(config, remoteView, localView, callback) {
    this.remoteView = remoteView;
    this.localView = localView;
    this.callback = callback;

    this.channelARN = config.signalingChannel.channelARN;
    this.role = Role.VIEWER;
    let tempCredential = config.tempCredential;
    this.region = tempCredential.region;
    this.accessKeyId = tempCredential.accessKeyId;
    this.sessionToken = tempCredential.sessionToken;
    this.secretAccessKey = tempCredential.secretAccessKey;
    this._endpointsByProtocol = config.endpoints;
    const iceServers = [];
    config.iceServers.forEach((iceServer) => {
      if (iceServer.password) {
        iceServers.push({
          urls: iceServer.uris,
          username: iceServer.username,
          credential: iceServer.password,
        });
      } else {
        iceServers.push(iceServer);
      }
    });
    this.iceServers = iceServers;
    this.config = config;
  }

  // Create RTCPeerConnection
  createRTCPeerConnection() {
    if (this.iceServers && this.iceServers.length === 0) {
      console.error("Please obtain iceServices first");
      return false;
    }
    const peerConnection = new RTCPeerConnection({
      iceServers: this.iceServers,
      iceTransportPolicy: "all", //Specify the transmission policy of ICE. Delay: only relay candidates are used. All: any type can be used
    });
    
    this._peerConnection = peerConnection;
    return this;
  }

  //Create signaling channel client
  createWebRTCSignalingClient() {
    let signalingClient = new SignalingClient({
      channelARN: this.channelARN,
      channelEndpoint: this._endpointsByProtocol.WSS,
      clientId: CommonUtil.getRandomClientId(),
      role: this.role,
      region: this.region,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
        sessionToken: this.sessionToken,
      },
    });
    this.signalingClient = signalingClient;
    return this;
  }

  addSignalingClientEventListeners() {
    if (this.signalingClient === null) {
      console.error("Please create the signaling client first");
      return false;
    }
    this.signalingClient.on("open", async () => {
      // Get a stream from the webcam, add it to the peer connection, and display it in the local view
      try {
        const devices = await getEnumerateDevices();
        const resolution = { width: { ideal: 640 }, height: { ideal: 400 } };
        let constraints = devices.reduce(
          (info, device) => {
            if (!device) {
              return info;
            }
            if ("videoinput" === device.kind) {
              return { video: resolution, audio: info.audio };
            }
            if ("audioinput" === device.kind) {
              return { audio: true, video: info.video };
            }
            return info;
          },
          { video: false, audio: false }
        );

        if (this.callback) {
          this.callback({
            type: "constraints",
            result: constraints,
          });
        }

        let navigator =CommonUtil.getNavigator();
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise
          .then((stream) => {
            this.localStream = stream;
            stream.getTracks().forEach((track) => {
              this._peerConnection.addTrack(track, stream);
            });
            if (this.localView) {
              this.localView.srcObject = stream;
            }
          })
          .catch((err) => {
            if (this.callback) {
              this.callback({
                type: "localstream",
                result: "error",
              });
            }
            console.error(err.name + ": " + err.message);
          });
      } catch (e) {
        console.error("[VIEWER] Could not find webcam");
        return;
      }

      // Create an SDP offer and send it to the master
      const offer = await this._peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });

      await this._peerConnection.setLocalDescription(offer);
      this.signalingClient.sendSdpOffer(this._peerConnection.localDescription);
    });
    // When the SDP answer is received back from the master, add it to the peer connection.
    this.signalingClient.on("sdpAnswer", async (answer) => {
      if (!this.isSetting) {
        this.isSetting = true;
        await this._peerConnection.setRemoteDescription(answer);
      }
    });

    // When an ICE candidate is received from the master, add it to the peer connection.
    this.signalingClient.on("iceCandidate", (candidate) => {
      this._peerConnection.addIceCandidate(candidate);
    });

    this.signalingClient.on("close", () => {
      console.log("Handle client closures");
      // Handle client closures
    });

    this.signalingClient.on("error", (error) => {
      console.error(error);
      // Handle client errors
    });
    return this;
  }

   //Add Peer Connection Event Listeners
  addPeerConnectionEventListeners() {
    this._peerConnection.addEventListener("icecandidate", (info) => {
      let { candidate } = info;
      if (candidate) {
        this.signalingClient.sendIceCandidate(candidate);
      } else {
        // No more ICE candidates will be generated
      }
    });

    // As remote tracks are received, add them to the remote view
    this._peerConnection.addEventListener("track", (event) => {
      if (this.remoteView === null || this.remoteView.srcObject) {
        return;
      }
      this.remoteView.srcObject = event.streams[0];
      if (this.callback) {
        this.callback({
          type: "romoteTrack",
          result: {},
        });
      }
    });
    return this;
  }

  openSignalingConnection() {
    if (this.signalingClient === null) {
      console.error("Please create the signaling client first");
      return false;
    }
    this.signalingClient.open();
    return this;
  }

  stopIntercom() {
    if (this.localStream) {
      var track = this.localStream.getAudioTracks()[0];
      if (track) {
        track.enabled = false;
      }
    }
  }

  startIntercom() {
    if (this.localStream) {
      var track = this.localStream.getAudioTracks()[0];
      if (track) {
        track.enabled = true;
      }
    }
  }

  mute() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    this.remoteView.muted = true;
  }

  noMute() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    this.remoteView.muted = false;
  }

  close() {
    if (this.signalingClient === null) {
      return false;
    }
    if (this._peerConnection) {
      this._peerConnection.close();
    }
    this.signalingClient.onClose();
    this.signalingClient = null;
    return this;
  }

  capture() {
    var player = this.remoteView; //获取video的Dom节点
    player.setAttribute("crossOrigin", "anonymous"); //添加srossOrigin属性，解决跨域问题
    var canvas = document.createElement("canvas");
    canvas.width = player.clientWidth;
    canvas.height = player.clientHeight;
    canvas
      .getContext("2d")
      .drawImage(player, 0, 0, canvas.width, canvas.height); //截
    var dataURL = canvas.toDataURL("image/png"); //将图片转成base64格式
    var save_link = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "a"
    ); //有效的内部空间URI
    save_link.href = dataURL;
    save_link.download = "aa.png";
    var event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    save_link.dispatchEvent(event);
  }

  fullScreen() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    var ele = this.remoteView;
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
      ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullScreen) {
      ele.webkitRequestFullScreen();
    }
  }

  exitFullscreen() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    var de = this.remoteView;
    if (de.exitFullscreen) {
      de.exitFullscreen();
    } else if (de.mozCancelFullScreen) {
      de.mozCancelFullScreen();
    } else if (de.webkitCancelFullScreen) {
      de.webkitCancelFullScreen();
    }
  }

  init() {
    this.createWebRTCSignalingClient();
    this.createRTCPeerConnection();
    this.addSignalingClientEventListeners();
    this.addPeerConnectionEventListeners();
    this.openSignalingConnection();
  }
}
