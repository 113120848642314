import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/User';
import companyReducer from './slices/Company';
import customToaster from './slices/CustomToaster';
import common from './slices/Common';


const reducer = {
  user: userReducer,
  company: companyReducer,
  toast: customToaster,
  common:common
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
