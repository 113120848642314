import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteAndZoneService from "../services/SiteAndZoneService";

const initialState = [];


export const getAllSite = createAsyncThunk('site/filter', async () => {
  const res = await SiteAndZoneService.getAllSite();
  return res.data;
});

export const getAllZone = createAsyncThunk('zone/', async (payload) => {
    const res = await SiteAndZoneService.getAllZone(payload);
    return res.data;
  });

  const zoneSlice = createSlice({
    name: 'zone',
    initialState,
  });

  const { reducer } = zoneSlice;
export default reducer;