import api from "../config/http-common";

export const getEvents = (params) => {
  return api.securedAxios().get("/device/event", {
    params: params,
  });
};

export const getDashUrl = (params) => {
  return api.securedAxios().post("/stream/dash", params);
};

export const getStreamClip = (params) => {
  return api.securedAxios().get("/stream/clip", {
    params: params,
  });
};
