import React from 'react';
import OnBordlayout from '../../components/OnBordlayout';
import LoginForm from './LoginForm';

function Login() {
  return (
    <OnBordlayout>
      <LoginForm />
    </OnBordlayout>
  );
}

export default Login;
