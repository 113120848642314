import * as React from 'react';
import { Grid, IconButton } from '@mui/material';
import { ReactComponent as Site } from '../../assets/Site.svg';
import { ReactComponent as Events } from '../../assets/Events.svg';
import { ReactComponent as Organization } from '../../assets/Organization.svg';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as DeviceDemo2 } from '../../assets/DeviceDemo2.svg';
import { ReactComponent as About } from '../../assets/About.svg';
import Slidarcontent from './Sidebarcontent'
import { useHistory, useLocation } from 'react-router';
export default function Sidebar(props) {
    let history = useHistory();
    let location = useLocation();
    const [contextMenu, setContextMenu] = React.useState(true);
    const [Selected, setSelected] = React.useState(0);
    const [SlidebarICone, setSlidebarICone] = React.useState([
        { icon: Site, tile: 'Site', path: '/siot/dashboard' },
        { icon: Events, tile: 'event', path: '/siot/event' },
        { icon: Organization, tile: 'Organizaton', path: '/siot/organizaton' },
        { icon: About, tile: 'About', path: '/siot/about' },
        { icon: DeviceDemo2, tile: 'device', path: '/siot/device' } 
    ]);
    return (
        <Grid
            maxWidth={contextMenu ? '78px' : '100%'}
            style={{
                display: 'flex',
                padding: '8px 0px',
                height: "100%",
                backgroundColor: '#fff',
                borderRadius: '3px'
            }}>
            <Grid style={{ display: 'flex', flexDirection: 'column', width: '58px' }}>
                {SlidebarICone.map((item, index) => <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton
                        disableRipple
                        onClick={() => { (item.tile == 'event') || (item.tile == 'Site') ? setSelected(item.tile) || setContextMenu(true) || history.push(item.path) : setSelected(item.tile) || history.push(item.path) }}
                        color="primary"
                        style={{
                            backgroundColor: (Selected === item.tile) || (location.pathname === item.path) ? '#36C96D' : '',
                            color: Selected === item.tile ? '#ffff' : '',
                            display: 'flex',
                            borderRadius: '0px',
                            width: '100%',
                            padding:'16px 0px'
                        }}>
                        <item.icon
                            fill={(Selected === item.tile) || (location.pathname === item.path) ? '#FFFF' : '#97A2B3'}
                            style={{
                                color: (Selected === item.tile) || (location.pathname === item.path) ? '#FFFF' : '#97A2B3',
                                width: '42px',
                                height: '24px'
                            }} />
                    </IconButton>
                </Grid>
                )}
            </Grid>
            <IconButton
                onClick={() => (Selected == 'Organizaton') || (Selected == 'About') ? setContextMenu(!contextMenu) : null}
                style={{
                    left: contextMenu ? '48px' : '260px',
                    backgroundColor: '#FFFF',
                    alignSelf: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '32px',
                    width: '32px',
                    borderRadius: '50%',
                    position: 'fixed'
                }} >
                <Arrow fill={'#304669'} style={{ transform: `rotate(${contextMenu ? 270 : 90}deg)`, height: '14px', width: '14px' }} />
            </IconButton>
            <Grid style={{ display: contextMenu ? "none" : "flex", minWidth: '214px', height: '100%' }}>
                <Grid style={{ display: contextMenu ? "none" : "block", width: '4px', backgroundColor: '#F4F5F7', height: `calc(100% + 16px)`, marginTop: '-8px' }} />
                <Grid style={{ backgroundColor: '#fff', width: '100%', padding: '0px 4px' }}>
                    <Slidarcontent
                        content={Selected}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
