import React, { useState, useEffect } from "react";
import { Role } from "amazon-kinesis-video-streams-webrtc";
import { Grid } from "@mui/material";
import { getSignalingChannel } from "../../services/DashboardServce";
import Button from "@material-ui/core/Button";
import MasterSDK from "./MasterSDK";
import TextField from "@mui/material/TextField";
export default function ViewerPage() {
  const [deviceId, setDeviceId] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const getParams = () => {
    if (deviceId === "") {
      setError(true);
      setHelperText("Please enter deviceId");
      return false;
    }
    getSignalingChannel({
      deviceId: deviceId,
      channelRole: Role.MASTER,
    }).then((res) => {
      if (res.status === 200) {
        let result = res.data;
        if (result.code === "CVSI0000") {
          let config = {};
          let rtcInfo = result.data;
          config.endpoints = rtcInfo.endpoints.reduce((endpoints, endpoint) => {
            endpoints[endpoint.protocol] = endpoint.resourceEndpoint;
            return endpoints;
          }, {});
          config.signalingChannel = rtcInfo.signalingChannel;
          config.iceServers = rtcInfo.iceServers;
          config.tempCredential = rtcInfo.tempCredential;
          let eventVideo = document.getElementById("webRtcVideo");
          let webrtc = new MasterSDK(config, eventVideo);
          webrtc.init();
        } else {
          console.error(result.message);
        }
      } else {
        console.error(res);
      }
    });
  };
  return (
    <Grid
      sx={{
        width: "100%",
        padding: "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <video
          autoPlay="autoplay"
          id="webRtcVideo"
          loop="loop"
          style={{
            width: "50%",
            border: "1px solid gray",
          }}
        />
      </Grid>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          alignItems: 'flex-start'
        }}
      >
        <div>
          <TextField
            error={error}
            helperText={helperText}
            placeholder="Please enter deviceId"
            label=""
            onChange={(e) => {
              setDeviceId(e.target.value);
              setError(false);
              setHelperText("");
            }}
            onFocus={() => {
              setError(false);
              setHelperText("");
            }}
            value={deviceId}
          />
        </div>

        <Button
          style={{
            marginLeft: "20px",
            padding: '15px'
          }}
          onClick={() => {
            getParams();
          }}
          variant="contained"
        >
          start
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        When simulating a webrtc device to push video, you must ensure that the
        device ID are the same to achieve webrtc video communication
      </Grid>
    </Grid>
  );
}
