import React from 'react';
import { Grid, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

export default function DeleteBox(props) {
  const { t } = useTranslation();
  const styleDialog = { '& .MuiDialog-paper': { backgroundColor: '#F4F5F7', right: '24px', bottom: '24px', position: 'absolute', boxShadow: 1, maxWidth: 'none', width: '314px' } };
  return (
    <Dialog sx={styleDialog} open={props.open}>
      <Grid sx={{ padding: '4px' }}>
        <Grid sx={{ padding: '12px', justifyContent: 'center', display: 'flex', fontSize: '18px', color: '#304669' }}> {t('SIOT00061')}</Grid>
        <Grid sx={{ padding: '12px', justifyContent: 'center', display: 'flex', fontSize: '15px', color: '#304669' }}> {props.message}</Grid>
        <Grid
          xs={12}
          md={8}
          sm={8}
          sx={{ textAlign: { xs: 'center', sm: 'center', md: 'center' }, margin: '16px 0px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}
        >
          <Button variant='contained' style={{ backgroundColor: '#FFFF', color: '#36C96D', border: '0.5px solid #36C96D' }} onClick={() => props.onClick(false)}>
            {props.SecondaryButton ? props.SecondaryButton : t('SIOT00059')}
          </Button>
          <Button type='submit' variant='contained' style={{ backgroundColor: '#36C96D', color: '#FFFF' }} onClick={() => props.onClick(true)}>
            {props.PrimaryButton ? props.PrimaryButton : t('SIOT00058')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
