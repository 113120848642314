import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core";
export const weekBarStyles = makeStyles(() => ({
  week: {
    width: "42px",
    height: "26px",
    lineHeight: "26px",
    fontSize: "20px",
    fontFamily: "Microsoft YaHei",
    fontWeight: 400,
    color: "#B3B3B3",
    opacity: 1,
  },
  date: {
    width: "42px",
    height: "36px",
    lineHeight: "36px",
    opacity: 1,
    borderRadius: "3px",
    color: "#304669",
    fontSize: "20px",
    fontFamily: "Microsoft YaHei",
    "&:hover": {
      cursor: "pointer",
    },
    boxSizing:'border-box'
  },
  active: {
    color: "#36C96D",
    border: "1px solid #36C96D",
  },
}));


export default function WeekBar(props) {
  const classs = weekBarStyles();
  const [dates, setDates] = React.useState([]);
  let date = props.date;
  let currenDate = date ? new Date(date.getTime()) : new Date();
  let timesStamp = currenDate.getTime();
  let currenDay = currenDate.getDay();
  let weeks = ['SU','MO','TU','WE','TH','FR','SA']
  useEffect(() => {
    var dates = [];
    for (var i = 0; i < 7; i++) {
      let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - currenDay));
      dates.push(date);
    }
    setDates(dates);
  }, [date]);
  const dateClick = (item) => {
    props.dateClick(item);
  };
  return (
    <Grid container sx={{ justifyContent: "space-between" }}>
      {dates.map((item) => {
        return (
          <Grid
            onClick={() => {
              dateClick(item);
            }}
            key={item.getTime()}
            sx={{ textAlign: "center" }}
          >
            <div className={classs.week}>{weeks[item.getDay()]}</div>
            <div
              className={`${
                item.getDate() === currenDate.getDate() ? classs.active : ""
              } ${classs.date}`}
            >
              {item.getDate()}
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
