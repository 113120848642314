import { SignalingClient, Role } from "amazon-kinesis-video-streams-webrtc";

const getRandomClientId = () => {
  return Math.random().toString(36).substring(2).toUpperCase();
};

const getEnumerateDevices = async () => {
  try {
    const devices = await global.navigator.mediaDevices.enumerateDevices();
    return devices;
  } catch (errMsg) {
    return [];
  }
};

const getNavigator = () => {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // 首先，如果有getUserMedia的话，就获得它
      var getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.getUserMedia ||
        navigator.msGetUserMedia;
      // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // 否则，为老的navigator.getUserMedia方法包裹一个Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
  return navigator;
};

export default class WebRTC {
  _endpointsByProtocol = null;
  _peerConnection = null;
  signalingClient = null;
  localStream = null;
  isSetting = false;

  channelARN = "";
  role = "";
  region = "";
  accessKeyId = "";
  secretAccessKey = "";
  iceServers = [];
  remoteView = null;
  sessionToken = "";
  config = {};

  connectionCallback = () => {};

  constructor(config, remoteView) {
    this.remoteView = remoteView;
    this.channelARN = config.signalingChannel.channelARN;
    this.role = Role.VIEWER;
    let tempCredential = config.tempCredential;
    this.region = tempCredential.region;
    this.accessKeyId = tempCredential.accessKeyId;
    this.sessionToken = tempCredential.sessionToken;
    this.secretAccessKey = tempCredential.secretAccessKey;
    this._endpointsByProtocol = config.endpoints;
    const iceServers = [];
    config.iceServers.forEach((iceServer) => {
      if (iceServer.password) {
        iceServers.push({
          urls: iceServer.uris,
          username: iceServer.username,
          credential: iceServer.password,
        });
      } else {
        iceServers.push(iceServer);
      }
    });
    this.iceServers = iceServers;
    this.config = config;
  }

  setConnectionCallback(connectionCallback) {
    this.connectionCallback = connectionCallback;
  }

  // create RTCPeerConnection
  createRTCPeerConnection() {
    if (this.iceServers && this.iceServers.length === 0) {
      console.error("请先获取iceServices");
      return false;
    }
    const peerConnection = new RTCPeerConnection({
      iceServers: this.iceServers,
      iceTransportPolicy: "relay",
    });
    this._peerConnection = peerConnection;
    return this;
  }

  //创建信令通道客户端
  createWebRTCSignalingClient() {
    let signalingClient = new SignalingClient({
      channelARN: this.channelARN,
      channelEndpoint: this._endpointsByProtocol.WSS,
      clientId: getRandomClientId(),
      role: this.role,
      region: this.region,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
        sessionToken: this.sessionToken,
      },
    });
    this.signalingClient = signalingClient;
    return this;
  }

  addSignalingClientEventListeners() {
    if (this.signalingClient === null) {
      console.error("请先创建信令客户端");
      return false;
    }
    this.signalingClient.on("open", async () => {
      try {
        const devices = await getEnumerateDevices();
        let constraints = devices.reduce(
          (info, device) => {
            if (!device) {
              return info;
            }
            if ("videoinput" === device.kind) {
              return { video: false, audio: info.audio };
            }
            if ("audioinput" === device.kind) {
              return { audio: true, video: info.video };
            }
            return info;
          },
          { video: false, audio: false }
        );

        let navigator = getNavigator();
        let promise = navigator.mediaDevices.getUserMedia(constraints);
        promise
          .then((stream) => {
            this.localStream = stream;
            stream.getTracks().forEach((track) => {
              this._peerConnection.addTrack(track, stream);
            });
          })
          .catch((err) => {
            console.error(err.name + ": " + err.message);
          });
      } catch (e) {
        console.error("[VIEWER] Could not find webcam");
        return;
      }

      // Create an SDP offer and send it to the master
      const offer = await this._peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      });

      await this._peerConnection.setLocalDescription(offer);

      this.signalingClient.sendSdpOffer(this._peerConnection.localDescription);
    });
    // When the SDP answer is received back from the master, add it to the peer connection.
    this.signalingClient.on("sdpAnswer", async (answer) => {
      if (!this.isSetting) {
        this.isSetting = true;
        await this._peerConnection.setRemoteDescription(answer);
      }
    });

    // When an ICE candidate is received from the master, add it to the peer connection.
    this.signalingClient.on("iceCandidate", (candidate) => {
      this._peerConnection.addIceCandidate(candidate);
    });

    this.signalingClient.on("close", () => {
      // Handle client closures
    });

    this.signalingClient.on("error", (error) => {
      console.error(error);
      // Handle client errors
    });
    return this;
  }

  //Add Peer Connection Event Listeners
  addPeerConnectionEventListeners() {
    this._peerConnection.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        this.signalingClient.sendIceCandidate(candidate);
      } else {
        // No more ICE candidates will be generated
      }
    });

    // As remote tracks are received, add them to the remote view
    this._peerConnection.addEventListener("track", (event) => {
      if (this.remoteView === null || this.remoteView.srcObject) {
        return;
      }
      this.remoteView.srcObject = event.streams[0];
      if (this.connectionCallback) {
        this.connectionCallback();
      }
    });
    return this;
  }

  openSignalingConnection() {
    if (this.signalingClient === null) {
      console.error("请先创建信令客户端");
      return false;
    }
    this.signalingClient.open();
    return this;
  }

  //获取麦克风状态
  getIntercomStatus() {
    var tracks = this.localStream.getAudioTracks();
    var track = tracks[0];
    return track.enabled;
  }

  //停止麦克风
  stopIntercom() {
    if (this.localStream) {
      var track = this.localStream.getAudioTracks()[0];
      if (track) {
        track.enabled = false;
      }
    }
  }

  //开启麦克风
  startIntercom() {
    if (this.localStream) {
      var track = this.localStream.getAudioTracks()[0];
      if (track) {
        track.enabled = true;
      }
    }
  }

  //静音
  mute() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    this.remoteView.muted = true;
  }

  //解除静音
  noMute() {
    if (this.remoteView === null) {
      console.error("error");
      return false;
    }
    this.remoteView.muted = false;
  }

  close() {
    if (this.signalingClient === null) {
      console.error("请先创建信令客户端");
      return false;
    }
    this.signalingClient.close();
  }

  //截图
  capture() {
    var player = this.remoteView; //获取video的Dom节点
    player.setAttribute("crossOrigin", "anonymous"); //添加srossOrigin属性，解决跨域问题
    var canvas = document.createElement("canvas");
    canvas.width = player.clientWidth;
    canvas.height = player.clientHeight;
    canvas
      .getContext("2d")
      .drawImage(player, 0, 0, canvas.width, canvas.height); //截
    var dataURL = canvas.toDataURL("image/png"); //将图片转成base64格式
    var save_link = document.createElementNS(
      "http://www.w3.org/1999/xhtml",
      "a"
    ); //有效的内部空间URI
    save_link.href = dataURL;
    save_link.download = "aa.png";
    var event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    save_link.dispatchEvent(event);
  }
  init() {
    this.remoteView.srcObject = null;
    this.createWebRTCSignalingClient();
    this.createRTCPeerConnection();
    this.addSignalingClientEventListeners();
    this.addPeerConnectionEventListeners();
    this.openSignalingConnection();
  }
}
