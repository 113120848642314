import { Grid, Radio, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loggedUser } from '../../slices/User';
import CommonUtil from '../../Util/CommonUtils';
import { ReactComponent as IotLogo } from '../../assets/UserIcon.svg';
import { Box } from '@mui/system';
import OverflowTip from '../Profiler/tooltip';
import Organization from '../../assets/dashboard/organization.png';

export default function SwitchSite(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const logged = useSelector((state) => state.user.logged);
  const [user, setUser] = useState({});
  const [sites, setSItes] = useState([
    { id: '0001', code: '0001', name: 'Default' },
    { id: '0002', code: '0002', name: 'Bangalore RnD' },
  ]);
  const [Selectedsite, setSite] = useState('');


  useEffect(() => {
    if (localStorage.getItem('USER')) {
      dispatch(loggedUser());
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(logged)) {
      setUser({
        ...user,
        ...logged,
      });
    }
  }, [logged]);
  return (
    <Grid style={{ height: '100%', width: '100%', backgroundColor: '#F4F5F7' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <Grid marginRight={3}>
          <img src={Organization} width='103px' height={'74px'} />
        </Grid>
        <Grid>
          <Typography style={{ fontSize: '14px', color: '#97A2B4' }}>Current organization</Typography>
          <Typography style={{ fontSize: '16px', color: '#304669' }}>{user.companyName}</Typography>
          <Typography style={{ fontSize: '14px', color: '#97A2B4' }}>You are</Typography>
          <Typography style={{ fontSize: '16px', color: '#304669' }}>{user.roleCode}</Typography>
        </Grid>
      </Grid>
      <Grid>
        <Typography style={{ fontSize: '15px', color: '#97A2B4' }}>{t('SIOT00068')}</Typography>
      </Grid>
      <Grid>
        {sites.map((site) => {
          return (
            <Box
              display='flex'
              alignItems={'center'}
              width='100%'
              borderRadius='5px'
              marginTop={2}
              paddingTop={1}
              paddingBottom={1}
              onClick={() => { setSite(site.name); }}
              bgcolor={site.name === Selectedsite ? '#36C96D1A' : '#FFFF'}   // ' #F4F5F7 0.5px solid',
              border={site.name === Selectedsite ? '#7fff00 0.5px solid' : '1px solid #CAD0D9'} >
              <Radio
                checked={Selectedsite === site.name}
                onChange={() => setSite(site.name)}
                size='small' sx={{ color: '#36C96D', '&.Mui-checked': { color: '#36C96D' } }} />
              <OverflowTip
                value={site.name}
                style={{
                  fontFamily: 'Bahnschrift ',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '178px',
                  fontSize: '18px',
                  color: '#304669',
                  margin: '4px 4px',
                }}
              />
            </Box>
          );
        })}
      </Grid>

    </Grid>
  );
}
