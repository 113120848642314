import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

export const potint = makeStyles(() => ({
    root: {
        width: '100%'
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
}));

export default function Successmsg(props) {
    const classes = potint();
    useEffect(() => {
    }, []);
    return (
        <Grid className={classes.root}>
            <Grid
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                <Typography>{props.msg.toString()}</Typography>
                <Typography>{props.path.toString()}</Typography>

            </Grid>
        </Grid>
    );
}
