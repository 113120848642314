import api from '../config/http-common';
import CommonUtil from '../Util/CommonUtils';

const getAll = () => {
  return api.securedAxios().get('/company');
};

const get = (id) => {
  return api.securedAxios().get(`/company/${id}`);
};

const create = (data) => {
  return api.securedAxios().post('/company', data);
};

const filterCompany = (payload) => {
  var url = '/company?pageNumber=1&pageSize=1000';
  if (!CommonUtil.isEmpty(payload)) {
    if (!CommonUtil.isEmptyString(payload.id)) url = url + '&companyId=' + payload.id;
  }
  return api.securedAxios().get(url);
};
const updateCompanyProfile = (payload) => {
  return api.securedAxios().put(`/company?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&companyId=${payload.id}`, payload);
};

const verifyCompany = (data) => {
  return api.securedAxios().put(`/company/switch`, data);
};

const updateCompany = (payload) => {
  return api.securedAxios().put(`/company/profile/` + payload.id, payload);
};
const CompanyService = {
  getAll,
  get,
  create,
  updateCompanyProfile,
  verifyCompany,
  filterCompany,
  updateCompany,
};

export default CompanyService;
