import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as IotLogo } from '../../assets/logo.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loggedUser } from '../../slices/User';

const styleImag = {
  width: '24px',
  height: '24px',
  border: '0.5px solid #ffff',
  borderRadius: '50%',
  backgroundColor: '#5383ff',
  margin: '0px 12px',
  color: '#ffff',
};
export default function Header(props) {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.user.logged);
  const [user, setUser] = useState({
    companyCode: '',
    lastName: '',
    companyType: '',
    user_name: '',
    roleId: '',
    companyName: '',
    userId: '',
    client_id: '',
    firstName: '',
    lastLoginCompanyId: '',
    companyId: '',
    phone: '',
    roleCode: '',
    roleName: '',
    id: '',
    email: '',
  });
  useEffect(() => {
    if (localStorage.getItem('USER')) {
      dispatch(loggedUser());
    }
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      ...logged,
    });
  }, [logged]);
  return (
    <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '12px', alignItems: 'center', backgroundColor: '#fff' }}>
      <IotLogo style={{ height: '32px', width: '212px' }} />
      <Grid style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        {user.companyName}
        <AccountCircleIcon style={styleImag} onClick={() => props.openProfile(true)} />
        <span onClick={() => props.openProfile(true)}>{user.firstName + ' ' + user.lastName}</span>
        <Grid style={{ color: '#304669', alignSelf: 'center', backgroundColor: '#fff', padding: '0px 10px', marginTop: '6px', borderRadius: '50%' }}>
          <Arrow style={{ height: '14px', width: '14px', transform: `rotate270deg` }} onClick={() => props.openProfile(true)} />
        </Grid>
      </Grid>
    </Grid>
  );
}
