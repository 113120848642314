import React from 'react';
import OnBordlayout from '../components/OnBordlayout';
import { Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { REACT_LOGIN } from '../constants/ReactEndPoints';

export default function MessageConfirm(props) {
  const history = useHistory();

  return (
    <>
      <OnBordlayout>
        <Typography variant='h4' component='div' gutterBottom style={{ color: '#474A4E' }}>
          {props.header}
        </Typography>
        <br />
        <Typography variant='subtitle1' gutterBottom component='div'>
          {props.message}
        </Typography>

        <br></br>
        <Button style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }} onClick={() => history.push(REACT_LOGIN)}>
          Back to Login
        </Button>
      </OnBordlayout>
    </>
  );
}
