export const REACT_LOGIN = '/siot/login';
export const REACT_SIGNUP = '/siot/signup';
export const REACT_SIGNUP_AGREE = '/siot/signup-aggrement';
export const REACT_CREATE_ORG = '/siot/create-organization';
export const REACT_SELECT_ORG = '/siot/select-organization';
export const REACT_SELECT_ORG_TYPE = '/siot/select-organization-type';
export const REACT_EVENTS = '/siot/events';

export const REACT_DASHBORD = '/siot/dashboard';
export const REACT_EVENT = '/siot/event';
export const REACT_FORGOT_PASSWORD = '/siot/forgot-password';
export const REACT_FORGOT_PASSWORD_SUCCESS = '/siot/forgot-password-success';
export const REACT_RESET_PASSWORD = '/siot/reset-password';
export const REACT_RESULT_PAGE = '/siot/result-page';
export const REACT_RESET_PASSWORD_PROFILE = '/siot/reset-password';
export const REACT_RESET_PASSWORD_PROFILE_SUCCESS = '/siot/reset-success';
export const REACT_USER_ACTIVATION ='/siot/user-activation';

export const REACT_SURVEILLANCE_DEVICE_LIST = '/siot/z/device-list';
export const REACT_SURVEILLANCE_PAYMENTPAGE = '/siot/z/payment'
export const REACT_URL_SURVEILLANCE_SUBSCRIPTION='/siot/z/surveillances/subscription';
export const REACT_SURVEILLANCE_SUBSCRIPTION__ORDERLISTANDBILLING='/siot/z/surveillances/orderlist-billing';
export const REACT_URL_SURVELLANCE_DESCRIPTION='/siot/z/susurveillances/information';
export const REACT_URL_SURVELLANCE_UNPAIDSUBSCRIPTION = '/siot/z/unpaid-subscription';
export const REACT_URL_SURVELLANCE_VIEWORDERLIST = '/siot/z/view-orderlist';
export const REACT_URL_SURVELLANCE_PAYMENTSUCCESSPAGE = '/siot/z/payment-success';
export const REACT_URL_SURVELLANCE_ASSIGNDEVICEPAGE = '/siot/z/assign-device';
export const REACT_URL_SURVELLANCE_PAYFORSUBSCRIPTIION = '/siot/z/pay-for-subscription';
