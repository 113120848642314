import api from '../config/http-common';
const getSubscriptionList = (payload) => {
  return api.securedAxios().get('/subscription/subscription_list?company_id=' + payload.company_id + '&pageNumber=1&pageSize=999');
};

const createSubscription = (payload) => {
  return api.securedAxios().post('/subscription/create_subscription', payload);
};

const paypalPayment = (payload) => {
  return api.securedAxios().post('/subscription/paypal', payload);
};

const getPricestrategiesList = () => {
  return api.securedAxios().get('/subscription/priceStrategies?pageNumber=1&pageSize=10');
};

const getPriceByPlanName = (payload) => {
  return api.securedAxios().get('/subscription/priceStrategies?pageNumber=1&pageSize=10&name=' + payload.name);
};

const cancelSubscription = (payload) => {
  return api.securedAxios().patch('/subscription/' + payload.id, payload);
};

const SubscriptionService = {
  getSubscriptionList,
  createSubscription,
  getPricestrategiesList,
  cancelSubscription,
  getPriceByPlanName,
  paypalPayment,
};

export default SubscriptionService;
