import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import DeviceDemo from "../../assets/DeviceDemo.svg";
import { makeStyles } from "@material-ui/core";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const filterClass = makeStyles(() => ({
  checked: {
    color: "#36C96D !important",
  },
}));

export default function AdvancedFilter() {
  const [eventType, setEventType] = useState("");
  const [selectCamera, setSelectCamera] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  let list = [];
  for (let i = 0; i < 20; i++) {
    list.push({
      id: "deviceId" + i,
      name: "ZC10-998" + i,
      site: "Site a kitchen" + i,
      zone: "office entrance zone",
    });
  }
  const [cameraList, setCameraList] = useState(list);
  let classes = filterClass();
  return (
    <Grid>
      {selectCamera ? (
        <Grid>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            sx={{
              overflow: "auto",
              height: `calc(${windowDimensions.height + "px"} - 200px)`,
            }}
          >
            {cameraList.map((value) => (
              <Grid
                sx={{
                  width: "45%",
                }}
                key={value.id}
                item
              >
                <Card
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: "10px",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    onChange={(e) => {}}
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "0px",
                      color: "rgba(0, 0, 0, 0.2)",
                    }}
                    classes={{
                      checked: classes.checked,
                    }}
                  />
                  <img
                    src={DeviceDemo}
                    alt=""
                    style={{
                      width: "68px",
                      height: "68px",
                    }}
                  />

                  <Typography style={{ color: "#304669", fontSize: "16px" }}>
                    {value.name}
                  </Typography>
                  <Typography style={{ color: "#96A1B3", fontSize: "12px" }}>
                    {value.site}
                  </Typography>
                  <Typography style={{ color: "#96A1B3", fontSize: "12px" }}>
                    {value.zone}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => {
                setSelectCamera(false);
              }}
              style={{ width: "90%" }}
              variant="contained"
            >
              OK
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <div>
            <TextField
              label="Select camera"
              sx={{ minWidth: 280, width: "100%" }}
              onClick={() => {
                setSelectCamera(true);
              }}
              focused
              InputProps={{
                endAdornment: <ArrowForwardIosIcon></ArrowForwardIosIcon>,
              }}
            />
          </div>
          <FormControl sx={{ minWidth: 280, width: "100%", marginTop: "40px" }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Select event type
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              style={{ width: "100%" }}
              value={eventType}
              onChange={(event) => {
                setEventType(event.target.value);
              }}
              autoWidth
              label="Select event type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Twenty</MenuItem>
              <MenuItem value={21}>Twenty one</MenuItem>
              <MenuItem value={22}>Twenty one and a half</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
}
