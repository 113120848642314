import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DeviceService from "../services/DeviceService";

const initialState = [];

export const getDeviceList = createAsyncThunk('device/', async (filter) => {
  const res = await DeviceService.getDeviceList(filter);
  return res.data;
});
export const getDeviceListPegination = createAsyncThunk('device/', async (payload) => {
  const res = await DeviceService.getDeviceListPegination(payload);
  return res.data;
});

export const assignDeviceToUse = createAsyncThunk('device/', async (payload) => {
  const res = await DeviceService.assignDeviceToUse(payload);
  return res.data;
});
const deviceSlice = createSlice({
  name: 'device',
  initialState,
});

const { reducer } = deviceSlice;
export default reducer;