import React, { useState } from "react";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography, IconButton } from "@mui/material";
import WebRtcVideo from "./dashboard/WebRtcVideo";
import { ReactComponent as Marker } from "../assets/Marker.svg";
import { ReactComponent as DeviceDemo2 } from "../assets/DeviceDemo2.svg";
import { makeStyles } from "@material-ui/core";
import DialogBox from "../components/DilogBox";
import SwitchSite from "./dashboard/SwitchSite";
export const siteStyle = makeStyles(() => ({
  active: {
    border: "1px solid #36c96d !important",
  },
  activeZone: {
    position: "relative",
    "&:before": {
      position: "absolute",
      bottom: "-1px",
      content: "close-quote",
      width: "90%",
      left: "5%",
      display: "inline-block",
      height: "4px",
      backgroundColor: "#36c96d",
    },
  },
}));
export default function Site(props) {
  const Classs = siteStyle();
  const { t } = useTranslation();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [currentZone, setCurrentZone] = useState("");
  const [zoneList, setZoneList] = useState([
    {
      zoneName: "Default zone",
    },
    {
      zoneName: "Site A kitchen zone",
    },
    {
      zoneName: "Site A Kitchen",
    },
  ]);
  const [devices, setDevices] = useState([]);
  const [switchSite, openSwitchSite] = useState(false);
  const handleSwitchSite = () => {
    openSwitchSite(!switchSite);
  };
  const clickZone = (zone) => {
    let device = [];
    for (let i = 0; i < 20; i++) {
      device.push({
        deviceId: "deviceId" + i,
        deviceName: "deviceName" + i,
        site: "Site A场所",
        zone: zone.zoneName,
      });
    }
    setDevices(device);
    setCurrentZone(zone);
    setCurrentDevice(null);
  };
  return (
    <Grid item lg={8} style={{ width: "100%", height: "100%", padding: "1em" }}>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "6%"
        }}
      >
        <IconButton
          onClick={() => handleSwitchSite()}
          style={{
            border: "0.5px solid #36C96D",
            borderRadius: "4px",
            justifyContent: "flex-start",
            width: "30%"
          }}
        >
          {"Site A场所"}
        </IconButton>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Grid
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#E6E6E6",
              marginRight: "14px",
            }}
          >
            <FilterAltIcon />
          </Grid>
          <Grid
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#E6E6E6",
            }}
          >
            <AddIcon />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          flexWrap: "nowrap",
          height: "60px",
          alignItems: "center",
          height: "10%"
        }}
      >
        <Grid
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#E6E6E6",
          }}
        >
          <Arrow
            style={{
              height: "14px",
              width: "14px",
              transform: `rotate(90deg)`,
            }}
          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          {zoneList.map((item) => {
            return (
              <Grid
                className={item === currentZone ? Classs.activeZone : ""}
                onClick={() => {
                  clickZone(item);
                }}

              >
                <span className="app-text"> {item.zoneName}</span>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#E6E6E6",
          }}
        >
          <Arrow
            style={{
              height: "14px",
              width: "14px",
              transform: `rotate(270deg)`,
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ flexWrap: "nowrap", height: "85%" }} >
        {devices && devices.length > 0 ? (
          <>
            <Grid  lg={6} sx={{ overflow: "auto",margin:'0px 2px'}} >
              {devices.map((item) => {
                return (
                  <Grid
                    onClick={() => setCurrentDevice(item)}
                    className={item === currentDevice ? Classs.active : ""}
                    key={item.deviceName}
                    style={{
                      margin: "12px 8px",
                      height: "116px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      padding: '2px',
                    }}
                  >
                    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <DeviceDemo2 />
                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0px 10px" }}>
                      <Typography style={{ color: "#304669", fontSize: "20px" }}>
                        {item.deviceName}
                      </Typography>
                      <Typography style={{ color: "#304669", fontSize: "12px", opacity: "0.5" }}>
                        {item.site}
                      </Typography>
                      <Typography style={{ color: "#304669", fontSize: "12px", opacity: "0.5" }}>
                        {item.zone}
                      </Typography>
                    </Grid>
                    <Grid style={{ width: "40px", height: "40px" }}>
                      <Marker />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid sx={{  width: "100%" ,marginLeft:'12px'}}>
              {currentDevice ? (
                <WebRtcVideo
                  device={currentDevice}
                  DemoInfo={{
                    siteA: "front door device",
                    siteB: "Site A",
                    siteC: "Site A kitchen zone...",
                    Internetspeed: "200 kb/s      ",
                    size: " 25MB",
                    battery: "70%",
                    duration: "20 min",
                  }}
                />
              ) : (
                <Grid>Click device on the left bar to start</Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid>
            You do not have device in this zone,please check other zone.
          </Grid>
        )}
      </Grid>
      <DialogBox
        size={"Default"}
        open={switchSite}
        HeaderContent={t("SIOT00068")}
        close={() => handleSwitchSite()}
        children={switchSite ? <SwitchSite /> : ""}
      />
    </Grid>
  );
}
