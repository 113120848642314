import * as React from "react";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { showMessage } from "../slices/Common";

export default function AlterMessage(props) {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.common);
  const handleClose = () => {
    dispatch(
      showMessage({
        showMessage: false,
        messageType: "",
        message: "",
      })
    );
  };
  // error warning info success
  return (
    <Snackbar
      open={store.showMessage}
      autoHideDuration={5000}
      onClose={() => {
        handleClose();
      }}
    >
      <Alert
        onClose={() => {
          handleClose();
        }}
        severity={store.messageType || "success"}
        sx={{ position: "fixed", top: "70px", right: "30px" }}
      >
        {store.message}
      </Alert>
    </Snackbar>
  );
}
