const countryListEN = [
  {
    country: 'Afghanistan',
  },
  {
    country: 'Aland Islands',
  },
  {
    country: 'Albania',
  },
  {
    country: 'Algeria',
  },
  {
    country: 'AmericanSamoa',
  },
  {
    country: 'Andorra',
  },
  {
    country: 'Angola',
  },
  {
    country: 'Anguilla',
  },
  {
    country: 'Antarctica',
  },
  {
    country: 'Antigua and Barbuda',
  },
  {
    country: 'Argentina',
  },
  {
    country: 'Armenia',
  },
  {
    country: 'Aruba',
  },
  {
    country: 'Australia',
  },
  {
    country: 'Austria',
  },
  {
    country: 'Azerbaijan',
  },
  {
    country: 'Bahamas',
  },
  {
    country: 'Bahrain',
  },
  {
    country: 'Bangladesh',
  },
  {
    country: 'Barbados',
  },
  {
    country: 'Belarus',
  },
  {
    country: 'Belgium',
  },
  {
    country: 'Belize',
  },
  {
    country: 'Benin',
  },
  {
    country: 'Bermuda',
  },
  {
    country: 'Bhutan',
  },
  {
    country: 'Bolivia, Plurinational State of',
  },
  {
    country: 'Bosnia and Herzegovina',
  },
  {
    country: 'Botswana',
  },
  {
    country: 'Brazil',
  },
  {
    country: 'British Indian Ocean Territory',
  },
  {
    country: 'Brunei Darussalam',
  },
  {
    country: 'Bulgaria',
  },
  {
    country: 'Burkina Faso',
  },
  {
    country: 'Burundi',
  },
  {
    country: 'Cambodia',
  },
  {
    country: 'Cameroon',
  },
  {
    country: 'Canada',
  },
  {
    country: 'Cape Verde',
  },
  {
    country: 'Cayman Islands',
  },
  {
    country: 'Central African Republic',
  },
  {
    country: 'Chad',
  },
  {
    country: 'Chile',
  },
  {
    country: 'China',
  },
  {
    country: 'Christmas Island',
  },
  {
    country: 'Cocos (Keeling) Islands',
  },
  {
    country: 'Colombia',
  },
  {
    country: 'Comoros',
  },
  {
    country: 'Congo',
  },
  {
    country: 'Congo, The Democratic Republic of the Congo',
  },
  {
    country: 'Cook Islands',
  },
  {
    country: 'Costa Rica',
  },
  {
    country: 'Cote d Ivoire',
  },
  {
    country: 'Croatia',
  },
  {
    country: 'Cuba',
  },
  {
    country: 'Cyprus',
  },
  {
    country: 'Czech Republic',
  },
  {
    country: 'Denmark',
  },
  {
    country: 'Djibouti',
  },
  {
    country: 'Dominica',
  },
  {
    country: 'Dominican Republic',
  },
  {
    country: 'Ecuador',
  },
  {
    country: 'Egypt',
  },
  {
    country: 'El Salvador',
  },
  {
    country: 'Equatorial Guinea',
  },
  {
    country: 'Eritrea',
  },
  {
    country: 'Estonia',
  },
  {
    country: 'Ethiopia',
  },
  {
    country: 'Falkland Islands (Malvinas)',
  },
  {
    country: 'Faroe Islands',
  },
  {
    country: 'Fiji',
  },
  {
    country: 'Finland',
  },
  {
    country: 'France',
  },
  {
    country: 'French Guiana',
  },
  {
    country: 'French Polynesia',
  },
  {
    country: 'Gabon',
  },
  {
    country: 'Gambia',
  },
  {
    country: 'Georgia',
  },
  {
    country: 'Germany',
  },
  {
    country: 'Ghana',
  },
  {
    country: 'Gibraltar',
  },
  {
    country: 'Greece',
  },
  {
    country: 'Greenland',
  },
  {
    country: 'Grenada',
  },
  {
    country: 'Guadeloupe',
  },
  {
    country: 'Guam',
  },
  {
    country: 'Guatemala',
  },
  {
    country: 'Guernsey',
  },
  {
    country: 'Guinea',
  },
  {
    country: 'Guinea-Bissau',
  },
  {
    country: 'Guyana',
  },
  {
    country: 'Haiti',
  },
  {
    country: 'Holy See (Vatican City State)',
  },
  {
    country: 'Honduras',
  },
  {
    country: 'Hong Kong',
  },
  {
    country: 'Hungary',
  },
  {
    country: 'Iceland',
  },
  {
    country: 'India',
  },
  {
    country: 'Indonesia',
  },
  {
    country: 'Iran, Islamic Republic of Persian Gulf',
  },
  {
    country: 'Iraq',
  },
  {
    country: 'Ireland',
  },
  {
    country: 'Isle of Man',
  },
  {
    country: 'Israel',
  },
  {
    country: 'Italy',
  },
  {
    country: 'Jamaica',
  },
  {
    country: 'Japan',
  },
  {
    country: 'Jersey',
  },
  {
    country: 'Jordan',
  },
  {
    country: 'Kazakhstan',
  },
  {
    country: 'Kenya',
  },
  {
    country: 'Kiribati',
  },
  {
    country: 'Korea, Democratic People s Republic of Korea',
  },
  {
    country: 'Korea, Republic of South Korea',
  },
  {
    country: 'Kuwait',
  },
  {
    country: 'Kyrgyzstan',
  },
  {
    country: 'Laos',
  },
  {
    country: 'Latvia',
  },
  {
    country: 'Lebanon',
  },
  {
    country: 'Lesotho',
  },
  {
    country: 'Liberia',
  },
  {
    country: 'Libyan Arab Jamahiriya',
  },
  {
    country: 'Liechtenstein',
  },
  {
    country: 'Lithuania',
  },
  {
    country: 'Luxembourg',
  },
  {
    country: 'Macao',
  },
  {
    country: 'Macedonia',
  },
  {
    country: 'Madagascar',
  },
  {
    country: 'Malawi',
  },
  {
    country: 'Malaysia',
  },
  {
    country: 'Maldives',
  },
  {
    country: 'Mali',
  },
  {
    country: 'Malta',
  },
  {
    country: 'Marshall Islands',
  },
  {
    country: 'Martinique',
  },
  {
    country: 'Mauritania',
  },
  {
    country: 'Mauritius',
  },
  {
    country: 'Mayotte',
  },
  {
    country: 'Mexico',
  },
  {
    country: 'Micronesia, Federated States of Micronesia',
  },
  {
    country: 'Moldova',
  },
  {
    country: 'Monaco',
  },
  {
    country: 'Mongolia',
  },
  {
    country: 'Montenegro',
  },
  {
    country: 'Montserrat',
  },
  {
    country: 'Morocco',
  },
  {
    country: 'Mozambique',
  },
  {
    country: 'Myanmar',
  },
  {
    country: 'Namibia',
  },
  {
    country: 'Nauru',
  },
  {
    country: 'Nepal',
  },
  {
    country: 'Netherlands',
  },
  {
    country: 'Netherlands Antilles',
  },
  {
    country: 'New Caledonia',
  },
  {
    country: 'New Zealand',
  },
  {
    country: 'Nicaragua',
  },
  {
    country: 'Niger',
  },
  {
    country: 'Nigeria',
  },
  {
    country: 'Niue',
  },
  {
    country: 'Norfolk Island',
  },
  {
    country: 'Northern Mariana Islands',
  },
  {
    country: 'Norway',
  },
  {
    country: 'Oman',
  },
  {
    country: 'Pakistan',
  },
  {
    country: 'Palau',
  },
  {
    country: 'Palestinian Territory, Occupied',
  },
  {
    country: 'Panama',
  },
  {
    country: 'Papua New Guinea',
  },
  {
    country: 'Paraguay',
  },
  {
    country: 'Peru',
  },
  {
    country: 'Philippines',
  },
  {
    country: 'Pitcairn',
  },
  {
    country: 'Poland',
  },
  {
    country: 'Portugal',
  },
  {
    country: 'Puerto Rico',
  },
  {
    country: 'Qatar',
  },
  {
    country: 'Romania',
  },
  {
    country: 'Russia',
  },
  {
    country: 'Rwanda',
  },
  {
    country: 'Reunion',
  },
  {
    country: 'Saint Barthelemy',
  },
  {
    country: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  {
    country: 'Saint Kitts and Nevis',
  },
  {
    country: 'Saint Lucia',
  },
  {
    country: 'Saint Martin',
  },
  {
    country: 'Saint Pierre and Miquelon',
  },
  {
    country: 'Saint Vincent and the Grenadines',
  },
  {
    country: 'Samoa',
  },
  {
    country: 'San Marino',
  },
  {
    country: 'Sao Tome and Principe',
  },
  {
    country: 'Saudi Arabia',
  },
  {
    country: 'Senegal',
  },
  {
    country: 'Serbia',
  },
  {
    country: 'Seychelles',
  },
  {
    country: 'Sierra Leone',
  },
  {
    country: 'Singapore',
  },
  {
    country: 'Slovakia',
  },
  {
    country: 'Slovenia',
  },
  {
    country: 'Solomon Islands',
  },
  {
    country: 'Somalia',
  },
  {
    country: 'South Africa',
  },
  {
    country: 'South Sudan',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
  },
  {
    country: 'Spain',
  },
  {
    country: 'Sri Lanka',
  },
  {
    country: 'Sudan',
  },
  {
    country: 'Suricountry',
  },
  {
    country: 'Svalbard and Jan Mayen',
  },
  {
    country: 'Swaziland',
  },
  {
    country: 'Sweden',
  },
  {
    country: 'Switzerland',
  },
  {
    country: 'Syrian Arab Republic',
  },
  {
    country: 'Taiwan',
  },
  {
    country: 'Tajikistan',
  },
  {
    country: 'Tanzania, United Republic of Tanzania',
  },
  {
    country: 'Thailand',
  },
  {
    country: 'Timor-Leste',
  },
  {
    country: 'Togo',
  },
  {
    country: 'Tokelau',
  },
  {
    country: 'Tonga',
  },
  {
    country: 'Trinidad and Tobago',
  },
  {
    country: 'Tunisia',
  },
  {
    country: 'Turkey',
  },
  {
    country: 'Turkmenistan',
  },
  {
    country: 'Turks and Caicos Islands',
  },
  {
    country: 'Tuvalu',
  },
  {
    country: 'Uganda',
  },
  {
    country: 'Ukraine',
  },
  {
    country: 'UAE',
  },
  {
    country: 'UK',
  },
  {
    country: 'USA',
  },
  {
    country: 'Uruguay',
  },
  {
    country: 'Uzbekistan',
  },
  {
    country: 'Vanuatu',
  },
  {
    country: 'Venezuela, Bolivarian Republic of Venezuela',
  },
  {
    country: 'Vietnam',
  },
  {
    country: 'Virgin Islands, British',
  },
  {
    country: 'Virgin Islands, U.S.',
  },
  {
    country: 'Wallis and Futuna',
  },
  {
    country: 'Yemen',
  },
  {
    country: 'Zambia',
  },
  {
    country: 'Zimbabwe',
  },
];

export const countryList = countryListEN;
