import { Box, Grid, Paper } from "@material-ui/core";
import { Avatar, IconButton, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as DevcieIcon } from '../../src/assets/DeviceIcon.svg'

export default function DeviceInfo(props) {
    return (

        <Paper elevation={0}
            style={{
                minHeight: "40px",
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                paddingTop: "16px",
                paddingBottom: "16px",
                marginBottom:'20px',
                marginLeft:'18px',
            }}>
            <Grid container spacing={2}>
                <Grid item lg={0.5}>
                    <IconButton
                        style={{ alignSelf: 'center', borderRadius: '50px', backgroundColor: '#a8b8d126', height: '60px', width: '60px', left: '20px', top: '0px' }}>
                        <DevcieIcon style={{ height: '30px', width: '35px' }} />
                    </IconButton>
                </Grid>
                <Grid item lg={11.5} style={{ marginLeft: '25px' }}>
                    <Typography>{props.data.deviceModel}</Typography>
                    <Typography >{'Site:  '}{props.data.siteName}</Typography>
                    <Typography>{'Zone:  '}{props.data.zoneName}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}