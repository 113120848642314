import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { REACT_CREATE_ORG_TYPE, REACT_SELECT_ORG_TYPE } from '../../constants/ReactEndPoints';
import { useTranslation } from 'react-i18next';

function SelectOrganizationTypeProfile(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [organizationType, setOrganizatonType] = React.useState('Family');
  return (
    <>
      <Grid
        // onClick={() => { setOrganizatonType('Corporate') }}
        style={{
          backgroundColor: '#F9F9F9',
          boxShadow:
            organizationType === 'Corporate'
              ? '#7fff00 0px 0px 0px 2px, #7fff00 0px 4px 6px -1px, #7fff00 0px 1px 0px inset'
              : 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
          borderRadius: '4px',
          padding: '6px',
          backgroundColor: organizationType === 'Corporate' ? '#FFFF' : '#F9F9F9',
          maxWidth: '498px',
        }}
      >
        <Typography
          style={{
            font: 'normal medium 17px/20px Roboto',
            fontSize: '20px',
            color: '#304669',
            fontWeight: 'bold',
            padding: '4px',
          }}
        >
          Corporate company
        </Typography>
        <Typography style={{ padding: '4px', fontSize:'14px'}}>
          company with department, designation, etc. This company will be large size organization for more complex human resource management.
        </Typography>
      </Grid>
      <br />
      <Grid
        onClick={() => props.ScreenChangeTo('Create Organization Profile', 'Default')}
        // onClick={() => { setOrganizatonType('Family') }}
        style={{
          borderColor: 'red',
          borderRadius: '4px',
          boxShadow:
            organizationType === 'Family'
              ? '#7fff00 0px 0px 0px 2px, #7fff00 0px 4px 6px -1px, #7fff00 0px 1px 0px inset'
              : 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
          padding: '6px',
          maxWidth: '498px',
          backgroundColor: organizationType === 'Family' ? '#FFFF' : '#F9F9F9',
        }}
      >
        <Typography
          style={{
            fontStyle: 'Roboto',
            fontSize: '20px',
            color: '#304669',
            fontWeight: 'bold',
            padding: '4px 4px 4px',
          }}
        >
          Family and homeautomation
        </Typography>
        <Typography style={{ padding: '4px', fontSize:'14px' }}>
          You will create one family and have your family member together. There is some people group to quickly manage the sharing of devices. It is suitable for home automation.
        </Typography>
      </Grid>
    </>
  );
}

export default SelectOrganizationTypeProfile;
