import api from "../config/http-common";
import CommonUtil from "../Util/CommonUtils";

const buildQuery = (filter) => {
    
    
    let query = '';
    if (!CommonUtil.isEmptyString(filter.deviceSn)) {
        query = "&deviceSn=" + filter.deviceSn;
    }
    if (!CommonUtil.isEmptyString(filter.deviceId)) {
        query = query + "&deviceId=" + filter.deviceId;
    }
    if (!CommonUtil.isEmptyString(filter.siteId)) {
        query = query + "&siteId=" + filter.siteId;
    }
    if (!CommonUtil.isEmptyString(filter.zoneId)) {
        query = query + "&zoneId=" + filter.zoneId;
    }
    return query;
};

const getDeviceList = (filter) => {
    return api.securedAxios().post('/device/list?pageNumber=1&pageSize=10' + buildQuery(filter));
};
const getDeviceListPegination = (payload) => {
    return api.securedAxios().post('/device/list?pageNumber=' + payload.pageNumber + '&pageSize=' +payload. pageSize);
};
const assignDeviceToUse = (payload) => {
    return api.securedAxios().post('/subscription/device',payload);
}


const DeviceService = {
    getDeviceList,
    getDeviceListPegination,
    assignDeviceToUse,
}

export default DeviceService;