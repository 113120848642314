import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = { type: 'success', message: 'success' };

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    success: (state, action) => {
      state.type = 'success';
      state.message = action.payload;
    },
  },
});

const { reducer } = toastSlice;
export default reducer;
