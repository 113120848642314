import React, { useState } from 'react';
import OnBordlayout from '../../components/OnBordlayout';
import DilogBox from '../../components/DilogBox';
import { Button, Checkbox, Grid, Link, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { REACT_LOGIN, REACT_SIGNUP } from '../../constants/ReactEndPoints';
import { useTranslation } from 'react-i18next';

export default function SignupAgreement(props) {
  const { t } = useTranslation();
  const [openDilog, setopenDilog] = React.useState(false);
  const [HeaderContent, setHeaderContent] = React.useState('');
  const [userAgreement, setUserAgreement] = React.useState(false);
  const [termsCondition, setTermsCondition] = React.useState(false);
  const history = useHistory();
  const [init, setInit] = useState({ userAgreement: true, termsCondition: true });

  const handleAgree = () => {
    if (HeaderContent === 'User Agreement') {
      setInit({ ...init, userAgreement: false });
      setUserAgreement(true);
    } else {
      setInit({ ...init, termsCondition: false });
      setTermsCondition(true);
    }
    setopenDilog(false);
  };

  const handleDisAgree = () => {
    if (HeaderContent === 'User Agreement') {
      setInit({ ...init, userAgreement: false });
      setUserAgreement(false);
    } else {
      setInit({ ...init, termsCondition: false });
      setTermsCondition(false);
    }
    setopenDilog(false);
  };

  const handleSubmit = () => {
    if (userAgreement && termsCondition) {
      history.push(REACT_SIGNUP);
    } else {
      setInit({ userAgreement: false, termsCondition: false });
    }
  };

  return (
    <>
      <OnBordlayout>
        <Typography variant='h4' component='div' gutterBottom style={{ color: '#474A4E' }}>
          Welcome to register,
        </Typography>
        <br />
        <Typography variant='subtitle1' gutterBottom component='div'>
          Register your account by email or mobile. The account is the only credential for you to log into the system, Please keep your information properly.
        </Typography>
        <Typography variant='subtitle1' gutterBottom component='div'>
          You can create organization under your account, and your account can also be invited to join other organizations.
        </Typography>
        <br />
        <Typography variant='subtitle1' gutterBottom component='div'>
          You can delete account and its information, or transfer account and digital assets to trusted people.
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant='subtitle1' gutterBottom component='div'>
          Please read the User Agreement and Privacy Policy before registering an account.
        </Typography>
        <Typography>
          <Checkbox
            style={{ color: '#36C96D' }}
            value={userAgreement}
            checked={userAgreement}
            onChange={(e) => {
              setUserAgreement(e.target.checked);
              setInit({ ...init, userAgreement: false });
            }}
          />{' '}
          I have read and agree to{' '}
          <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }} onClick={() => setopenDilog(!openDilog) & setHeaderContent('User Agreement')}>
            User Agreement
          </Link>
        </Typography>
        {!userAgreement && !init.userAgreement && (
          <Typography variant='subtitle1' gutterBottom component='div' style={{ color: 'red' }}>
            You must agree to the User Agreement.
          </Typography>
        )}

        <Typography>
          <Checkbox
            style={{ color: '#36C96D' }}
            value={termsCondition}
            checked={termsCondition}
            onChange={(e) => {
              setTermsCondition(e.target.checked);
              setInit({ ...init, termsCondition: false });
            }}
          />{' '}
          I have read and agree to{' '}
          <Link style={{ color: 'rgb(54, 201, 109)', textDecoration: 'none', cursor: 'pointer' }} onClick={() => setopenDilog(!openDilog) & setHeaderContent('Privacy Policy')}>
            Privacy Policy
          </Link>
        </Typography>
        {!termsCondition && !init.termsCondition && (
          <Typography variant='subtitle1' gutterBottom component='div' style={{ color: 'red' }}>
            You must agree to Privacy Policy.
          </Typography>
        )}
        <br></br>
        <Button style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }} onClick={() => handleSubmit()}>
          Register
        </Button>
        <Grid style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
          <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
            {' '}
            {t('SIOT00050')}{' '}
          </Typography>
        </Grid>
      </OnBordlayout>
      <DilogBox
        open={openDilog}
        HeaderContent={HeaderContent}
        close={(data) => {
          setopenDilog(!openDilog);
        }}
      >
        {HeaderContent === 'User Agreement' ? (
          <>
            {' '}
            <Typography variant='subtitle1' gutterBottom component='div'>
              1.information we collect about you and how we collect it <br />
              In order to provide you with our services,we will ask you to provide personal information necessary to provide you with the services. If you do not provide personal
              information, we may not be able to provide with you our products or services. We collect this information directly from you when you provide it to us. We may also
              automatically collect certain information , such as usage details,device profiles and IP addresses, as you browse the Site or Use our Services through the Use of
              technologies such as cookies, tags and scripts.
            </Typography>
            <br />
            <Typography variant='subtitle1' gutterBottom component='div'>
              We collect the following types of information (whether or not it is personal information):
            </Typography>
            <br />
            <Button style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }} onClick={() => handleAgree()}>
              Read and agree
            </Button>
            <br />
            <br />
            <Button
              variant='outlined'
              style={{ width: '100%', backgroundColor: 'white', color: 'rgb(54, 201, 109)', borderColor: 'rgb(54, 201, 109)' }}
              onClick={() => handleDisAgree()}
            >
              Not agree and quit
            </Button>
          </>
        ) : (
          <>
            <Typography variant='subtitle1' gutterBottom component='div'>
              1.information we collect about you and how we collect it <br />
              In order to provide you with our services,we will ask you to provide personal information necessary to provide you with the services. If you do not provide personal
              information, we may not be able to provide with you our products or services. We collect this information directly from you when you provide it to us. We may also
              automatically collect certain information , such as usage details,device profiles and IP addresses, as you browse the Site or Use our Services through the Use of
              technologies such as cookies, tags and scripts.
            </Typography>
            <br />
            <Typography variant='subtitle1' gutterBottom component='div'>
              We collect the following types of information (whether or not it is personal information):
            </Typography>
            <Button style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }} onClick={() => handleAgree()}>
              Read and agree
            </Button>
            <br />
            <br />
            <Button
              variant='outlined'
              style={{ width: '100%', backgroundColor: 'white', color: 'rgb(54, 201, 109)', borderColor: 'rgb(54, 201, 109)' }}
              onClick={() => handleDisAgree()}
            >
              Not agree and quit
            </Button>
          </>
        )}
      </DilogBox>
    </>
  );
}
