
import React, { useState, useRef } from "react";
import { Grid, IconButton } from '@mui/material';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { styled } from '@mui/material/styles';

export default function ProfileModifier(props) {
    const handleClick = (item) => {
        props.ScreenChangeTo(item.toString(), item === 'Edit My profile' ? 'HalfScreen' : 'Default')
    }
    const CustomGrid = styled(Grid)(() => ({
        height: '48px',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px',
        display: 'flex',
        flexDirection: 'row',
        margin: '12px 0px',
        backgroundColor: '#FFFF',
        color: '#304669',
        '&:hover': {
            background: "#36C96D",
            color: '#FFFF'
        }
    }));
    return (
        <Grid style={{ backgroundColor: '#F4F5F7' }}>
            {['Edit My profile', 'Delete my account', 'Transfer my account'].map(item => <CustomGrid
                onClick={() => { handleClick(item) }}
            >
                <Grid style={{ fontSize: '14px', fontFamily: 'Microsoft YaHei' }}>{item} </Grid>
                <IconButton><Arrow style={{ height: '14px', width: '14px', transform: `rotate(270deg)` }} /></IconButton>
            </CustomGrid>)}

        </Grid>
    );
}
