import React, { useState, useEffect } from 'react';
import { Grid, Typography, Avatar } from '@mui/material';
import download from '../../assets/event/ic_download.png';
import video from '../../assets/event/btn_video.png';
import taking_pictures from '../../assets/event/btn_capture.png';
import { makeStyles } from '@material-ui/core';
import btn_mute from '../../assets/event/btn_mute.png';
import ic_voice_ban from '../../assets/dashboard/ic_voice_ban.png';
import ic_video_ahove from '../../assets/dashboard/ic_video_ahove.png';
import ic_xingbiao from '../../assets/event/ic_xingbiao.png';
import movie from '../../assets/movie.mp4';
import { capture, downloadVideo } from './utils/utils.js';
import Recorder from '../../Util/Recorder.js';
import { getDashUrl } from '../../services/EventService';
import { ReactComponent as Electricity } from '../../assets/electricity.svg';
import { ReactComponent as Ic_cloud_storage } from '../../assets/ic_cloud_storage.svg';

function BtnGrid(props) {
  return (
    <Grid
      sx={{
        width: '60px',
        textAlign: 'center',
        fontSize: '12px',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <img
        onClick={() => {
          props.clickEvent();
        }}
        alt=''
        src={props.icon}
        style={{ height: '59px', width: '59px', borderRadius: '8px' }}
      />
      <div>{props.children}</div>
    </Grid>
  );
}

export const potint = makeStyles(() => ({
  root: {
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: 'red',
    position: 'absolute',
    top: '25px',
    left: '25px',
    animation: '$myEffect 500ms infinite',
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export const useStyle = makeStyles(() => ({
  recording_indicator: {
    width: '5px',
    height: '5px',
    borderRadius: '5px',
    backgroundColor: 'red',
    position: 'absolute',
    bottom: '28px',
    left: '28px',
    animation: '$myEffect 500ms infinite',
  },
  play_icon_position: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  play_icon_container: {
    position: 'relative',
    backgroundColor: '#000000',
    boxSizing: 'border-box',
    width: '100%',
  },
  disable_icon: {
    position: 'relative',
    transform: 'rotateZ(45deg)',
    width: '24px',
    right: '-12px',
    bottom: '-12px',
  },
  video_frame: {
    maxHeight: '400px',
    width: '100%',
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export default function PlaybackEvent(props) {
  let potintClass = potint();
  const [muted, setMuted] = useState(false);
  const [recording, setRecording] = useState(null);
  const [options, showOptions] = useState(false);
  const labelRef = React.createRef();
  const style = useStyle();
  let clickEvent = (event) => {
    let eventVideo = document.getElementById('playbackEventVideo');
    if (event === 'capture') {
      capture(eventVideo);
    } else if (event === 'muted') {
      let videoMuted = eventVideo.muted;
      setMuted(!videoMuted);
    } else if (event === 'save') {
      let href = eventVideo.src;
      downloadVideo(href);
    } else if (event === 'video') {
      if (recording === null) {
        let recorderObj = new Recorder(eventVideo.captureStream(25), {
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 2500000,
          mimeType: 'video/webm',
        });
        recorderObj.start();
        setRecording(recorderObj);
      } else {
        recording.stop();
        recording.download();
        setRecording(null);
      }
    }
  };

  let clickVideo = () => {
    let eventVideo = document.getElementById('playbackEventVideo');
    let paused = eventVideo.paused;
    if (paused) {
      eventVideo.play();
      showOptions(true);
    } else {
      eventVideo.pause();
    }
  };

  useEffect(() => {
    getDashUrl().then((data) => {
      debugger;
    });
    let eventVideo = document.getElementById('playbackEventVideo');
    eventVideo.pause();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      sx={{
        backgroundColor: '#ffffff',
        padding: '10px',
      }}
    >
      <Grid sx={{ flexWrap: 'nowrap', marginBottom: '10px' }} container>
        <Grid>
          <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' />
        </Grid>
        <Grid
          sx={{
            justifyContent: 'space-between',
            marginLeft: '10px',
          }}
          container
        >
          <Grid>
            <Typography
              style={{
                font: 'normal normal normal 14px/14px "Roboto","Helvetica","Arial",sans-serif',
                color: '#304669',
              }}
            >
              front door device
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: '#30466980',
              }}
            >
              Site A
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: '#30466980',
              }}
            >
              Site A kitchen zone...
            </Typography>
            {/* <Typography
              style={{
                fontSize: '18px',
                fontWeight: 400,
                color: '#304669',
              }}
            >
              Motion detection
            </Typography> */}
          </Grid>
          <Grid
            style={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#30466980',
            }}
          >
            <Typography sx={{ fontSize: '16px', alignItems: 'center' }}>
              {props.DemoInfo.Internetspeed + ' ' + props.DemoInfo.size} <Electricity /> {' ' + props.DemoInfo.battery}{' '}
            </Typography>
            <Typography>{props.DemoInfo.duration}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <div
        class='container'
        style={{
          backgroundColor: '#000000',
          border: '1px solid #0AF861',
          boxSizing: 'border-box',
        }}
      >
        <video
          onClick={() => {
            clickVideo();
          }}
          id='playbackEventVideo'
          autoPlay='autoplay'
          loop='loop'
          muted={muted}
          style={{ width: '100%', height: '100%', maxHeight: props.videoHeight ? props.videoHeight : '350px' }}
          src={movie}
        ></video>
        <div class='overlay'>
          <p>2020-July-23, 16:45:00</p>
        </div>
      </div>
      {/* <Grid
        style={{
          backgroundColor: '#000000',
          border: '1px solid #0AF861',
          boxSizing: 'border-box',
        }}
      >
        <video
          onClick={() => {
            clickVideo();
          }}
          id='playbackEventVideo'
          autoPlay='autoplay'
          loop='loop'
          muted={muted}
          style={{ width: '100%', height: '100%', maxHeight: props.videoHeight ? props.videoHeight : '350px' }}
          src={movie}
        ></video>
        <div class='overlayText'>
          <p id='topText'>Content above your video</p>
        </div>
      </Grid> */}
      {options && (
        <Grid
          container
          sx={{
            marginTop: '10px',
            justifyContent: 'space-between',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <BtnGrid
            clickEvent={() => {
              clickEvent('save');
            }}
            icon={download}
          >
            Save
          </BtnGrid>
          <BtnGrid
            clickEvent={() => {
              clickEvent('video');
            }}
            icon={video}
          >
            {recording !== null ? <div className={potintClass.root}></div> : ''}
            Video
          </BtnGrid>
          <BtnGrid
            clickEvent={() => {
              clickEvent('capture');
            }}
            icon={taking_pictures}
          >
            Capture
          </BtnGrid>
          <BtnGrid
            clickEvent={() => {
              clickEvent('muted');
            }}
            icon={muted ? btn_mute : ic_voice_ban}
          >
            Mute
          </BtnGrid>
          {props.starTheEvent && (
            <BtnGrid clickEvent={() => {}} icon={ic_xingbiao}>
              Star this event
            </BtnGrid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
