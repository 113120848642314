import { makeStyles, Paper } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyLogo from '../../assets/dashboard/empty-item.svg';
// import Loader from "./Util/Loader";
const useStyles = makeStyles((theme) => ({
  custompagination: {
    height: 500,
    width: "100%",
    textAlign: "center",
    boxShadow: "0px 3px 8px #00000029",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    opacity: 1,
    [theme.breakpoints.down("lg")]: {
      height: 350,
      width: "100%",
      textAlign: "center",
      boxShadow: "0px 3px 8px #00000029",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      opacity: 1,
    },
  }
}));
function DataTable(props) {
  const { t } = useTranslation();
  const { handleChange } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(props.pageSize?99:10);

  const handleRowChange = () => {
    var e = document.getElementById("pagesize");
    handleChange(1, e.value);
    setRowsPerPage(e.value);

  };

  const handlePageChange = (event, value) => {
    handleChange(value, rowsPerPage);
  };
  return (
    <>
      {/* <Loader/> */}
      <Paper>
        <div
          className={classes.custompagination}
        >
          {(props.rows.length === 0) && (props.img !== true) ? (
            <img alt="no record found"
              src={EmptyLogo}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            />
          ) : null}

          {props.rows.length === 0 && (props.img !== true) ? (
            <span
              style={{
                color: "#3D4977",
                opacity: "0.5",
                position: "absolute",
                paddingTop: 160,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              {t('No Records')}
            </span>
          ) : null}
          <DataGrid
            rows={props.rows}
            columns={props.columns}
            hight={props.hight}
            pageSize={rowsPerPage}
            checkboxSelection
            disableMultipleSelection
            onSelectionModelChange={props.onSelectionModelChange}
            selectionModel={props.selectionModel}
            hideFooterPagination
            hideFooter={props.rows.length > 0 && props.pagination === false ? false : true}
            disableColumnMenu
            getRowId={props.rowId}
            defaultPage={1}
            localeText={{ columnHeaderSortIconLabel: t('SRT011') }}
            paganations={props.pagination}
            sx={[
              () => ({
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F4F6F8",
                },
              }),
              () => ({
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
              }),
              () => ({
                "& .MuiDataGrid-overlay": {
                  display: "none",
                },
              }),
            ]}
          />
          <>
            {props.pagination === true ? '' :
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  position: "relative",
                  bottom: 40,
                  justifyContent: "flex-end",
                }}
              >
                {props.rows.length > 0 ? (
                  <>
                    <label
                      for="records"
                      style={{ paddingRight: 5, paddingTop: 3.5, color: '#304669' }}
                    >
                      {t('SIOT00072')}{" "}
                    </label>
                    <select
                      id="pagesize"
                      style={{
                        padding: 6,
                        paddingBottom: 5,
                        paddingTop: 5,
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        border: "0.5px solid #CCCCCC",
                        borderRadius: "3px",
                        opacity: 1,
                        backgroundColor: '#fff',
                        color: '#304669'
                      }}
                      onChange={handleRowChange}
                      value={rowsPerPage}
                    >
                      <option value={5} >5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                    </select>
                    <p style={{ paddingTop: 3.5, color: '#304669' }}> {t('SIOT00073')}</p>
                  </>
                ) : null}
                <Pagination
                  hidden={props.rows.length > 0 ? false : true}
                  count={props.pageCount}
                  onChange={handlePageChange}
                />
              </Stack>
            }
          </>
        </div>
      </Paper>
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  columns: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "fullName",
      headerName: "Full name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
  ],

  rows: [],
  checkboxSelection: false,
  pageCount: 10,
};

