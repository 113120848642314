import React, { useEffect } from 'react';
import { Grid, Typography, Checkbox, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { ReactComponent as IotLogo } from '../../assets/logo.svg';
import CustomInput from '../../components/CustomInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PhoneInput from 'react-phone-input-2';
import CommonUtil from '../../Util/CommonUtils';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authorizeUser, logout } from '../../slices/User';
import { useHistory } from 'react-router';
import { REACT_FORGOT_PASSWORD, REACT_SELECT_ORG, REACT_SIGNUP, REACT_SIGNUP_AGREE } from '../../constants/ReactEndPoints';
import setJWTToken from '../../config/setJWTToken';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function LoginForm(props) {
  const [visibility, setVisibility] = React.useState(true);
  const [LoginMethod, setLoginMethod] = React.useState('Email');
  const [payload, setPayload] = React.useState({ userName: '', password: '' });
  const [error, setError] = React.useState({ userName: '', password: '' });
  const [userNameType, setUserNameType] = React.useState('Email');
  const [countryCode, setCountryCode] = React.useState(CommonUtil.getLocationDetails('country_calling_code'));
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(logout());
    // try {
    //   fetch('https://ipapi.co/json/')
    //     .then((response) => response.json())
    //     .then((data) => localStorage.setItem('locationInfo', JSON.stringify(data)));
    // } catch (error) {}
  }, []);

  useEffect(() => {
    resetFields();
  }, [userNameType]);

  useEffect(() => {
    setError({
      ...error,
      userName: '',
    });
  }, [phoneNumber, countryCode]);

  const resetFields = () => {
    setPayload({
      password: '',
      userName: '',
    });
    setError({
      userName: '',
      password: '',
    });
    setPhoneNumber('');
    setCountryCode(CommonUtil.getLocationDetails('country_calling_code'));
  };

  const handleUsernameType = (event) => {
    setUserNameType(event.target.value);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const validatePayload = () => {
    if (userNameType === 'Email') {
      if (CommonUtil.isEmptyString(payload.userName)) {
        setError({
          ...error,
          userName: t('SIOT00001'),
        });
        return false;
      }
      if (!CommonUtil.isValidEmail(payload.userName)) {
        setError({
          ...error,
          userName: t('SIOT00003'),
        });
        return false;
      }
    } else {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          userName: t('SIOT00001'),
        });
        return false;
      } else {
        if (CommonUtil.isEmptyString(countryCode)) {
          setError({
            ...error,
            userName: t('SIOT00007'),
          });
          return false;
        } else {
          var mobile = countryCode.replace('+', '') + phoneNumber;

          if (mobile.length < 8 || mobile.length > 25) {
            setError({
              ...error,
              userName: t('SIOT00008'),
            });
            return false;
          }
        }
      }
    }
    if (CommonUtil.isEmptyString(payload.password)) {
      setError({
        ...error,
        password: t('SIOT00001'),
      });
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validatePayload()) {
      var state = {
        userName: userNameType === 'Email' ? payload.userName : '+' + countryCode.replace('+', '') + '-' + phoneNumber,
        password: payload.password,
      };
      dispatch(authorizeUser(state))
        .unwrap()
        .then((data) => {
          if(data.code === 'SIOTI0001' && data.data.access_token=== null){
            setError({
              ...error,
              userName: userNameType === 'Email' ? t('SIOT00033') : t('SIOT00034'),
              password: userNameType === 'Email' ? t('SIOT00033') : t('SIOT00034'),
            });
          }

          else if (data.code === 'SIOTI0001') {
            localStorage.setItem('USER_TOKEN', 'Bearer ' + data.data.access_token);
            setJWTToken('Bearer ' + data.data.access_token);
            history.push({ pathname: REACT_SELECT_ORG });
          } else {
            setError({
              ...error,
              userName: userNameType === 'Email' ? t('SIOT00033') : t('SIOT00034'),
              password: userNameType === 'Email' ? t('SIOT00033') : t('SIOT00034'),
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (countryCode.length + value.length > 23) {
      return false;
    } else {
      setPhoneNumber(value);
    }
  };

  const FieldPassword = (key) => (
    <CustomInput
      required
      type={visibility ? 'password' : 'text'}
      autoComplete='off'
      fullWidth
      error={error.password}
      name='password'
      label={t('SIOT00012')}
      value={payload.password}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.password}
      InputProps={{
        endAdornment: visibility ? (
          <VisibilityOffIcon
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ) : (
          <VisibilityIcon
            onClick={() => {
              setVisibility(!visibility);
            }}
          />
        ),
      }}
    />
  );
  const FieldEmail = (key) => (
    <CustomInput
      type={'text'}
      required
      style={{ margin: '14px 0px' }}
      autoComplete='off'
      fullWidth
      error={!CommonUtil.isEmptyString(error.userName)}
      name='userName'
      label={t('SIOT00009')}
      value={payload.userName}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.userName}
      validation='email'
    />
  );

  const FiledPhoneNumber = () => (
    <Grid container>
      <Grid item lg={4} sm={12} marginTop={'14px'} marginBottom={'14px'} paddingRight={'8px'}>
        <PhoneInput
          autoFormat={false}
          countryCodeEditable={true}
          enableSearch='true'
          searchPlaceholder={'search'}
          searchNotFound={'search'}
          onChange={(value, data) => setCountryCode(data.dialCode)}
          inputProps={{
            disabled: true,
          }}
          value={countryCode}
          country={CommonUtil.getDefaultCountryCode()}
          placeholder={false}
          specialLabel={t('SIOT00011') + ' *'}
        />
      </Grid>
      <Grid lg={8} sm={12}>
        <CustomInput
          type={'text'}
          required
          style={{ margin: '14px 0px' }}
          autoComplete='off'
          fullWidth
          error={!CommonUtil.isEmptyString(error.userName)}
          name='phoneNumber'
          label={t('SIOT00010')}
          value={phoneNumber}
          variant='outlined'
          size='small'
          handleChange={(e) => handlePhoneNumberChange(e.target.value)}
          helperText={error.userName}
          validation='numeric'
        />
      </Grid>
    </Grid>
  );

  const Login = (key) => (
    <Grid>
      <span className='app-text-header'>{t('SIOT00022') + ','}</span>
      <Grid style={{ display: 'flex', justifyContent: 'space-evenly', margin: '14px 0px' }}>
        <Grid
          onClick={() => {
            setLoginMethod('Email');
          }}
          style={{ borderBottom: LoginMethod === 'Email' ? '4px solid #36c96d' : '' }}
        >
          <span className='app-text'>{t('SIOT00013')}</span>
        </Grid>
        <Grid
          onClick={() => {
            setLoginMethod('Phone');
          }}
          style={{ borderBottom: LoginMethod === 'Phone' ? '4px solid #36c96d' : '' }}
        >
          <span className='app-text'>{t('SIOT00014')}</span>
        </Grid>
      </Grid>

      <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={userNameType} onChange={handleUsernameType}>
        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Email' control={<Radio color='success' />} label={t('SIOT00015')} color='success' />

        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Phone' control={<Radio color='success' />} label={t('SIOT00016')} color='success' />
      </RadioGroup>

      {userNameType === 'Email' ? FieldEmail() : FiledPhoneNumber()}
      {FieldPassword()}
      <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'system-ui', color: '#8d8d8d', margin: '14px 0px' }}>
        <Grid style={{ fontSize: '16px' }}>
          <Checkbox defaultChecked style={{ margin: '0px', padding: '0px' }} /> {t('SIOT00017')}
        </Grid>
        <Grid style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold' }} onClick={() => history.push({ pathname: REACT_FORGOT_PASSWORD, page: t('SIOT00018') })}>
          {t('SIOT00018')}{' '}
        </Grid>
      </Grid>

      <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
        {t('SIOT00019')}
      </Button>

      <Grid style={{ flexDirection: 'row', display: 'flex' }}></Grid>

      <Grid style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '14px 0px' }}>
        <Typography style={{ fontSize: '14px', color: '#304669', fontFamily: 'Bahnschrift Light', marginRight: '4px', cursor: 'pointer' }}>{t('SIOT00038')}</Typography>

        <KeyboardArrowDownIcon />
      </Grid>

      <Grid style={{ display: 'flex', justifyContent: 'center', margin: '14px 0px' }}>
        <Typography style={{ color: 'gray', fontSize: '12px' }}> {t('SIOT00020')} </Typography>
        <Typography onClick={() => history.push({ pathname: REACT_SIGNUP_AGREE })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
          {' '}
          {t('SIOT00021')}{' '}
        </Typography>
      </Grid>
    </Grid>
  );
  return <Grid style={{ height: '100%' }}>{Login()}</Grid>;
}
