import { Button, Typography, Grid } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import DilogBox from '../../components/DilogBox';
import OnBordlayout from '../../components/OnBordlayout';
import { REACT_LOGIN } from '../../constants/ReactEndPoints';
import { ReactComponent as Successful } from '../../assets/successful.svg';


export default function ResetPasswordProfileSuccessPage() {
    const history = useHistory();
    const [open, setOpen] = React.useState(true);

    return (

            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


                <Grid style={{ margin: '12px 0px' }}>
                    <Successful />
                </Grid>
                <Typography style={{ margin: '12px 0px', color: '#304669', fontSize: '16px' }}>Your password has been successfully reset.</Typography>

                <Grid style={{ display: 'flex', justifyContent: 'center', margin: '14px 0px' }}>
                    <Typography style={{ color: '#304669', fontSize: '14px' }}> {'Please click to'} </Typography>
                    <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '14px', cursor: 'pointer', margin: '0px 4px' }}>
                        {'log in '}

                    </Typography>
                </Grid>

            </Grid>
    
    )
}

