import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export default function Notification() {
  const CustomGrid = styled(Grid)(() => ({
    display: "flex",
    backgroundColor: "#ffffff",
    height: "62px",
    margin: "12px 0px",
    alignItems: "center",
    fontFamily: "Microsoft YaHei",
    justifyContent: "space-between",
    borderRadius: "5px",
    padding: "0px 12px",
    color: "#304669",
    "&:hover": {
      cursor: "pointer",
      background: "#36C96D",
      color: "#FFFF",
    },
  }));

  const CustomTypography = styled(Typography)(() => ({
    fontSize: "18px",
  }));

  const CustomArrowForwardIosIcon = styled(ArrowForwardIosIcon)(() => ({
    fontSize: "16px",
  }));

  return (
    <Grid>
      <CustomGrid>
        <CustomTypography>Mark all as read</CustomTypography>
        <CustomArrowForwardIosIcon></CustomArrowForwardIosIcon>
      </CustomGrid>
      <CustomGrid>
        <CustomTypography>Unread events</CustomTypography>
        <CustomArrowForwardIosIcon></CustomArrowForwardIosIcon>
      </CustomGrid>
      <CustomGrid>
        <CustomTypography>Star events</CustomTypography>
        <CustomArrowForwardIosIcon></CustomArrowForwardIosIcon>
      </CustomGrid>
    </Grid>
  );
}
