import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as IotLogo } from '../../assets/logo.svg';
import OnBordlayout from '../../components/OnBordlayout';
import { REACT_LOGIN } from '../../constants/ReactEndPoints';

export default function ForgotPasswordSuccess(props) {
    const { t } = useTranslation();
    const usehistory = useHistory();

    const handleClick = () => {
        usehistory.push(REACT_LOGIN);
    };

    const ForgotPasswordSuccess = (key) => (
        <Grid>
            <Grid>
                <Typography style={{ color: '#304669', fontSize: '36px' }}>Confirmation,</Typography>
                <Typography style={{ color: '#304669', fontSize: '17px', opacity: '50%', margin: '14px 0px' }}>Reset linkage or tamper login password has been sent to your mentioned email or mobile number Please check your inbox or mobile SMS.</Typography>
            </Grid>

            <Button onClick={() => handleClick()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff', fontSize: '17px' }}>
                Back to Login
            </Button>

            <Grid style={{ flexDirection: 'row', display: 'flex' }}></Grid>
        </Grid>
    );
    return (
        <OnBordlayout>
            <Grid style={{ height: '100%' }}>
                {ForgotPasswordSuccess()}
            </Grid>
        </OnBordlayout>
    );
}
