import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as Close } from '../assets/close.svg';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}
export const useStyles = makeStyles((theme) => ({}));

export default function DilogBox(props) {
  const [open, setopen] = useState(props.open);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const style = useStyles();
  const width = props.dialogWidth;
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handletoggle = (data) => {
    setopen(false);
    props.close(data);
  };

  const ScreenWidth = {
    FullScreen: '100%',
    Default: width || '362px',
    HalfScreen: '50%',
  };

  const styleDialog = {
    '& .MuiDialog-paper': {
      backgroundColor: '#F4F5F7',
      right: '0px',
      position: 'absolute',
      boxShadow: 1,
      maxWidth: 'none',
      width: props.size === 'FullScreen' ? ScreenWidth.FullScreen : props.size === 'HalfScreen' ? ScreenWidth.HalfScreen : ScreenWidth.Default,
    },
  };

  return (
    <Dialog sx={styleDialog} open={props.open}>
      <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Grid display='flex' alignItems='center' justifyContent='space-between' margin='12px'>
          <Grid style={{ fontSize: '24px', color: '#304669', fontFamily: 'Microsoft YaHei' }}>
            {props.HeaderContent === 'DefaultProfile' || props.HeaderContent === 'ResetPassword_Successfull' || props.HeaderContent === 'Reset password'
              ? ''
              : props.HeaderContent === 'Create organization'
              ? 'Create one organization'
              : props.HeaderContent === 'Create Organization Profile'
              ? 'Create organization'
              : props.HeaderContent}
          </Grid>
          <Grid style={{ top: 0 }}>
            <Close
              onClick={() => {
                handletoggle(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid sx={{ height: `calc(${windowDimensions.height + 'px'} - 120px)`, overflow: 'auto', padding: '12px', overflowX: 'hidden' }}>{props.children}</Grid>
      </Grid>
    </Dialog>
  );
}
