import React from "react";
import { Role } from "amazon-kinesis-video-streams-webrtc";
import WebRTC_SDK from "./ViewerSDK";
import axios from "axios";
import DynamicLoader from "../DynamicLoader";
import demovideo from '../../assets/Demo.mp4'
import DeviceInfo from "../DeviceInfo";
import {ReactComponent as CloseIcon} from '../../assets/closeIcon.svg';
import Snackbar from '@mui/material/Snackbar';
import {ReactComponent as FullScreen} from '../../assets/fullscreen.svg';
import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import Alert from "@mui/material/Alert";


export default class ViewerPage extends React.Component {

  constructor(props) {
    super(props);
    this.localRef = "";
    this.remoteRef = "";
    this.webrtc = null;
    this.state = {
      error:false,
      closeLive:false,
      site:props.data,
      message: "",
      device: props.data.id,
      loading: false,
      errorMessage: "",
      open: false,
      romoteTrack: false,
      exitIntercom: false, //是否有麦克风
      intercom: true, //麦克风启用禁用
      muted: false, //远程是否静音，true 表示静音、false
      showVideo: false,
    };
    if(this.state.device!=='undefined' || this.state.device!==''){
      this.getParams();
    }
  }

  getParams = () => {
    this.closeFn();
    setTimeout(() => {
      this.setState({
        closeLive:false,
        loading: true,
        romoteTrack: false,
        exitIntercom: false,
        showVideo: false,
      });
      var payload = {
        channelRole:Role.VIEWER,
        deviceId: this.state.device
      };
      const config = {
        headers:{
        Authorization: localStorage.getItem('USER_TOKEN')
        }
      };
      axios.get('/signaling?channelRole='+payload.channelRole+'&deviceId='+payload.deviceId,config)
        .then((data) => {
          if (data.data.code === "CVSI0000") {
            this.setState({
              loading: false,
              romoteTrack: false,
              exitIntercom: false,
              showVideo: true
            });
            let result = data.data.data;
            let config = {};
            config.endpoints = result.endpoints.reduce(
              (endpoints, endpoint) => {
                endpoints[endpoint.protocol] = endpoint.resourceEndpoint;
                return endpoints;
              },
              {}
            );
            config.signalingChannel = result.signalingChannel;
            config.iceServers = result.iceServers;
            config.tempCredential = result.tempCredential;
            let webrtc = new WebRTC_SDK(
              config,
              this.remoteRef,
              this.localRef,
              this.callback
            );
            this.webrtc = webrtc;
            // alert(JSON.stringify(this.webrtc))
            console.log('webrtc',webrtc);
            webrtc.init();
          } else {
            this.setState({
              errorMessage: data.data.message,
              open: true,
              error:true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorMessage: e.message || e.data,
            open: true,
            error:true,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }, 100);
  };

  callback = (result) => {
    if (result.type === "constraints") {
      //本地摄像头或者麦克风
      if (result.result.audio) {
        this.setState({
          exitIntercom: true,
        });
      } else {
        this.setState({
          exitIntercom: false,
        });
      }
    } else if (result.type === "localstream") {
      //本地视频流
    } else if (result.type === "romoteTrack") {
      //远程视频流联通
      this.setState({
        romoteTrack: true,
      });
    }
  };

  setCurrent = (item) => {
    this.setState({
      device: item,
    });
  };
  stopIntercom() {
    this.setState({
      intercom: false,
    });
    this.webrtc.stopIntercom();
  }
  startIntercom() {
    this.setState({
      intercom: true,
    });
    this.webrtc.startIntercom();
  }
  capture() {
    this.webrtc.capture();
  }

  fullScreen() {
    this.webrtc.fullScreen();
  }

  mute() {
    this.webrtc.mute();
    this.setState({
      muted: true,
    });
  }

  continueLive() {
    this.getParams();
  }

  closeFn() {
    
    if (this.webrtc) {
      this.setState({
        closeLive:true,
        showVideo: false,
        romoteTrack: false,
        exitIntercom: false, //是否有麦克风
        intercom: true, //麦克风启用禁用
        muted: false, //远程是否静音，true 表示静音、false
      });
      this.webrtc.close();
      this.webrtc = null;
    }
  }

  componentWillUnmount() {
    this.closeFn();
  }

  componentDidMount() {}

  //取消静音
  noMute() {
    this.webrtc.noMute();
    this.setState({
      muted: false,
    });
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      errorMessage: '',
      error: false,
    });
  };
  render() {
    return (<>
    {this.state.showVideo? (<DeviceInfo data={this.state.site}/>):this.state.closeLive? (<DeviceInfo data={this.state.site}/>):this.state.open? (<DeviceInfo data={this.state.site}/>):this.state.loading?(<DeviceInfo data={this.state.site}/>):''}
    {this.state.open? (<Paper elevation={0} style={{textAlign:'center',marginLeft:'18px',height:'69%'}}>
        <Typography style={{position:'fixed',left:'50%',top:'68%'}}>The device has offline</Typography>
       </Paper>):''}
    {this.state.loading?(<Paper elevation={0} style={{marginLeft:'18px',height:'69%',borderRadius:'10px'}}><DynamicLoader /></Paper>):('')}
     
     
      {this.state.showVideo ? (
        
      <Paper elevation={0} style={{marginLeft:'18px',borderRadius:'10px',marginTop:10,height:'69%',position:'relative',backgroundColor:'black'}}>
        <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ position:'absolute' }}
        >
        <Grid item>
          <Typography style={{ backgroundColor: '#36c96d', color: '#fff',marginLeft:'2%', width: '3%', borderRadius: '5px', textAlign: 'center',position:'absolute',zIndex:'999' }}>Live</Typography>
        </Grid>
        <Grid item style={{marginRight:'13px',marginTop:'5px',zIndex:'999'}}>
          <IconButton style={{backgroundColor: 'rgb(193 190 190)',borderRadius:'10px'}}  onClick={() => this.closeFn()} >
          <CloseIcon/>
          </IconButton>
        </Grid>
        
        </Grid>
            <Box container>
              <Box item marginRight={'13px'} marginLeft={'96%'} marginTop={'26%'} style={{position:'absolute',bottom:'18px',right:'5px',zIndex:'999'}}>
                <IconButton style={{backgroundColor: 'rgb(193 190 190)',borderRadius:'10px'}} onClick={() => { this.fullScreen() }} >
                  <FullScreen />
                </IconButton>
              </Box>
            </Box>
          <video
            width='100%    !important'
            height='100%   !important'
            style={{objectFit:'cover',borderRadius:'10px',position:'absolute'}}
            src={''}
            ref={(node) => (this.remoteRef = node)}
            autoPlay
          />
      </Paper>
       ) : ('')}
      {this.state.closeLive? (<Paper elevation={0} style={{ height:'69%', textAlign:'center',marginLeft:'18px'}}>
        <div style={{position:'fixed',left:'50%',top:'50%'}}>
        <Typography gutterBottom> Live video is closed </Typography>
        <Typography>Click <u style={{color:'#36C96D'}} onClick={()=>{this.continueLive()}}>continue</u> to play it</Typography>
        </div>
      </Paper>):''}
        <Snackbar open={this.state.error}
        autoHideDuration={3000} 
        onClose={this.handleClose} 
        anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}>
        <Alert onClose={this.handleClose} severity='error'>
        {this.state.errorMessage}
        </Alert>
      </Snackbar>
      </>
      
    );
  }
}
