import React, { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import CachedIcon from "../assets/event/ic_return.png";
import FilterAltIcon from "../assets/event/ic_search.png";
import NotificationIcon from "../assets/event/ic_message.png";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ZkDatePicker from "./events/ZkDatePicker";
import EventItem from "./events/EventItem";
import { styled } from "@mui/material/styles";
import WeekBar from "./events/WeekBar";
import DilogBox from "../components/DilogBox";
import EventVideo from "./events/EventVideo";
import AdvancedFilter from "./events/AdvancedFilter";
import Notification from "./events/Notification";
import { getEvents, getDashUrl } from "../services/EventService";
import { showLoad, showMessage } from "../slices/Common";
import { useDispatch } from "react-redux";

const CustomButtonGrid = styled(Grid)(() => ({
  height: "40px",
  width: "40px",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#E6E6E6",
  marginRight: "14px",
  cursor: "pointer",
}));
export const paginationStyles = makeStyles(() => ({
  root: {
    "& button": {
      border: "none",
      backgroundColor: "#ffffff",
      color: "#304669",
    },
    "& .Mui-selected": {
      backgroundColor: "#36C96D !important",
      color: "#FFFFFF",
    },
    "& .Mui-disabled": {
      backgroundColor: "#ffffff !important",
    },
  },
  ul: {},
}));
export default function Event(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [eventDia, setEventDia] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [notificationDia, setNotificationDia] = useState(false);
  const [currenDate, setCurrenDate] = useState(new Date());
  const [currentEvent, setCurrenEvent] = useState(null);
  const [open, setOpen] = useState(false);
  const classs = paginationStyles();
  const [eventDashUrl, setEventDashUrl] = useState("");

  const [events, setEvents] = useState({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    notifications: [],
  });

  const itemClick = (event) => {
    setCurrenEvent(event);
    let message = event.message;
    let eventInfo = {};
    message.split("#").forEach((item) => {
      let temp = item.split("_");
      eventInfo[temp[0]] = temp[1];
    });
    let params = {
      deviceId: eventInfo.deviceId,
      displayFragmentNumber: "ALWAYS",
      displayFragmentTimestamp: "NEVER",
      endTimestamp: eventInfo.endTime * 1000,
      expires: "3000",
      fragmentSelectorType: "SERVER_TIMESTAMP",
      maxManifestFragmentResults: 10,
      playbackMode: "LIVE_REPLAY",
      startTimestamp: eventInfo.startTime * 1000,
    };
    dispatch(
      showLoad({
        showLoad: true,
        loadingMessage: "Loading Event Details",
      })
    );
    getDashUrl(params)
      .then((res) => {
        let data = res.data;
        if (data.code === "CVSI0000") {
          setEventDashUrl(data.data.url);
          setEventDia(true);
        } else {
          dispatch(
            showMessage({
              showMessage: true,
              messageType: "error",
              message: data.message,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          showMessage({
            showMessage: true,
            messageType: "error",
            message: e.message,
          })
        );
      })
      .finally(() => {
        dispatch(
          showLoad({
            showLoad: false,
            loadingMessage: "",
          })
        );
      });
  };
  const refresh = () => {
    setCurrenDate(new Date());
    setEvents({
      ...events,
      totalCount: 0,
      totalPages: 0,
      notifications: [],
    });
  };

  const loadEventList = () => {
    setEvents({
      ...events,
      totalCount: 0,
      totalPages: 0,
      notifications: [],
    });
    let startTime = new Date(currenDate.setHours(0, 0, 0, 0)).getTime();
    let endTime = new Date(currenDate.setHours(23, 59, 59, 999)).getTime();
    let params = {
      startTime: startTime,
      endTime: endTime,
      pageNumber: events.currentPage,
      pageSize: "10",
    };
    dispatch(
      showLoad({
        showLoad: true,
        loadingMessage: "Loading Events",
      })
    );
    getEvents(params)
      .then((res) => {
      try {
        if (res.status === 200) {
          let result = res.data.data;
          if (result.currentPage) {
            setEvents(result);
          } else {
            dispatch(
              showMessage({
                showMessage: true,
                messageType: "error",
                message: res.data.message,
              })
            );
          }
        } else {
          dispatch(
            showMessage({
              showMessage: true,
              messageType: "error",
              message: res.data.message,
            })
          );
        }
      } catch (error) {
        dispatch(
          showMessage({
            showMessage: true,
            messageType: "error",
            message: error,
          })
        );
      }
    })
    .catch((e) => {
      dispatch(
        showMessage({
          showMessage: true,
          messageType: "error",
          message: e.message,
        })
      );
    })
    .finally(() => {
      dispatch(
        showLoad({
          showLoad: false,
          loadingMessage: "",
        })
      );
    });
};

  useEffect(() => {
    loadEventList();
  }, [currenDate, events.currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid lg={6} style={{ width: "100%", padding: "20px" ,height:'100%'}}>
      <Grid style={{height:'24%'}}>
        <Grid 
       style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
          >
            <Grid className="app-text">{"Events"}</Grid>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                views={["year", "month"]}
                value={currenDate}
                onChange={(newValue) => {
                  setCurrenDate(newValue);
                }}
                renderInput={(params) => {
                  let value = params.inputProps.value || "";
                  return (
                    <div
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="app-text"
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        fontWeight: 400,
                        fontSize: "20px",
                      }}
                    >
                      {value.replace(" ", " , ")}
                      <Arrow
                        className="app-text"
                        style={{
                          height: "1em",
                          width: "1em",
                          transform: `rotate270deg`,
                          padding: "0px 8px",
                          marginTop: "13px",
                          fontSize: "12px",
                        }}
                      />
                    </div>
                  );
                }}
              />
            </LocalizationProvider>
            <ZkDatePicker
              handleClose={() => {
                setOpen(false);
              }}
              handleOk={(date) => {
                setCurrenDate(date);
                setOpen(false);
              }}
              open={open}
              date={currenDate}
            ></ZkDatePicker>
          </Grid>

          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <CustomButtonGrid
              onClick={() => {
                refresh();
              }}
            >
              <img alt="" src={CachedIcon} />
            </CustomButtonGrid>
            <CustomButtonGrid
              onClick={() => {
                setAdvancedFilter(true);
              }}
            >
              <img alt="" src={FilterAltIcon} />
            </CustomButtonGrid>

            <CustomButtonGrid
              onClick={() => {
                setNotificationDia(true);
              }}
            >
              <img alt="" src={NotificationIcon} />
            </CustomButtonGrid>
          </Grid>
        </Grid>

        <Grid sx={{ marginTop: "20px" }}>
          <WeekBar
            date={currenDate}
            dateClick={(item) => {
              setCurrenDate(item);
            }}
          ></WeekBar>
        </Grid>

        <span className="app-text" style={{ fontSize: "14px" }}>
          {`today : ${events.totalCount}, this page : ${events.notifications.length}`}
        </span>
        </Grid>

        <Grid style={{height:'70%',overflow:'auto',paddingRight:'16px'}}>
          {events.notifications.map((value, index) => {
            return (
              <EventItem
                info={value}
                key={value.id}
                itemClick={() => {
                  itemClick(value);
                }}
              ></EventItem>
            );
          })}
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "center",
            margin: "12px 0px",
          }}
        >
          <Pagination
            className={`${classs.root} ${classs.ul}`}
            showFirstButton
            showLastButton
            onChange={(event, page) => {
              setEvents({
                ...events,
                currentPage: page,
              });
            }}
            count={events.totalPages}
            page={events.currentPage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>

        <DilogBox
          open={eventDia}
          HeaderContent={" "}
          close={(data) => {
            setEventDia(false);
          }}
          children={
            eventDia ? (
              <EventVideo
                eventDashUrl={eventDashUrl}
                event={currentEvent}
                starTheEvent={true}
              ></EventVideo>
            ) : (
              ""
            )
          }
        />

        <DilogBox
          open={advancedFilter}
          dialogWidth={'500px'}
          HeaderContent={"Advanced filter"}
          close={(dta) => {
            setAdvancedFilter(false);
          }}
          children={<AdvancedFilter></AdvancedFilter>}
        />

        <DilogBox
          open={notificationDia}
          HeaderContent={"Advanced filter"}
          close={(dta) => {
            setNotificationDia(false);
          }}
          children={<Notification></Notification>}
        />
      
    </Grid>
  );
}
