import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SubscriptionService from '../services/SubScriptionService';

const initialState = [];

export const getSubscriptionList = createAsyncThunk('subscription/', async (payload) => {
  const res = await SubscriptionService.getSubscriptionList(payload);
  return res.data;
});
export const createSubscription = createAsyncThunk('subscription/create', async (payload) => {
  const res = await SubscriptionService.createSubscription(payload);
  return res;
});

export const paypalPayment = createAsyncThunk('subscription/paypalPayment', async (payload) => {
  const res = await SubscriptionService.paypalPayment(payload);
  return res;
});

export const getPriceList = createAsyncThunk('subscription/', async (payload) => {
  const res = await SubscriptionService.getPricestrategiesList(payload);
  return res.data;
});

export const getPriceByPlanName = createAsyncThunk('subscription/', async (payload) => {
  const res = await SubscriptionService.getPriceByPlanName(payload);
  return res.data;
});

export const cancelSubscription = createAsyncThunk('subscription/cancel', async (payload) => {
  const res = await SubscriptionService.cancelSubscription(payload);
  return res;
});

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
});

const { reducer } = subscriptionSlice;
export default reducer;
