import React, { useEffect } from 'react';
import OnBordlayout from '../../components/OnBordlayout';
import { Grid, Typography, Checkbox, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { ReactComponent as IotLogo } from '../../assets/logo.svg';
import { ReactComponent as Help } from '../../assets/Help.svg';
import CustomInput from '../../components/CustomInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PhoneInput from 'react-phone-input-2';
import CommonUtil from '../../Util/CommonUtils';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createUser } from '../../slices/User';
import { useHistory } from 'react-router';
import { REACT_LOGIN } from '../../constants/ReactEndPoints';
import DilogBox from '../../components/DilogBox';
import SignupConfirmation from './SignupConfirmation';

export default function Signup() {
  const [visibility, setVisibility] = React.useState(true);
  const [visibilityCP, setVisibilityCP] = React.useState(true);
  const [payload, setPayload] = React.useState({ firstName: '', lastName: '', phone: '', email: '', password: '', confirmPassword: '' });
  const [error, setError] = React.useState({ firstName: '', lastName: '', phone: '', email: '', password: '', confirmPassword: '' });
  const [userNameType, setUserNameType] = React.useState('Email');
  const [countryCode, setCountryCode] = React.useState(CommonUtil.getLocationDetails('country_calling_code'));
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    resetFields();
  }, [userNameType]);

  useEffect(() => {
    setError({
      ...error,
      phone: '',
    });
  }, [phoneNumber, countryCode]);

  const resetFields = () => {
    setPayload({
      ...payload,
      email: '',
      phone: '',
    });
    setError({
      ...error,
      email: '',
      phone: '',
    });
    setPhoneNumber('');
    setCountryCode(CommonUtil.getLocationDetails('country_calling_code'));
  };

  const handleUsernameType = (event) => {
    setUserNameType(event.target.value);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handlePhoneNumberChange = (value) => {
    var mobile = '+' + countryCode.replace('+', '') + '-' + value;
    if (mobile.length > 25) {
      return false;
    } else {
      setPhoneNumber(value);
    }
  };

  const validatePayload = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({
        ...error,
        firstName: t('SIOT00001'),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(payload.lastName)) {
      setError({
        ...error,
        lastName: t('SIOT00001'),
      });
      return false;
    }
    if (userNameType === 'Email') {
      if (CommonUtil.isEmptyString(payload.email)) {
        setError({
          ...error,
          email: t('SIOT00001'),
        });
        return false;
      }
      if (!CommonUtil.isValidEmail(payload.email)) {
        setError({
          ...error,
          email: t('SIOT00003'),
        });
        return false;
      }
    } else {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          ...error,
          phone: t('SIOT00001'),
        });
        return false;
      } else {
        if (CommonUtil.isEmptyString(countryCode)) {
          setError({
            ...error,
            phone: t('SIOT00007'),
          });
          return false;
        } else {
          var mobile = '+' + countryCode.replace('+', '') + '-' + phoneNumber;
          if (mobile.length < 10 || mobile.length > 25) {
            setError({
              ...error,
              phone: t('SIOT00008'),
            });
            return false;
          } else {
            setPayload({
              ...payload,
              phone: mobile,
            });
          }
        }
      }
    }
    if (CommonUtil.isEmptyString(payload.password)) {
      setError({
        ...error,
        password: t('SIOT00001'),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(payload.confirmPassword)) {
      setError({
        ...error,
        confirmPassword: t('SIOT00001'),
      });
      return false;
    }
    if (payload.password !== payload.confirmPassword) {
      setError({
        ...error,
        confirmPassword: t('SIOT00030'),
      });
      return false;
    }
    return true;
  };

  const handleError = (data) => {
    if (data.code === 'UASE0020') {
      setError({ ...error, email: t('SIOT00031'), phone: t('SIOT00032') });
    }
  };
  const handleSubmit = () => {
    if (validatePayload()) {
      var state = {
        ...payload,
        phone: userNameType !== 'Email' ? '+' + countryCode.replace('+', '') + '-' + phoneNumber : '',
      };
      dispatch(createUser(state))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0001') {
            setSuccess(true);
          } else {
            handleError(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const FieldFirstName = (key) => (
    <CustomInput
      type={'text'}
      required
      style={{ marginBottom: '14px', marginTop: '14px' }}
      autoComplete='off'
      fullWidth
      error={!CommonUtil.isEmptyString(error.firstName)}
      name='firstName'
      label={t('SIOT00024')}
      value={payload.firstName}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.firstName}
      validation='alpha-numeric'
      inputProps={{
        maxLength: 100,
      }}
    />
  );
  const FieldLastName = (key) => (
    <CustomInput
      type={'text'}
      required
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={!CommonUtil.isEmptyString(error.lastName)}
      name='lastName'
      label={t('SIOT00025')}
      value={payload.lastName}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.lastName}
      validation='alpha-numeric'
      inputProps={{
        maxLength: 100,
      }}
    />
  );
  const FieldEmail = (key) => (
    <CustomInput
      type={'text'}
      required
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={!CommonUtil.isEmptyString(error.email)}
      name='email'
      label={t('SIOT00009')}
      value={payload.email}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.email}
      validation='email'
      inputProps={{
        maxLength: 150,
      }}
    />
  );

  const FiledPhoneNumber = () => (
    <Grid container>
      <Grid item lg={4} paddingRight={'8px'}>
        <PhoneInput
          autoFormat={false}
          countryCodeEditable={true}
          enableSearch='true'
          searchPlaceholder={'search'}
          searchNotFound={'search'}
          onChange={(value, data) => setCountryCode(data.dialCode)}
          inputProps={{
            disabled: true,
          }}
          value={countryCode}
          country={CommonUtil.getDefaultCountryCode()}
          placeholder={false}
          specialLabel={t('SIOT00011') + ' *'}
        />
      </Grid>
      <Grid lg={8}>
        <CustomInput
          type={'text'}
          required
          style={{ marginBottom: '14px' }}
          autoComplete='off'
          fullWidth
          error={!CommonUtil.isEmptyString(error.phone)}
          name='phoneNumber'
          label={t('SIOT00010')}
          value={phoneNumber}
          variant='outlined'
          size='small'
          handleChange={(e) => handlePhoneNumberChange(e.target.value)}
          helperText={error.phone}
          validation='numeric'
        />
      </Grid>
    </Grid>
  );
  const FieldPassword = (key) => (
    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
      <CustomInput
        required
        type={visibility ? 'password' : 'text'}
        autoComplete='off'
        fullWidth
        error={error.password}
        name='password'
        label={t('SIOT00012')}
        value={payload.password}
        variant='outlined'
        size='small'
        handleChange={handleChange}
        helperText={error.password}
        validation='password'
        InputProps={{
          endAdornment: visibility ? (
            <VisibilityOffIcon
              onClick={() => {
                setVisibility(!visibility);
              }}
            />
          ) : (
            <VisibilityIcon
              onClick={() => {
                setVisibility(!visibility);
              }}
            />
          ),
        }}
      />
      <Grid style={{ margin: '0px 4px', alignItems: 'center' }}>
        <Help onClick={() => setOpen(!open)} />
      </Grid>
    </Grid>
  );
  const FieldConfirmPassword = (key) => (
    <CustomInput
      required
      type={visibilityCP ? 'password' : 'text'}
      style={{ marginBottom: '14px' }}
      autoComplete='off'
      fullWidth
      error={error.confirmPassword}
      name='confirmPassword'
      label={t('SIOT00026')}
      value={payload.confirmPassword}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.confirmPassword}
      validation='password'
      InputProps={{
        endAdornment: visibilityCP ? (
          <VisibilityOffIcon
            onClick={() => {
              setVisibilityCP(!visibilityCP);
            }}
          />
        ) : (
          <VisibilityIcon
            onClick={() => {
              setVisibilityCP(!visibilityCP);
            }}
          />
        ),
      }}
    />
  );

  const Signup = (key) => (
    <Grid>
      <span className='app-text-header'>{t('SIOT00023')}</span>
      {FieldFirstName()}
      {FieldLastName()}
      <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={userNameType} onChange={handleUsernameType}>
        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Email' control={<Radio color='success' />} label={t('SIOT00015')} color='success' />

        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Phone' control={<Radio color='success' />} label={t('SIOT00016')} color='success' />
      </RadioGroup>{' '}
      {userNameType === 'Email' ? FieldEmail() : FiledPhoneNumber()}
      {FieldPassword()}
      <br />
      {FieldConfirmPassword()}
      <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
        {t('SIOT00023')}
      </Button>
      <Grid style={{ flexDirection: 'row', display: 'flex' }}></Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', margin: '14px 0px' }}>
        <Typography style={{ color: 'gray', fontSize: '12px' }}> {t('SIOT00027')} </Typography>
        <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
          {' '}
          {t('SIOT00019')}{' '}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <>
      {success ? (
        <SignupConfirmation />
      ) : (
        <>
          <OnBordlayout>
            <Grid style={{ height: '100%' }}>
              <Grid sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' } }}>
                <IotLogo />
              </Grid>
              {Signup()}
            </Grid>
          </OnBordlayout>
          <DilogBox open={open} close={(data) => setOpen(false)} HeaderContent={'Create Password'}>
            <Grid>
              <Typography>To keep password secure, it must contain: capital letters, special symbols, numbers. Example : C89$Mp@q </Typography>
            </Grid>
          </DilogBox>
        </>
      )}
    </>
  );
}
