import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Grid, Button } from '@mui/material';
import React, { useState } from 'react';
import PlaybackEvent from '../events/PlaybackEvent';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import ZkDatePicker from '../events/ZkDatePicker';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { useTranslation } from 'react-i18next';
import VideoThumbnail from '../../assets/dashboard/VideoThumbnail.png';

export default function Playback(props) {
  const { t } = useTranslation();
  const [currenDate, setCurrenDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([
    { duration: '10:00:00', time: '00"30"' },
    { duration: '10:30:00', time: '00"20"' },
    { duration: '11:00:00', time: '01"30"' },
    { duration: '12:30:00', time: '02"50"' },
    { duration: '12:50:00', time: '02"50"' },
    { duration: '10:00:00', time: '00"30"' },
    { duration: '10:30:00', time: '00"20"' },
    { duration: '11:00:00', time: '01"30"' },
    { duration: '12:30:00', time: '02"50"' },
    { duration: '12:50:00', time: '02"50"' },
    { duration: '10:00:00', time: '00"30"' },
    { duration: '10:30:00', time: '00"20"' },
    { duration: '11:00:00', time: '01"30"' },
    { duration: '12:30:00', time: '02"50"' },
    { duration: '12:50:00', time: '02"50"' },
    { duration: '10:00:00', time: '00"30"' },
    { duration: '10:30:00', time: '00"20"' },
    { duration: '11:00:00', time: '01"30"' },
    { duration: '12:30:00', time: '02"50"' },
    { duration: '12:50:00', time: '02"50"' },
  ]);

  const videoThumbnail = (event) => (
    <>
      <Box display={'flex'}>
        <Box item flexGrow={1}>
          {event.duration}
        </Box>
        <Box item>{event.time}</Box>
      </Box>
      <Box display={'flex'}>
        <img alt='' src={VideoThumbnail} style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
      </Box>
    </>
  );
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item lg={7}>
          <PlaybackEvent
            videoHeight='20vh'
            DemoInfo={{
              siteA: 'front door device',
              siteB: 'Site A',
              siteC: 'Site A kitchen zone...',
              Internetspeed: '200 kb/s      ',
              size: ' 25MB',
              battery: '70%',
              duration: '20 min',
            }}
          />
        </Grid>
      </Grid>
      <Grid>
        <Box display={'flex'}>
          <Box item flexGrow={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                views={['year', 'month', 'day']}
                inputFormat={'yyyy-MMMM-dd'}
                value={currenDate}
                onChange={(newValue) => {
                  setCurrenDate(newValue);
                }}
                renderInput={(params) => {
                  return (
                    <div
                      onClick={() => {
                        setOpen(true);
                      }}
                      className='app-text'
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        fontWeight: 400,
                        fontSize: '20px',
                      }}
                    >
                      {params.inputProps.value}
                      <Arrow
                        className='app-text'
                        style={{
                          height: '1em',
                          width: '1em',
                          transform: `rotate270deg`,
                          padding: '0px 8px',
                          marginTop: '13px',
                          fontSize: '12px',
                        }}
                      />
                    </div>
                  );
                }}
              />
            </LocalizationProvider>
            <ZkDatePicker
              handleClose={() => {
                setOpen(false);
              }}
              handleOk={(date) => {
                setCurrenDate(date);
                setOpen(false);
              }}
              open={open}
              date={currenDate}
            ></ZkDatePicker>
          </Box>
          <Box>
            <Button size='small' type='submit' variant='contained' style={{ backgroundColor: '#36C96D', color: '#FFFF' }} onClick={() => props.onClick(true)}>
              {props.PrimaryButton ? props.PrimaryButton : t('Cloud')}
            </Button>{' '}
            <Button size='small' variant='contained' style={{ backgroundColor: '#FFFF', color: '#36C96D', border: '0.5px solid #36C96D' }} onClick={() => props.onClick(false)}>
              {props.SecondaryButton ? props.SecondaryButton : t('SD Card')}
            </Button>
          </Box>
        </Box>
      </Grid>
      <span className='app-text' style={{ fontSize: '14px' }}>
        {'today : 689, this page : 100'}
      </span>
      <Grid container spacing={1}>
        {events.map((event) => {
          return (
            <Grid item lg={2}>
              {videoThumbnail(event)}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
