import React from 'react';
import { Grid, Link, Divider, Button, Typography, IconButton, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import InfoDilogBox from '../../components/InfoDilogBox'

export default function DeleteProfilr(props) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleChange = () => {
        props.ScreenChangeTo('Current account profile', '',false)
    };

    return (
        <Grid>
            <Typography>Continue to delete account？</Typography>
            <Typography style={{ fontSize: '14px', color: '#97A2B4' }}>You have to handle the digital asset :</Typography>
            <Grid style={{ fontSize: '12px', flexDirection: 'row', display: 'flex' }}>
                <Checkbox
                    defaultChecked
                    sx={{
                        [`&, &.${checkboxClasses.checked}`]: { color: "#36C96D" }
                    }}
                />
                <Typography> {t('SIOT00047')}</Typography>
            </Grid>
            <Grid style={{ fontSize: '12px', flexDirection: 'row', display: 'flex' }}>
                <Checkbox
                    defaultChecked
                    sx={{
                        [`&, &.${checkboxClasses.checked}`]: { color: "#36C96D" }
                    }}
                />
                <Typography> {t('SIOT00048')}</Typography>
            </Grid>

            <Grid style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <IconButton onClick={()=>setOpen(true)} style={{ backgroundColor: '#F51818', color: '#FFFF', height: '38px', width: '150px', borderRadius: '6px', fontSize: '14px' }}>
                    {("Check status")}
                </IconButton>
                <IconButton onClick={()=>handleChange(true)} style={{ backgroundColor: '#36c96d', color: '#FFFF', height: '38px', width: '150px', borderRadius: '6px', fontSize: '14px' }}>
                    {("Cancel")}
                </IconButton>
            </Grid>
            <InfoDilogBox open={open}
                close={() => setOpen(false)}
                children={<Grid style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <IconButton onClick={()=>setOpen(true)} style={{ backgroundColor: '#F51818', color: '#FFFF', height: '38px', width: '150px', borderRadius: '6px', fontSize: '14px' }}>
                        {("Check status")}
                    </IconButton>
                    <IconButton onClick={()=>setOpen(false)} style={{ backgroundColor: '#36c96d', color: '#FFFF', height: '38px', width: '150px', borderRadius: '6px', fontSize: '14px' }}>
                        {("Cancel")}
                    </IconButton>
                </Grid>}
            />
        </Grid>
    )
};