import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CompanyService from '../services/CompanyService';
import CommonUtil from '../Util/CommonUtils';

const initialState = [];

export const createCompany = createAsyncThunk('company/create', async (payload) => {
  const res = await CompanyService.create(payload);
  return res.data;
});

export const verifyCompany = createAsyncThunk('company/verify', async (payload) => {
  const res = await CompanyService.verifyCompany(payload);
  return res.data;
});

export const filterCompany = createAsyncThunk('company/filter', async (payload) => {
  const res = await CompanyService.filterCompany(payload);
  return res.data;
});

export const updateCompany = createAsyncThunk('company/filter', async (payload) => {
  const res = await CompanyService.updateCompany(payload);
  return res.data;
});

const companySlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: {
    [filterCompany.fulfilled]: (state, action) => {
      if (action.payload.code === 'UASI0000' && !CommonUtil.isEmpty(action.payload.data)) {
        return action.payload.data.company;
      } else {
        return [];
      }
    },
  },
});

const { reducer } = companySlice;
export default reducer;
