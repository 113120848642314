import React from 'react';
import SwitchOrganization from './SwitchOrganization';
import EditProfile from './EditProfile';
import ProfileMain from './ProfileMain';
import CreateOrganizationProfile from './CreateOrganizationProfile';
import DilogBox from '../../components/DilogBox';
import ProfileModifier from './Profilehandler';
import DeleteProfile from './DeleteProfile';
import EditOrganizationprofile from './EditOrganizationprofile';
import Switchlanguage from './switchlanguage';
import ResetPasswordProfile from './ResetPasswordProfile';
import ResetPasswordProfileSuccessPage from './ResetPasswordProfileSuccessPage';
import SelectOrganizationTypeProfile from './SelectOrganizationTypeProfile';

export default function SwitchBox(props) {
  const [Screen, setScreen] = React.useState('DefaultProfile');
  const [size, setSize] = React.useState(props.size ? props.size : 'Default');
  const handleChange = (screen, size) => {
    setScreen(screen);
    setSize(size);
  };
  function SwitchCase(props) {
    switch (props.Screen) {
      case 'DefaultProfile':
        return <ProfileMain ScreenChangeTo={handleChange} />;
      case 'Current account profile':
        return <ProfileModifier ScreenChangeTo={handleChange} />;
      case 'Edit My profile':
        return <EditProfile ScreenChangeTo={handleChange} />;
      case 'Switch organization':
        return <SwitchOrganization ScreenChangeTo={handleChange} {...props} />;
      case 'Organization profile':
        return <EditOrganizationprofile ScreenChangeTo={handleChange} />;
      case 'Create organization':
        return <SelectOrganizationTypeProfile ScreenChangeTo={handleChange} test={false} />;
      case 'Delete my account':
        return <DeleteProfile ScreenChangeTo={handleChange} />;
      case 'Switch language':
        return <Switchlanguage ScreenChangeTo={handleChange} />;
      case 'Reset password':
        return <ResetPasswordProfile ScreenChangeTo={handleChange} />;
      case 'ResetPassword_Successfull':
        return <ResetPasswordProfileSuccessPage ScreenChangeTo={handleChange} />;
      case 'Create Organization Profile':
        return <CreateOrganizationProfile ScreenChangeTo={handleChange} test={false} />;
      default:
        return <p>Not Found</p>;
    }
  }
  return (
    <DilogBox
      open={props.test}
      size={size}
      HeaderContent={Screen}
      close={(data) => {
        props.close(false);
        setScreen('DefaultProfile');
        setSize('Default');
      }}
      children={
        <SwitchCase
          Screen={Screen}
          close={() => {
            props.close(false);
            setScreen('DefaultProfile');
            setSize('Default');
          }}
        />
      }
    />
  );
}
