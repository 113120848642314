import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import OnBordlayout from '../../components/OnBordlayout';
import { useHistory } from 'react-router';
import Autocomplete from '@mui/material/Autocomplete';
import { REACT_LOGIN, REACT_SELECT_ORG_TYPE } from '../../constants/ReactEndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { filterCompany, verifyCompany } from '../../slices/Company';
import CommonUtil from '../../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

export default function SelectOrganization() {
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.company);
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(filterCompany({}));
  }, []);

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(company)) {
      setError(t('SIOT00029'));
      return;
    }
    var payload = {
      companyCode: company,
    };
    dispatch(verifyCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.setItem('USER_TOKEN', 'Bearer ' + data.data.access_token);
          localStorage.setItem('REFRESH_TOKEN', 'Bearer ' + data.data.refresh_token);
          const decoded = jwt_decode(data.data.access_token);
          localStorage.setItem('USER', JSON.stringify(decoded));

          history.push({ pathname: '/siot/dashboard', page: 'Dashboard' });
        } else {
          setError(data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <OnBordlayout>
      <Typography style={{ fontSize: '36px', color: '#797979', fontWeight: 'bold', fontFamily: 'Bahnschrift Light' }}>Select organization,</Typography>
      <Typography style={{ fontSize: '17px', color: '#304669', fontFamily: 'Bahnschrift Light', padding: '4px' }}>
        Please select the organization you created and enter this organization. You can also switch between your multiple organizations after logging in.
      </Typography>
      <Grid>
        <Autocomplete
          size='small'
          style={{ margin: '12px 0px' }}
          getOptionLabel={(option) => option.name}
          options={store}
          renderInput={(params) => <TextField required helperText={error} error={!CommonUtil.isEmptyString(error)} {...params} label='Select organization or input code' />}
          onChange={(event, newValue) => {
            if (!CommonUtil.isEmpty(newValue)) {
              setCompany(newValue.code);
            }
          }}
        />
        <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
          Enter
        </Button>
        <br />
        <Grid container>
          <Grid item md={3} style={{ display: 'flex', justifyContent: 'flex-start', margin: '14px 0px' }}>
            <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
              {' '}
              {t('SIOT00050')}{' '}
            </Typography>
          </Grid>
          <Grid item md={9} style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
            <Typography style={{ color: 'gray', fontSize: '12px' }} onClick={(event) => true}>
              Don't have an organization?
            </Typography>
            <Typography onClick={() => history.push(REACT_SELECT_ORG_TYPE)} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
              Create one
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </OnBordlayout>
  );
}
