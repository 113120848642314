import { Redirect, Route, Switch } from 'react-router-dom';
import SecuredRoute from './config/SecuredRoute';
import {
  REACT_CREATE_ORG,
  REACT_DASHBORD,
  REACT_EVENT,
  REACT_FORGOT_PASSWORD,
  REACT_FORGOT_PASSWORD_SUCCESS,
  REACT_LOGIN,
  REACT_RESET_PASSWORD,
  REACT_USER_ACTIVATION,
  REACT_RESET_PASSWORD_PROFILE,
  REACT_RESET_PASSWORD_PROFILE_SUCCESS,
  REACT_RESULT_PAGE,
  REACT_SELECT_ORG,
  REACT_SELECT_ORG_TYPE,
  REACT_SIGNUP,
  REACT_SIGNUP_AGREE,
  REACT_EVENTS,
  REACT_BULLETIN_BOARD,
  REACT_SURVEILLANCE_DEVICE_LIST,
  REACT_SURVEILLANCE_PAYMENTPAGE,
  REACT_URL_SURVEILLANCE_SUBSCRIPTION,
  REACT_SURVEILLANCE_SUBSCRIPTION__ORDERLISTANDBILLING,
  REACT_URL_SURVELLANCE_DESCRIPTION,
  REACT_URL_SURVELLANCE_UNPAIDSUBSCRIPTION,
  REACT_URL_SURVELLANCE_VIEWORDERLIST,
  REACT_URL_SURVELLANCE_PAYMENTSUCCESSPAGE,
  REACT_URL_SURVELLANCE_ASSIGNDEVICEPAGE,
  REACT_URL_SURVELLANCE_PAYFORSUBSCRIPTIION,
} from "./constants/ReactEndPoints";
import DashBordLayout from "./layout-components/Dashbord/DashbordLayout";
import CreateOrganization from "./pages/onboard/CreateOrganization";
import ForgotPassword from "./pages/onboard/ForgotPassword";
import ForgotPasswordSuccess from "./pages/onboard/ForgotPasswordSuccess";
import Login from "./pages/onboard/Login";
import ResetPassword from "./pages/onboard/ResetPassword";
import ResultPage from "./pages/onboard/ResultPage";
import SelectOrganization from "./pages/onboard/SelectOrganization";
import SelectOrganizationType from "./pages/onboard/SelectOrganizationType";
import Signup from "./pages/onboard/Signup";
import SignupAgreement from "./pages/onboard/SignupAgreement";
import { reinit } from "./slices/User";
import store from "./store";
import Site from "./pages/Site";
import Event from "./pages/Event";
import UserActivation from "./pages/onboard/UserActivation";


import MasterPage from './pages/device-mock/MasterPage';

import ResetPasswordProfile from './pages/Profiler/ResetPasswordProfile';
import ResetPasswordProfileSuccessPage from './pages/Profiler/ResetPasswordProfileSuccessPage';
import MainLayoutSurveillance from './layout-components/DashbordSurveillance/MainLayoutSurveillance';
import Zlink from './components/Zlink';
import DeviceList from './pages/Device/DeviceList';
import PaymentPage from './pages/payments/PaymentPage';
import Subscription from './pages/Subscription/Subscription';
import OrderListAndBilling from './pages/Subscription/OrderListAndBilling';
import Live from './Surveillance/LiveVideo/Live';
import ViewUnpaidSubscription from './pages/payments/ViewUnpaidSubscription';
import ViewOrderlist from './pages/payments/ViewOrderList';
import PaymentSuccessPage from './pages/payments/PaymentSuccessPage';
import AssignDevicePage from './pages/payments/AssignDevicePage';
import PayForSubscription from './pages/payments/PayForSubscription';
const jwtToken = localStorage.getItem('USER_TOKEN');
if (jwtToken) {
  store.dispatch(reinit());
}

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from='/' to={REACT_LOGIN} />
      <Route path={REACT_LOGIN} component={Login} />
      <Route path={REACT_SIGNUP} component={Signup} />
      <Route path={REACT_SIGNUP_AGREE} component={SignupAgreement} />
      <Route path={REACT_FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={REACT_FORGOT_PASSWORD_SUCCESS} component={ForgotPasswordSuccess} />
      <Route path={REACT_RESET_PASSWORD} component={ResetPassword} />
      <Route path={REACT_USER_ACTIVATION} component={UserActivation} />
      <Route path={REACT_RESET_PASSWORD_PROFILE} component={ResetPasswordProfile} />
      <Route path={REACT_RESULT_PAGE} component={ResultPage} />
      <SecuredRoute path={REACT_CREATE_ORG} component={CreateOrganization} />
      <SecuredRoute path={REACT_SELECT_ORG_TYPE} component={SelectOrganizationType} />
      <SecuredRoute path={REACT_SELECT_ORG} component={SelectOrganization} />
      <SecuredRoute path={'/siot/site'} component={DashBordLayout} />
      <SecuredRoute
        path={REACT_RESET_PASSWORD_PROFILE_SUCCESS}
        component={ResetPasswordProfileSuccessPage}
      />

      <DashBordLayout
        children={
          <>
            <SecuredRoute path={REACT_DASHBORD} component={Site} />
            <SecuredRoute path={REACT_EVENT} component={Event} />
            <SecuredRoute path='/siot/device' component={MasterPage} />
          </>
        }
      />
    </Switch>
  );
}
