import React from 'react';
import { Grid, Typography } from '@mui/material';
import OnBordlayout from '../../components/OnBordlayout';
import { useHistory, useLocation } from 'react-router';
import { REACT_CREATE_ORG, REACT_SELECT_ORG } from '../../constants/ReactEndPoints';
import { useTranslation } from 'react-i18next';
export default function SelectOrganizationType() {
  const history = useHistory();
  const { t } = useTranslation();
  const [organizationType, setOrganizatonType] = React.useState('Family');

  return (
    <>
      <OnBordlayout>
        <Typography style={{ fontSize: '36px', color: '#797979', fontWeight: 'bold', fontFamily: 'Bahnschrift Light' }}>Create one organization,</Typography>
        <br />
        <Grid
          // onClick={() => { setOrganizatonType('Corporate') }}
          style={{
            backgroundColor: '#F9F9F9',
            boxShadow:
              organizationType === 'Corporate'
                ? '#7fff00 0px 0px 0px 2px, #7fff00 0px 4px 6px -1px, #7fff00 0px 1px 0px inset'
                : 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
            borderRadius: '4px',
            padding: '6px',
            backgroundColor: organizationType === 'Corporate' ? '#FFFF' : '#F9F9F9',
            maxWidth: '498px',
          }}
        >
          <Typography
            style={{
              font: 'normal medium 17px/20px Roboto',
              fontSize: '20px',
              color: '#304669',
              fontWeight: 'bold',
              padding: '4px',
            }}
          >
            Corporate company
          </Typography>
          <Typography style={{ padding: '4px' }}>
            company with department, designation, etc. This company will be large size organization for more complex human resource management.
          </Typography>
        </Grid>
        <br />
        <Grid
          onClick={() => history.push(REACT_CREATE_ORG)}
          // onClick={() => { setOrganizatonType('Family') }}
          style={{
            borderColor: 'red',
            borderRadius: '4px',
            boxShadow:
              organizationType === 'Family'
                ? '#7fff00 0px 0px 0px 2px, #7fff00 0px 4px 6px -1px, #7fff00 0px 1px 0px inset'
                : 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
            padding: '6px',
            maxWidth: '498px',
            backgroundColor: organizationType === 'Family' ? '#FFFF' : '#F9F9F9',
          }}
        >
          <Typography
            style={{
              fontStyle: 'Roboto',
              fontSize: '20px',
              color: '#304669',
              fontWeight: 'bold',
              padding: '4px 4px 4px',
            }}
          >
            Family and homeautomation
          </Typography>
          <Typography style={{ padding: '4px' }}>
            You will create one family and have your family member together. There is some people group to quickly manage the sharing of devices. It is suitable for home
            automation.
          </Typography>
        </Grid>
        <br />
        <Grid style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
          <Typography onClick={() => history.push({ pathname: REACT_SELECT_ORG })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
            {' '}
            {t('SIOT00052')}{' '}
          </Typography>
        </Grid>
      </OnBordlayout>
    </>
  );
}
