import React, { useEffect, useState } from 'react';
import { Grid, Box, InputLabel, MenuItem, Select, FormControl, Typography, Button, TextField, IconButton, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CommonUtil from '../../Util/CommonUtils';
import CustomInput from '../../components/CustomInput';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import defaultProfile from '../../assets/defaultProfile.png';
import FileBase64 from 'react-file-base64';
import { makeStyles } from '@material-ui/core';
import { filterCompany, updateCompany } from '../../slices/Company';
import { countryList } from '../../components/CountryList';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
}));
export default function EditOrganizationprofile(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [profile, setProfile] = React.useState(defaultProfile);

  const [state, setState] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    code: ''
  });

  const [error, setError] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    code: '',
    companyLogo: '',
  });

  useEffect(() => {
    if (localStorage.getItem('USER')) {
      var companyId = JSON.parse(localStorage.getItem('USER')).companyId;
      dispatch(filterCompany({ id: companyId }))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0000') {
            setState({
              ...state,
              id :data.data.company[0].id,
              name :data.data.company[0].name,
              code :data.data.company[0].code,
              email :data.data.company[0].email,
              country :data.data.company[0].country,
              state :data.data.company[0].state,
              pincode :data.data.company[0].pincode,
              city :data.data.company[0].city,
              address: data.data.company[0].address,
            });
            if (!CommonUtil.isEmptyString(data.data.users[0].profilePhoto)) {
              setProfile('data:image/jpeg;base64,' + data.data.company[0].companyLogo);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: '',
    });
  };

  const handleSubmit = () => {
    //props.ScreenChangeTo('DefaultProfile', '', false);
    if (!validateForm()) {
      return;
    }

    dispatch(updateCompany(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0007') {
          var userData = JSON.parse(localStorage.getItem('USER'));
          userData.companyName = state.name;
          userData.companyCode = state.code;
          localStorage.removeItem('USER');
          localStorage.setItem('USER', JSON.stringify(userData));
          enqueueSnackbar(data.message, {
            variant: 'success',
          });
          props.ScreenChangeTo('DefaultProfile', 'Default');
        } else {
          if (data.code === 'UASE0060') setError({ name: data.message });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validateForm = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({
        ...error,
        code: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.country)) {
      setError({
        ...error,
        country: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.city)) {
      setError({
        ...error,
        city: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.state)) {
      setError({
        ...error,
        state: t('SIOT00001'),
      });
      return;
    }
    return true;
  };
  const FieldOrgName = (key) => (
    <CustomInput
      fullWidth
      required
      error={error.name}
      name='name'
      label={t('SIOT00053')}
      variant='outlined'
      size='small'
      value={state.name}
      handleChange={handleChange}
      inputProps={{
        maxLength: 60,
      }}
      helperText={error.name}
    />
  );

  const FieldOrgCode = (key) => (
    <CustomInput
      required
      value={state.code}
      size='small'
      fullWidth
      name='code'
      label={t('SIOT00054')}
      error={error.code}
      helperText={error.code}
      handleChange={handleChange}
      inputProps={{
        maxLength: 30,
      }}
      validation={'code'}
      disabled='true'
    />
  );

  const FieldCountry = (key) => (
    <Autocomplete
      clearText='false'
      fullWidth
      size='small'
      getOptionLabel={(option) => option.country}
      options={countryList}
      value={{country:state.country}}
      renderInput={(params) => <TextField required helperText={error.country} error={error.country} {...params} label={t('SIOT00011')} />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
          setState({
            ...state,
            country: newValue.country,
          });
        }
      }}
    />
  );
  const FieldPriv_state = (key) => (
    <CustomInput
      validation={'alpha-numeric'}
      required
      fullWidth
      error={error.state}
      name='state'
      label={t('SIOT00039')}
      variant='outlined'
      size='small'
      value={state.state}
      handleChange={handleChange}
      inputProps={{
        maxlength: 50,
      }}
      helperText={error.state}
    />
  );
  const FieldCity = (key) => (
    <CustomInput
      required
      fullWidth
      error={error.city}
      name='city'
      label={t('SIOT00041')}
      variant='outlined'
      size='small'
      value={state.city}
      handleChange={handleChange}
      validation={'alpha-numeric'}
      inputProps={{
        maxlength: 50,
      }}
      helperText={error.city}
    />
  );
  const FieldPostcode = (key) => (
    <CustomInput
      validation='code'
      fullWidth
      error={error.pincode}
      name='pincode'
      label={t('SIOT00042')}
      variant='outlined'
      size='small'
      value={state.pincode}
      handleChange={handleChange}
      inputProps={{
        maxlength: 50,
      }}
      helperText={error.pincode}
    />
  );
  const FieldAddress = (key) => (
    <TextField
      autoComplete='off'
      fullWidth
      size='small'
      label={t('SIOT00055')}
      inputProps={{ className: classes.textarea, maxLength: 255 }}
      variant='outlined'
      aria-label='maximum height'
      value={state.address}
      error={error.address}
      name='address'
      onChange={handleChange}
      multiline
      helperText={error.address}
    />
  );

  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize, 10);
      if (size <= 2000) {
        setState({
          ...state,
          companyLogo: file.base64.split(',')[1],
        });
        setProfile(file.base64);
      } else {
      }
    }
  };

  return (
    <Grid style={{ height: '100%', width: '100%', backgroundColor: '#F4F5F7' }}>
      <Grid style={{ maxHeight: '92%', width: '100%', backgroundColor: '#F4F5F7', overflow: 'auto' }}>
        <form>
          <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '28px 0px' }}>
            <Grid style={{ border: '0.5px solid #474A4E80', padding: '12px', borderRadius: '8px', backgroundColor: '#FFFF' }}>
              <img alt='' src={profile} style={{ height: '59px', width: '59px', borderRadius: '8px' }} />
            </Grid>
            <Grid style={{ margin: '0px 12px' }}>
              <Typography style={{ fontFamily: 'Bahnschrift Light', color: '#97A2B4', paddingBottom: '4px' }}>Photos size up to maximum 3 MB</Typography>
              <IconButton
                style={{ color: '#36C96D', border: '0.5px solid #36C96D', padding: '4px 16px', fontStyle: 'none', borderRadius: '8px', fontFamily: 'Bahnschrift' }}
                variant='contained'
                component='label'
                color='secondary'
              >
                Add Photo
                <Grid style={{ display: 'none' }}>
                  <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                </Grid>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldOrgName()}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldCountry()}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldOrgCode()}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldPriv_state()}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldCity()}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldPostcode()}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldAddress()}
              </Box>
            </Grid>
          </Grid>
          <Grid
            onClick={() => {
              handleSubmit();
            }}
            style={{ position: 'absolute', bottom: '10px', width: `calc(100% - 24px)`, display: 'flex', justifyContent: 'center' }}
          >
            <Button style={{ width: '40%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}> {'Save'}</Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
