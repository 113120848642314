import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Grid, Typography, Button } from '@mui/material';
import InfoDilogBox from '../../components/InfoDilogBox';
import CustomInput from '../../components/CustomInput';
import { ReactComponent as Help } from '../../assets/Help.svg';
import CommonUtil from '../../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { createCompany } from '../../slices/Company';
import { useSnackbar } from 'notistack';
import { REACT_SELECT_ORG_TYPE } from '../../constants/ReactEndPoints';


export default function CreateOrganizationProfile(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [DilogContent, setDilogContent] = React.useState('Family name');
  const [state, setState] = useState({ code: '', name: '' });
  const [error, setError] = useState({ code: '', name: '' });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({
        ...error,
        code: t('SIOT00001'),
      });
      return;
    }

    dispatch(createCompany(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0006') {
          history.push({ pathname: '/siot/soon', page: 'Dashboard' });
          enqueueSnackbar(data.message, {
            variant: 'success',
          });
          props.ScreenChangeTo('DefaultProfile', 'Default');
        } else {
          data.code !== 'UASE0060' ? setError({ code: t('SIOT00036') }) : setError({ name: t('SIOT00037') });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <CustomInput
        type={'text'}
        value={state.name}
        required
        size='small'
        style={{ margin: '14px 0px' }}
        fullWidth
        name='name'
        error={error.name}
        helperText={error.name}
        label='Family name'
        handleChange={handleChange}
        InputProps={{
          endAdornment: <Help onClick={() => setOpen(!open) & setDilogContent('Family name')} />,
        }}
        inputProps={{
          maxLength: 60,
        }}
      />
      <CustomInput
        required
        size='small'
        style={{ margin: '14px 0px' }}
        fullWidth
        name='code'
        label='Family code'
        value={state.code}
        error={error.code}
        helperText={error.code}
        handleChange={handleChange}
        InputProps={{
          endAdornment: <Help onClick={() => setOpen(!open) & setDilogContent('Family code')} />,
        }}
        inputProps={{
          maxLength: 30,
        }}
        validation={'code'}
      />
      <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
        Create
      </Button>
      <InfoDilogBox
        open={open}
        HeaderContent={DilogContent}
        close={() => setOpen(false)}
        children={
          DilogContent === 'Family code' ? (
            <Grid
              onClick={() => {
                setOpen(false);
              }}
            >
              <Typography>Organization code is the unique code for the system to identify your organization.
              You can modify the organization name later,
              but the organization code will not change.</Typography>
              <br />
              <Typography>You can use a combination of letters and numbers to form an organization code.
              Easy- to-remember organization code will help you find relevant information faster.</Typography>
              <br />
              <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography>Example : </Typography>
                <Typography style={{ color: '#36c96d' }}> Office964, MyHome001</Typography>
              </Grid>
              {/* <button style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff', border:'none', borderRadius:'4px'  }}>Exit</button> */}
            </Grid>
          ) : (
            <Grid
              onClick={() => {
                setOpen(false);
              }}
            >
              <Typography>Organization is one of your family, restaurant, office, working space, etc.</Typography>
              <br />
              <Typography>Inside an organization,
               you can create some groups, departments.
              Groups can have family members or some of your employees.</Typography>
              {/* <button style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff', border:'none', borderRadius:'4px' }}>Exit</button> */}
            </Grid>
          )
        }
      />
    </>
  );
}
