import React, { useEffect, useState } from 'react';
import { Grid, Box, InputLabel, MenuItem, Select, FormControl, Typography, Button, Avatar, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import CommonUtil from '../../Util/CommonUtils';
import CustomInput from '../../components/CustomInput';
import { useDispatch } from 'react-redux';
import { filterUser, loggedUser, updateUserProfile } from '../../slices/User';
import jwt_decode from 'jwt-decode';
import defaultProfile from '../../assets/defaultProfile.png';
import FileBase64 from 'react-file-base64';
import { REACT_DASHBORD } from '../../constants/ReactEndPoints';
import { useHistory } from 'react-router-dom';
import { MarkEmailUnread } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

export default function EditProfile(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [countryCode, setCountryCode] = React.useState('country_calling_code');
  const [error, setError] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    profilePhoto: '',
    email: '',
    phone: '',
    common: '',
  });
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [profile, setProfile] = React.useState(defaultProfile);
  const [state, setState] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    profilePhoto: '',
    email: '',
    phone: '',
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    companyCode: '',
    lastName: '',
    companyType: '',
    user_name: '',
    roleId: '',
    companyName: '',
    userId: '',
    client_id: '',
    firstName: '',
    lastLoginCompanyId: '',
    companyId: '',
    phone: '',
    roleCode: '',
    roleName: '',
    id: '',
    email: '',
  });
  useEffect(() => {
    if (localStorage.getItem('USER')) {
      setUser({
        ...user,
        ...JSON.parse(localStorage.getItem('USER')),
      });
    }
  }, [localStorage.getItem('USER')]);

  useEffect(() => {
    if (localStorage.getItem('USER')) {
      var userId = JSON.parse(localStorage.getItem('USER')).id;
      dispatch(filterUser({ ids: userId }))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0000') {
            setState({
              ...state,
              ...data.data.users[0],
            });
            if (!CommonUtil.isEmptyString(data.data.users[0].profilePhoto)) {
              setProfile('data:image/jpeg;base64,' + data.data.users[0].profilePhoto);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmptyString(state.phone)) {
      var mobile = state.phone.split('-');
      if (mobile.length > 1) {
        setCountryCode(mobile[0]);
        setPhoneNumber(mobile[1]);
      }
    }
  }, [state.phone]);
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      ...error,
      [name]: '',
      common: '',
    });
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    var payload = {
      ...state,
      phone: CommonUtil.isEmptyString(phoneNumber) ? '' : '+' + countryCode.replace('+', '') + '-' + phoneNumber,
    };

    dispatch(updateUserProfile(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0002') {
          setUser({
            ...user,
            firstName: payload.firstName,
            lastName: payload.lastName,
          });
          var userData = JSON.parse(localStorage.getItem('USER'));
          userData.firstName = payload.firstName;
          userData.lastName = payload.lastName;

          localStorage.removeItem('USER');
          localStorage.setItem('USER', JSON.stringify(userData));
          dispatch(loggedUser());
          enqueueSnackbar(data.message, {
            variant: 'success',
          });
          props.ScreenChangeTo('DefaultProfile', 'Default');
        } else if(data.code === 'UASE0021') {

          setError({
            ...error,
            email: data.message })

        } else if(data.code === 'UASE0022') {

          setError({
            ...error,
            phone: data.message })

        }else {
          data.code !== 'UASE0060' ? setError({ code: t('SIOT00036') }) : setError({ name: t('SIOT00037') });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const isFormValid = () => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({
        ...error,
        firstName: t('SIOT00001'),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(state.lastName)) {
      setError({
        ...error,
        lastName: t('SIOT00001'),
      });
      return false;
    }
    if (CommonUtil.isEmptyString(phoneNumber) && CommonUtil.isEmptyString(state.email)) {
      setError({
        ...error,
        common: t('SIOT00049'),
      });
      return false;
    }
    if (!CommonUtil.isEmptyString(phoneNumber)) {
      if (CommonUtil.isEmptyString(countryCode)) {
        setError({
          ...error,
          phone: t('SIOT00007'),
        });
        return false;
      } else {
        var mobile = '+' + countryCode.replace('+', '') + '-' + phoneNumber;
        if (mobile.length < 10 || mobile.length > 25) {
          setError({
            ...error,
            phone: t('SIOT00008'),
          });
          return false;
        }
      }
    }
    if (!CommonUtil.isEmptyString(state.email)) {
      if (!CommonUtil.isValidEmail(state.email)) {
        setError({
          ...error,
          email: t('SIOT00003'),
        });
        return false;
      }
    }
    return true;
  };

  const handleError = (data) => {};

  const handlePhoneNumberChange = (value) => {
    if (countryCode.length + value.length > 23) {
      return false;
    } else {
      setPhoneNumber(value);
      setError({
        ...error,
        common: '',
        phone: '',
      });
    }
  };

  const FieldFirstName = (key) => (
    <CustomInput
      style={{ minWidth: '70%' }}
      required
      error={!CommonUtil.isEmptyString(error.firstName.trim())}
      name='firstName'
      label={t('SIOT00024')}
      variant='outlined'
      size='small'
      value={state.firstName}
      handleChange={handleChange}
      helperText={error.firstName}
      validation={'alpha-numeric'}
      inputProps={{
        maxLength: 100,
      }}
    />
  );

  const FieldLastName = (key) => (
    <CustomInput
      required
      style={{ minWidth: '70%' }}
      error={!CommonUtil.isEmptyString(error.lastName.trim())}
      name='lastName'
      label={t('SIOT00025')}
      variant='outlined'
      size='small'
      value={state.lastName}
      handleChange={handleChange}
      helperText={error.lastName}
      validation={'alpha-numeric'}
      inputProps={{
        maxLength: 100,
      }}
    />
  );
  const FieldPersonId = (key) => (
    <CustomInput required fullWidth error={error.id} name='id' label={t('SIOT00040')} variant='outlined' size='small' value={state.id} handleChange={(e) => handleChange(e)} />
  );
  const FieldEmail = (key) => (
    <CustomInput
    required
      style={{ minWidth: '70%' }}
      error={!CommonUtil.isEmptyString(error.email.trim())}
      name='email'
      label={t('SIOT00009')}
      variant='outlined'
      size='small'
      value={state.email}
      handleChange={handleChange}
      helperText={error.email}
      validation={'email'}
    />
  );
  const FiledPhoneNumber = () => (
    <Grid container justifyContent={'center'} maxWidth='70%'>
      <Grid item lg={3} marginTop={'14px'} marginBottom={'14px'} paddingRight={'8px'}>
        <PhoneInput
          autoFormat={false}
          countryCodeEditable={true}
          enableSearch='true'
          searchPlaceholder={'search'}
          searchNotFound={'search'}
          onChange={(value, data) => {
            setCountryCode(data.dialCode);
            setError({ ...error, common: '', phone: '' });
          }}
          inputProps={{
            disabled: true,
          }}
          value={countryCode}
          country={CommonUtil.getDefaultCountryCode()}
          placeholder={false}
          specialLabel={t('SIOT00011')}
        />
      </Grid>
      <Grid lg={9}>
        <CustomInput
          type={'text'}
          fullWidth
          style={{ margin: '14px 0px' }}
          autoComplete='off'
          error={!CommonUtil.isEmptyString(error.phone.trim())}
          name='phoneNumber'
          label={t('SIOT00010')}
          value={phoneNumber}
          variant='outlined'
          size='small'
          handleChange={(e) => handlePhoneNumberChange(e.target.value)}
          helperText={error.phone}
          validation='numeric'
        />
      </Grid>
    </Grid>
  );

  const FieldCountry = (key) => (
    <CustomInput required fullWidth error={error.Country} name='Country' label={t('SIOT00011')} variant='outlined' size='small' value={state.Country} handleChange={handleChange} />
  );
  const FieldPriv_state = (key) => (
    <CustomInput fullWidth error={error.state} name='state' label={t('SIOT00039')} variant='outlined' size='small' value={state.state} handleChange={handleChange} />
  );
  const FieldCity = (key) => (
    <FormControl variant='outlined' size='small' fullWidth>
      <InputLabel>{t('SIOT00041')}</InputLabel>
      <Select fullWidth error={error.city} name='city' label={t('SIOT00041')} variant='outlined' size='small' value={state.city} onChange={handleChange}>
        <MenuItem value={'Test'}>{t('Test')}</MenuItem>
      </Select>
    </FormControl>
  );
  const FieldPostcode = (key) => (
    <CustomInput fullWidth error={error.Postcode} name='Postcode' label={t('SIOT00042')} variant='outlined' size='small' value={state.Postcode} handleChange={handleChange} />
  );
  const FieldAddressLineone = (key) => (
    <CustomInput
      size='small'
      fullWidth
      error={error.AddressLineone}
      name='AddressLineone'
      label={t('SIOT00043')}
      variant='outlined'
      value={state.AddressLineone}
      handleChange={handleChange}
      multiline
      rows={2}
    />
  );

  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize, 10);
      if (size <= 3000) {
        setState({
          ...state,
          profilePhoto: file.base64.split(',')[1],
        });
        setProfile(file.base64);
      } else {
        setError({
          ...error,
          common: 'Photos size up to maximum 3 MB',
        });
      }
    } else {
      setError({
        ...error,
        common: 'Only support PNG and JPEG files',
      });
    }
  };

  return (
    <Grid style={{ minHeight: '100%', width: '100%', backgroundColor: '#F4F5F7', overflow: 'auto' }}>
      <form>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '28px 0px' }}>
            <Grid style={{ border: '0.5px solid #474A4E80', padding: '12px', borderRadius: '8px', backgroundColor: '#FFFF' }}>
              <img alt='' src={profile} style={{ height: '59px', width: '59px', borderRadius: '8px' }} />
            </Grid>
            <Grid style={{ margin: '0px 12px' }}>
              <Typography style={{ fontFamily: 'Bahnschrift Light', color: '#97A2B4', paddingBottom: '4px' }}>Photos size up to maximum 3 MB</Typography>
              <IconButton
                style={{
                  fontSize: '18px',
                  color: '#36C96D',
                  border: '0.5px solid #36C96D',
                  padding: '4px 16px',
                  fontStyle: 'none',
                  borderRadius: '8px',
                  fontFamily: 'Bahnschrift',
                }}
                variant='contained'
                component='label'
                color='secondary'
              >
                Add Photo
                <Grid style={{ display: 'none' }}>
                  <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                </Grid>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              {FieldFirstName()}
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldCountry()}
              </Box>
            </Grid> */}
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              {FieldLastName()}
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldPriv_state()}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldPersonId()}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldCity()}
              </Box>
            </Grid> */}

          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              {FieldEmail()}
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldPostcode()}
              </Box>
            </Grid> */}
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              {FiledPhoneNumber()}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <span style={{ color: 'red' }}>{error.common}</span>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                {FieldAddressLineone()}
              </Box>
            </Grid> */}
        </Grid>
        <Grid style={{ position: 'absolute', bottom: '10px', width: `calc(100% - 24px)`, display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            style={{ width: '40%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}
          >
            {' '}
            {'Save'}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
