import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.8em',
      backgroundColor: '#CAD0D9',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFFF',
      outline: '2px solid #FFFF',
    },
  },
}));

export default function InfoDilogBox(props) {
  const ScreenWidth = {
    FullScreen: '100%',
    Default: '314px',
    HalfScreen: '50%',
  };

  const styleDialog = {
    '& .MuiDialog-paper': {
      backgroundColor: '#F4F5F7',
      right: '24px',
      bottom: '24px',
      position: 'absolute',
      boxShadow: 1,
      maxWidth: 'none',
      width: props.size === 'FullScreen' ? ScreenWidth.FullScreen : props.size === 'HalfScreen' ? ScreenWidth.HalfScreen : ScreenWidth.Default,
    },
  };

  return (
    <Dialog sx={styleDialog} open={props.open} onBackdropClick={() => props.close()}>
      <Grid sx={{ overflow: 'auto', padding: '12px', overflowX: 'hidden' }}>{props.children}</Grid>
    </Dialog>
  );
}
