import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, Typography, Button } from '@mui/material';
import OnBordlayout from '../../components/OnBordlayout';
import DilogBox from '../../components/DilogBox';
import CustomInput from '../../components/CustomInput';
import { ReactComponent as Help } from '../../assets/Help.svg';
import CommonUtil from '../../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createCompany, verifyCompany } from '../../slices/Company';
import jwt_decode from 'jwt-decode';
import { REACT_LOGIN } from '../../constants/ReactEndPoints';

export default function CreateOrganization() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [DilogContent, setDilogContent] = React.useState('Family name');
  const [state, setState] = useState({ code: '', name: '' });
  const [error, setError] = useState({ code: '', name: '' });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: '',
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({
        ...error,
        name: t('SIOT00001'),
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({
        ...error,
        code: t('SIOT00001'),
      });
      return;
    }

    dispatch(createCompany(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0006') {
          companyVerify();
        } else {
          data.code !== 'UASE0060' ? setError({ code: t('SIOT00036') }) : setError({ name: t('SIOT00037') });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const companyVerify = () => {
    var payload = {
      companyCode: state.code,
    };
    dispatch(verifyCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.setItem('USER_TOKEN', 'Bearer ' + data.data.access_token);
          localStorage.setItem('REFRESH_TOKEN', 'Bearer ' + data.data.refresh_token);
          const decoded = jwt_decode(data.data.access_token);
          localStorage.setItem('USER', JSON.stringify(decoded));

          history.push({ pathname: '/siot/dashboard', page: 'Dashboard' });
        } else {
          setError(data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <OnBordlayout>
        <Typography style={{ fontSize: '36px', color: '#797979', fontWeight: 'bold', fontFamily: 'Bahnschrift Light' }}>Create organization,</Typography>
        <CustomInput
          type={'text'}
          value={state.name}
          required
          size='small'
          style={{ margin: '14px 0px' }}
          fullWidth
          name='name'
          error={error.name}
          helperText={error.name}
          label='Family name'
          handleChange={handleChange}
          InputProps={{
            endAdornment: <Help onClick={() => setOpen(!open) & setDilogContent('Family name')} />,
          }}
          inputProps={{
            maxLength: 60,
          }}
        />
        <CustomInput
          required
          value={state.code}
          size='small'
          style={{ margin: '14px 0px' }}
          fullWidth
          name='code'
          label='Family code'
          error={error.code}
          helperText={error.code}
          handleChange={handleChange}
          InputProps={{
            endAdornment: <Help onClick={() => setOpen(!open) & setDilogContent('Family code')} />,
          }}
          inputProps={{
            maxLength: 30,
          }}
          validation={'code'}
        />
        <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
          Create
        </Button>
        <Grid container>
          <Grid item md={3} style={{ display: 'flex', justifyContent: 'flex-start', margin: '14px 0px' }}>
            <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
              {' '}
              {t('SIOT00050')}{' '}
            </Typography>
          </Grid>
          <Grid item md={9} style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
            <Typography style={{ color: 'gray', fontSize: '12px' }} onClick={(event) => true}>
              {' '}
              Already have an organization?
            </Typography>
            <Typography onClick={() => history.push('/siot/select-organization')} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
              {' '}
              Select an organization{' '}
            </Typography>
          </Grid>
        </Grid>
      </OnBordlayout>
      <DilogBox open={open} close={(data) => setOpen(false)} HeaderContent={DilogContent}>
        {DilogContent === 'Family code' ? (
          <Grid>
            <Typography>Organization code is the unique code for the system to identify your organization. </Typography>
            <Typography>You can modify the organization name later,</Typography>
            <Typography>but the organization code will not change.</Typography>
            <br />
            <Typography>You can use a combination of letters and numbers to form an organization code.</Typography>
            <Typography>Easy- to-remember organization code will help you find relevant information faster.</Typography>
            <br />
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography>Example : </Typography>
              <Typography style={{ color: '#36c96d' }}> Office964, MyHome001</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography>Organization is one of your family, restaurant, office, working space, etc.</Typography>
            <br />
            <Typography>Inside an organization,</Typography>
            <Typography>you can create some groups, departments.</Typography>
            <Typography>Groups can have family members or some of your employees</Typography>
          </Grid>
        )}
      </DilogBox>
    </>
  );
}
