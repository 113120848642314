import { Button, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { fontSize } from '@mui/system';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomInput from '../../components/CustomInput';
import OnBordlayout from '../../components/OnBordlayout';
import { REACT_FORGOT_PASSWORD_SUCCESS, REACT_LOGIN } from '../../constants/ReactEndPoints';
import { forgotPassword } from '../../slices/User';
import CommonUtil from '../../Util/CommonUtils';

export default function ForgotPassword(props) {
  const { t } = useTranslation();
  const [error, setError] = React.useState({ userName: '' });
  const [userName, setUserName] = React.useState('');
  const [countryCode, setCountryCode] = React.useState(CommonUtil.getLocationDetails('country_calling_code'));
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [userNameType, setUserNameType] = React.useState('Email');
  const [LoginMethod, setLoginMethod] = React.useState('Email');
  let history = useHistory();
  const dispatch = useDispatch();

  const handleError = (data) => {
    if (data.code === 'UASE0003') {
      setError({ userName: t('SIOT00051') });
    } else {
      setError({ userName: data.message });
    }
  };

  useEffect(() => {
    resetFields();
  }, [userNameType]);

  useEffect(() => {
    setError({
      ...error,
      userName: '',
    });
  }, [phoneNumber, countryCode]);

  const handleUsernameType = (event) => {
    setUserNameType(event.target.value);
  };

  const resetFields = () => {
    setUserName('');
    setError({
      userName: '',
    });
    setPhoneNumber('');
    setCountryCode(CommonUtil.getLocationDetails('country_calling_code'));
  };

  const validatePayload = () => {
    if (userNameType === 'Email') {
      if (CommonUtil.isEmptyString(userName)) {
        setError({
          userName: t('SIOT00001'),
        });
        return false;
      }
      if (!CommonUtil.isValidEmail(userName)) {
        setError({
          userName: t('SIOT00003'),
        });
        return false;
      }
    } else {
      if (CommonUtil.isEmptyString(phoneNumber)) {
        setError({
          userName: t('SIOT00001'),
        });
        return false;
      } else {
        if (CommonUtil.isEmptyString(countryCode)) {
          setError({
            userName: t('SIOT00007'),
          });
          return false;
        } else {
          var mobile = '+' + countryCode.replace('+', '') + '-' + phoneNumber;
          if (mobile.length < 10 || mobile.length > 25) {
            setError({
              userName: t('SIOT00008'),
            });
            return false;
          } else {
            setUserName(mobile);
          }
        }
      }
    }
    return true;
  };

  const handleChange = (event) => {
    setUserName(event.target.value);
    setError({
      userName: '',
    });
  };

  const handleSubmit = () => {
    var emailOrPhone = userNameType === 'Email' ? userName : '+' + countryCode + '-' + phoneNumber;
    if (validatePayload()) {
      dispatch(forgotPassword(emailOrPhone))
        .unwrap()
        .then((data) => {
          if (data.code == 'SIOTI0001') {
            history.push(REACT_FORGOT_PASSWORD_SUCCESS);
          } else {
            handleError(data);
          }
        });
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (countryCode.length + value.length > 23) {
      return false;
    } else {
      setPhoneNumber(value);
    }
    setError({ userName: '' });
  };

  const FiledPhoneNumber = () => (
    <Grid container>
      <Grid item lg={4} sm={12} marginTop={'14px'} marginBottom={'14px'} paddingRight={'8px'}>
        <PhoneInput
          autoFormat={false}
          countryCodeEditable={true}
          enableSearch='true'
          searchPlaceholder={'search'}
          searchNotFound={'search'}
          onChange={(value, data) => setCountryCode(data.dialCode)}
          inputProps={{
            disabled: true,
          }}
          value={countryCode}
          country={CommonUtil.getDefaultCountryCode()}
          placeholder={false}
          specialLabel={t('SIOT00011') + ' *'}
        />
      </Grid>
      <Grid lg={8} sm={12}>
        <CustomInput
          type={'text'}
          required
          style={{ margin: '14px 0px' }}
          autoComplete='off'
          fullWidth
          error={!CommonUtil.isEmptyString(error.userName)}
          name='phoneNumber'
          label={t('SIOT00010')}
          value={phoneNumber}
          variant='outlined'
          size='small'
          handleChange={(e) => handlePhoneNumberChange(e.target.value)}
          helperText={error.userName}
          validation='numeric'
        />
      </Grid>
    </Grid>
  );

  const FieldEmail = (key) => (
    <CustomInput
      type={'text'}
      required
      style={{ margin: '14px 0px' }}
      autoComplete='off'
      fullWidth
      error={!CommonUtil.isEmptyString(error.userName)}
      name='userName'
      label={t('SIOT00009')}
      value={userName}
      variant='outlined'
      size='small'
      handleChange={handleChange}
      helperText={error.userName}
      validation='email'
    />
  );

  const ForgotPassword = (key) => (
    <Grid>
      <Grid>
        <Typography style={{ color: '#304669', fontSize: '36px' }}>{t('SIOT00018')}</Typography>
        <Typography style={{ color: '#304669', fontSize: '17px', opacity: '50%', margin: '14px 0px' }}>
          System will send a link to reset your password to the email or mobile used in your account, please check it carefully.
        </Typography>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'space-evenly', margin: '14px 0px' }}>
        <Grid
          onClick={() => {
            setLoginMethod('Email');
          }}
          style={{ borderBottom: LoginMethod === 'Email' ? '4px solid #36c96d' : '' }}
        ></Grid>
        <Grid
          onClick={() => {
            setLoginMethod('Phone');
          }}
          style={{ borderBottom: LoginMethod === 'Phone' ? '4px solid #36c96d' : '' }}
        ></Grid>
      </Grid>

      <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={userNameType} onChange={handleUsernameType}>
        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Email' control={<Radio color='success' />} label={t('SIOT00015')} color='success' />

        <FormControlLabel sx={{ '& .Mui-checked': { color: '#36c96d' } }} value='Phone' control={<Radio color='success' />} label={t('SIOT00016')} color='success' />
      </RadioGroup>

      {userNameType === 'Email' ? FieldEmail() : FiledPhoneNumber()}

      <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
        {t('SIOT00045')}
      </Button>

      <Grid style={{ flexDirection: 'row', display: 'flex' }}></Grid>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
        <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
          {' '}
          {t('SIOT00050')}{' '}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <OnBordlayout>
      <Grid style={{ height: '100%' }}>{ForgotPassword()}</Grid>
    </OnBordlayout>
  );
}
