import React, { useState, useEffect } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@material-ui/core";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
export const datePickerStyles = makeStyles(() => ({
  root: {
    "& .css-l0iinn": {
      position: "absolute",
      zIndex: "1000",
      left: "50%",
      marginLeft: "-60px",
      "&>:first-child": {
        marginRight:'5px',
        "&:after": {
          content: "','",
          position:'absolute',
          right:'-3px',
          bottom:"0px",
        },
      },
    },

    "& .css-k008qs": {
      width: "100%",
      position: "absolute",
      display: "flex",
      justifyContent: "space-between",
      left: "0px",
    },
    "& .Mui-selected": {
      backgroundColor: "#36C96D !important",
    },
  },
  paper: {
    position: "fixed !important",
    right: "20px",
    bottom: "20px",
  },

  foot: {
    position: "relative",
    top: "-25px",
    height: "30px",
    width: "calc(100% - 30px)",
    marginLeft: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "self-start",
    backgroundColor: "#ffffff",
  },
  okButton: {
    backgroundColor: "#36C96D !important",
    width: "120px",
    textTransform: "none !important",
  },
  cancleButton: {
    width: "120px",
    color: "#304669 !important",
    borderColor: "#707070 !important",
    textTransform: "none !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ZkDatePicker(props) {
  const classs = datePickerStyles();
  const [value, setValue] = React.useState(props.date || new Date());
  const [dialogOpen, setDialogOpen] = React.useState(props.open);
  let open = props.open;
  let date = props.date;
  useEffect(() => {
    setDialogOpen(open);
  }, [open]);
  useEffect(() => {
    setValue(date);
  }, [date]);
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const handleOk = () => {
    if (props.handleOk) {
      props.handleOk(value);
    }
  };
  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      classes={{
        paper: classs.paper,
      }}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          classes={{
            root: classs.root,
          }}
          showToolbar={false}
          displayStaticWrapperAs="desktop"
          disableHighlightToday={true}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => {
            return "";
          }}
        />

        <div className={classs.foot}>
          <Button
            onClick={() => {
              handleOk();
            }}
            className={classs.okButton}
            variant="contained"
          >
            OK
          </Button>
          <Button
            onClick={() => {
              handleClose();
            }}
            className={classs.cancleButton}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      </LocalizationProvider>
    </Dialog>
  );
}
