import * as React from "react";
import { Dialog } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { showLoad,showMessage } from "../slices/Common";
let timeout;
function debounce(func, wait) {
  return function () {
    var args = arguments;
    var content = this;
    clearTimeout(timeout);
    timeout = setTimeout(func.bind(content, args), wait);
  };
}

export const potint = makeStyles(() => ({
  root: {
    animation: "$myEffect 2000ms linear infinite",
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export default function LoadingDialog(props) {
  const dispatch = useDispatch();
  const store = useSelector((state) => {
    return state.common;
  });
  let color = props.color || "#36c96d";
  const classs = potint();
  let closeLoad = () => {
    if (store.showLoad) {
      dispatch(
        showLoad({
          showLoad: false,
          loadingMessage: "",
        })
      );
      dispatch(showMessage({
        showMessage: true,
        messageType: "error",
        message: 'Loading Error',
      }))
      
    }
  };
  debounce(closeLoad, 30000)()
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "hidden",
        },
      }}
      open={store.showLoad}
    >
      <svg
        t="1651029280478"
        className={classs.root}
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2634"
        width="100"
        height="100"
      >
        <path
          d="M510.340414 154.58869c46.786207 0 91.806897 8.792276 135.168 26.412138a353.986207 353.986207 0 0 1 118.642758 78.636138 355.822345 355.822345 0 0 1 78.636138 118.007172c17.655172 42.372414 26.58869 86.863448 26.871173 133.331862a361.931034 361.931034 0 0 1-24.681931 134.002759 40.041931 40.041931 0 0 1-5.04938 9.675034 51.906207 51.906207 0 0 1-5.684965 7.485793c-8.121379 8.086069-18.255448 12.040828-30.402207 11.793655-12.146759-0.247172-22.033655-4.13131-29.66069-11.793655-12.429241-12.888276-15.289379-29.060414-8.580413-48.622345a315.321379 315.321379 0 0 0 28.566069-121.856 301.762207 301.762207 0 0 0-21.398069-123.374344 308.824276 308.824276 0 0 0-70.761931-108.649931 312.673103 312.673103 0 0 0-132.272552-79.307035 316.204138 316.204138 0 0 0-149.362759-7.13269 313.202759 313.202759 0 0 0-138.699034 64.335449 351.337931 351.337931 0 0 1 108.649931-62.216828 351.832276 351.832276 0 0 1 120.019862-20.727172zM217.970759 350.490483c12.429241-0.953379 22.916414 2.824828 31.461517 11.405241 6.673655 6.638345 10.769655 13.912276 12.217379 21.786483 1.377103 7.874207 0.211862 16.772414-3.601655 26.835862a311.119448 311.119448 0 0 0-28.283586 121.856 315.250759 315.250759 0 0 0 21.115586 123.303724 308.18869 308.18869 0 0 0 70.761931 108.649931 311.825655 311.825655 0 0 0 132.978759 79.377655 317.863724 317.863724 0 0 0 149.433379 7.168 315.321379 315.321379 0 0 0 138.699034-64.370758 350.808276 350.808276 0 0 1-158.684689 75.776 360.48331 360.48331 0 0 1-171.572966-7.168 353.209379 353.209379 0 0 1-152.999724-90.782897 356.069517 356.069517 0 0 1-105.118896-251.268414 346.712276 346.712276 0 0 1 25.070344-134.002758 24.152276 24.152276 0 0 1 4.978759-10.028138c1.942069-2.860138 3.813517-5.225931 5.755586-7.13269 7.062069-7.662345 16.384-11.405241 27.789242-11.405241z"
          fill={color}
          p-id="2635"
        ></path>
      </svg>

      <div
        style={{
          textAlign: "center",
          color: color,
        }}
      >
        {store.loadingMessage}
      </div>
    </Dialog>
  );
}
