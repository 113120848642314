import CreateChanelStaticBg from '../assets/createchanelbg.png'
import DynamicLoaderLogo from '../assets/DynamicLoader.gif'


export default function DynamicLoader(){
    return(
        <div style={{ backgroundImage: `url(${CreateChanelStaticBg})`, height: '100%' }}>
            <div style={{ position: 'absolute', top: '60%', left: '55%' }}>
                <img src={DynamicLoaderLogo} alt="loading..." /></div>
            <div style={{ position: 'absolute', left: '50%', top: '70%' }}>Creating security video channel</div>

        </div>
    );
}