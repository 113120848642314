
import { ReactComponent as Subscriptionsurlogo } from '../../assets/dashboard/subscriptionsurlogo.svg';
import { ReactComponent as Devicesurlogo } from '../../assets/dashboard/devicesurlogo.svg';
import { REACT_SURVEILLANCE_DEVICE_LIST } from '../../constants/ReactEndPoints';
import { REACT_SURVEILLANCE_SUBSCRIPTION__ORDERLISTANDBILLING, REACT_URL_SURVEILLANCE_SUBSCRIPTION } from '../../constants/ReactEndPoints';

const sideBarMenuList=
    [
      {
        id: '1',
        name: 'Device',
        Icon: Devicesurlogo,
        items: [],
        link: REACT_SURVEILLANCE_DEVICE_LIST,
      },
      {
        id: '2',
        name: 'Subscription & service',
        Icon: Subscriptionsurlogo,
        items: [
          {
            id: '2.1',
            name: 'Subscription',
            link: `${REACT_URL_SURVEILLANCE_SUBSCRIPTION}`,
          },
          {
            id: '2.2',
            name: 'Order list and billing',
            link: `${REACT_SURVEILLANCE_SUBSCRIPTION__ORDERLISTANDBILLING}`,
          }
        ],
      },
    ];

export default sideBarMenuList;
