import api from "../config/http-common";


const getAllSite = () => {
  return api.securedAxios().get('/site/search?pageNumber=1&pageSize=999');
};

const getAllZone = (payload) => {
    return api.securedAxios().get('/zone/search?pageNumber=1&pageSize=999' +'&siteId='+ payload);
  };
const SiteAndZoneService = {
  getAllSite,
getAllZone,
}
export default SiteAndZoneService;