import { SignalingClient, Role } from "amazon-kinesis-video-streams-webrtc";
const getEnumerateDevices = async () => {
  try {
    const devices = await global.navigator.mediaDevices.enumerateDevices();
    return devices;
  } catch (errMsg) {
    return [];
  }
};

const getNavigator = () => {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // 首先，如果有getUserMedia的话，就获得它
      var getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.getUserMedia ||
        navigator.msGetUserMedia;
      // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
      if (!getUserMedia) {
        return Promise.reject(
          new Error("getUserMedia is not implemented in this browser")
        );
      }

      // 否则，为老的navigator.getUserMedia方法包裹一个Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
  return navigator;
};

export default class MasterSDK {
  _endpointsByProtocol = null;
  _peerConnection = null;
  signalingClient = null;
  localStream = null;
  isSetting = false;
  channelARN = "";
  role = "";
  region = "";
  accessKeyId = "";
  secretAccessKey = "";
  iceServers = [];
  localView = null;
  remoteView = null;
  sessionToken = "";
  config = {};
  peerConnectionList = {};

  constructor(config,localView,remoteView) {
    this.peerConnectionList = {};
    this.remoteView = remoteView;
    this.localView = localView;
    this.channelARN = config.signalingChannel.channelARN;
    this.role = Role.MASTER;
    let tempCredential = config.tempCredential;
    this.region = tempCredential.region;
    this.accessKeyId = tempCredential.accessKeyId;
    this.sessionToken = tempCredential.sessionToken;
    this.secretAccessKey = tempCredential.secretAccessKey;
    this._endpointsByProtocol = config.endpoints;
    const iceServers = [];
    config.iceServers.forEach((iceServer) => {
      if (iceServer.password) {
        iceServers.push({
          urls: iceServer.uris,
          username: iceServer.username,
          credential: iceServer.password,
        });
      } else {
        iceServers.push(iceServer);
      }
    });
    this.iceServers = iceServers;
    this.config = config;
  }

  //创建信令通道客户端
  createWebRTCSignalingClient() {
    let signalingClient = new SignalingClient({
      channelARN: this.channelARN,
      channelEndpoint: this._endpointsByProtocol.WSS,
      role: this.role,
      region: this.region,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
        sessionToken: this.sessionToken,
      },
    });

    this.signalingClient = signalingClient;
    return this;
  }

  async getMedia() {
    const resolution = { width: { ideal: 640 }, height: { ideal: 400 } };

    const devices = await getEnumerateDevices();
    let constraints = devices.reduce(
      (info, device) => {
        if (!device) {
          return info;
        }
        if ("videoinput" === device.kind) {
          return { video: resolution, audio: info.audio };
        }
        if ("audioinput" === device.kind) {
          return { audio: true, video: info.video };
        }
        return info;
      },
      { video: false, audio: false }
    );

    let navigator = getNavigator();
    let promise = navigator.mediaDevices.getUserMedia(constraints);
    promise
      .then((stream) => {
        this.localStream = stream;
        if (this.localView) {
          this.localView.srcObject = stream;
        }
      })
      .catch((err) => {
        console.error(err.name + ": " + err.message);
      });
  }

  addSignalingClientEventListeners() {
    if (this.signalingClient === null) {
      console.error("请先创建信令客户端");
      return false;
    }

    this.signalingClient.on("open", async () => {
      console.log("[MASTER] Connected to signaling service");
    });

    this.signalingClient.on("sdpOffer", async (offer, remoteClientId) => {
      console.log("[MASTER] Received SDP offer from client: " + remoteClientId);

      const configuration = {
        iceServers: this.iceServers,
        iceTransportPolicy: "relay",
      };
      const peerConnection = new RTCPeerConnection(configuration);
      peerConnection.remoteClientId = remoteClientId;
      this.peerConnectionList[remoteClientId] = peerConnection;

      peerConnection.addEventListener("icecandidate", ({ candidate }) => {
        if (candidate) {
          console.log(
            "[MASTER] Generated ICE candidate for client: " + remoteClientId
          );
          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          this.signalingClient.sendIceCandidate(candidate, remoteClientId);
        } else {
          console.log(
            "[MASTER] All ICE candidates have been generated for client: " +
              remoteClientId
          );

          this.signalingClient.sendSdpAnswer(
            peerConnection.localDescription,
            remoteClientId
          );
        }
      });

      // As remote tracks are received, add them to the remote view
      peerConnection.addEventListener("track", (event) => {
        if (this.remoteView === null || this.remoteView === undefined) {
          return;
        }
        this.remoteView.srcObject = event.streams[0];
      });

      
      if (this.localStream) {
        this.localStream
          .getTracks()
          .forEach((track) => peerConnection.addTrack(track, this.localStream));
      }

      await peerConnection.setRemoteDescription(offer);

      // Create an SDP answer to send back to the client
      console.log("[MASTER] Creating SDP answer for client: " + remoteClientId);
      await peerConnection.setLocalDescription(
        await peerConnection.createAnswer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        })
      );

      this.signalingClient.sendSdpAnswer(
        peerConnection.localDescription,
        remoteClientId
      );
    });

    this.signalingClient.on(
      "iceCandidate",
      async (candidate, remoteClientId) => {
        console.log(
          "[MASTER] Received ICE candidate from client: " + remoteClientId
        );
        const peerConnection = this.peerConnectionList[remoteClientId];
        if (peerConnection !== undefined) {
          peerConnection.addIceCandidate(candidate);
        }
      }
    );

    this.signalingClient.on("close", () => {
      console.log("[MASTER] Disconnected from signaling channel");
    });

    this.signalingClient.on("error", () => {
      console.error("[MASTER] Signaling client error");
    });
  }

  init() {
    this.createWebRTCSignalingClient();
    this.getMedia();
    this.addSignalingClientEventListeners();
    this.signalingClient.open();
  }
}
