import React, { useEffect, useState } from 'react';
import { Grid, Link, Divider, Button, Typography, Checkbox, Radio, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as Logo } from '../../assets/UserIcon.svg';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { filterCompany, verifyCompany } from '../../slices/Company';
import CommonUtil from '../../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import OverflowTip from './tooltip';
import jwt_decode from 'jwt-decode';
import { loggedUser } from '../../slices/User';
import { useSnackbar } from 'notistack';

function SwitchOrganization(props) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(filterCompany({}));
    dispatch(loggedUser());
  }, []);

  const history = useHistory();
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const store = useSelector((state) => state.company);
  const logged = useSelector((state) => state.user.logged);
  const [open, setOpen] = React.useState(false);
  const [msg, setmsg] = React.useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });
  useEffect(() => {
    if (!CommonUtil.isEmptyString(logged)) setCompany(logged.companyCode);
  }, [logged]);
  const handleClick = (data) => {
    setmsg(data);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(company)) {
      setError(t('SIOT00029'));
      return;
    }
    var payload = {
      companyCode: company,
    };
    dispatch(verifyCompany(payload))
      .unwrap()
      .then((data) => {
        if (data.code === 'UASI0011') {
          localStorage.setItem('USER_TOKEN', 'Bearer ' + data.data.access_token);
          localStorage.setItem('REFRESH_TOKEN', 'Bearer ' + data.data.refresh_token);
          const decoded = jwt_decode(data.data.access_token);
          localStorage.setItem('USER', JSON.stringify(decoded));
          enqueueSnackbar(data.message, {
            variant: 'success',
          });
          props.close();
        } else {
          setError(data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const SwitchOrganizationCard = {
    borderRadius: '6px',
    backgroundColor: '#FFFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    margin: '8px 0px',
    justifyContent: 'space-evenly',
    width: '98%',
  };

  return (
    <Grid style={{ height: '100%', width: '100%', backgroundColor: '#F4F5F7' }}>
      <Typography style={{ fontWeight: 'bold', fontFamily: 'Bahnschrift Light', color: '#97A2B4' }}>
        You have created multiple organizations in the system, select an organization and switch to that organization. Your operations, such as member modification, device
        addition, etc, are only valid for your selected organization.
      </Typography>
      <Divider />
      <br />
      <Grid style={{ maxHeight: '75%', width: '100%', backgroundColor: '#F4F5F7', overflow: 'auto' }}>
        {store.map((item, index) => (
          <Grid
            container
            onClick={() => {
              setCompany(item.code);
            }}
            style={{
              ...SwitchOrganizationCard,
              ...{
                backgroundColor: item.code === company ? '#36C96D1A' : '#FFFF',
                border: item.code === company ? '#7fff00 0.5px solid' : ' #F4F5F7 0.5px solid',
              },
            }}
          >
            <Radio checked={company === item.code} onChange={() => setCompany(item.code)} sx={{ color: '#36C96D', padding: '0px', '&.Mui-checked': { color: '#36C96D' } }} />
            <Grid style={{ margin: '2px' }}>
              <Logo />
            </Grid>
            <Grid>
              <OverflowTip
                value={item.name.toUpperCase()}
                style={{
                  fontFamily: 'Bahnschrift ',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '178px',
                  fontSize: '18px',
                  color: '#304669',
                  margin: '4px 4px',
                }}
              />
              <OverflowTip
                value={'Company code:' + item.code}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '178px',
                  fontSize: '14px',
                  fontFamily: 'Bahnschrift Light',
                  color: '#304669',
                  margin: '4px 4px',
                }}
              />
              {/* <Typography style={{ fontSize: '18px', color: '#304669', wordWrap: 'break-word', width: '190px' }}>{item.name.toUpperCase()} </Typography> */}
              {/* <Typography style={{ fontSize: '14px', color: '#304669', wordWrap: 'break-word', width: '190px' }}> {'Company code:'+item.code}</Typography> */}
              <Typography style={{ fontSize: '14px', color: '#304669', margin: '4px' }}>User Role: Owner</Typography>
            </Grid>
            <Grid>
              <IconButton component='span'>
                <MoreVertIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid style={{ position: 'absolute', bottom: '10px', width: `calc(100% - 24px)` }}>
        <Button onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff' }}>
          {'Switch'}
        </Button>
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
export default SwitchOrganization;
