import React from 'react';
import { Button, Grid, Typography, Box} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomInput from "../../components/CustomInput";
import OnBordlayout from "../../components/OnBordlayout";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {REACT_LOGIN} from '../../constants/ReactEndPoints';
import CommonUtil from '../../Util/CommonUtils';
import { userActivation } from '../../slices/User';
import { useDispatch } from 'react-redux';
import UserConfirmMessage from '../../components/UserConfirmMessage';



export default function UserActivation(){
    const { t } = useTranslation();
    const [error, setError] = React.useState({ password: '', confirmPassword: '', common: '' });
    const [payload, setPayload] = React.useState({ code: '', password: '', confirmPassword: '' });
    let history = useHistory();
    const dispatch = useDispatch();
    const [visibility, setVisibility] = React.useState(true);
    const [visibilityCP, setVisibilityCP] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    var urlValue = window.location.href;
  var url = new URL(urlValue);
  var codeData = url.searchParams.get('code');

    const handleChange = (event) => {
        const name = event.target.name;
        setPayload({
          ...payload,
          [name]: event.target.value,
        });
        setError({
          ...error,
          [name]: '',
        });
      };

      const validatePayload = () =>{
        if(CommonUtil.isEmptyString(payload.password)){
          setError({
            ...error,
            password: t('SIOT00001'),
          });
          return false;
        }
        if(CommonUtil.isEmptyString(payload.confirmPassword)){
          setError({
            ...error,
            confirmPassword: t('SIOT00030'),
          });
          return false;
        }
        return true;
      };

      const handleSubmit=() => {
        if (validatePayload()) {
          var state = {
            ...payload,
            code: codeData,
          };

          dispatch(userActivation(state))
        .unwrap()
        .then((data) => {
          if (data.code === 'UASI0017') {
            setSuccess(true);
          } else {
            setError({
              ...error,
              common: data.message,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
        }
      }

    const FieldConfirmPassword = (key) => (
        <CustomInput
          required
          type={visibility ? 'password' : 'text'}
          style={{ marginBottom: '14px' }}
          autoComplete='off'
          fullWidth
          error={error.password}
          name='password'
          label={t('Create new password')}
          value={payload.password}
          variant='outlined'
          size='small'
          handleChange={handleChange}
          helperText={error.password}
          validation='password'
          InputProps={{
            endAdornment: visibility ? (
              <VisibilityOffIcon
                onClick={() => {
                  setVisibility(!visibility);
                }}
              />
            ) : (
              <VisibilityIcon
                onClick={() => {
                  setVisibility(!visibility);
                }}
              />
            ),
          }}
        />
      );
    
      const FieldReConfirmPassword = (key) => (
        <CustomInput
          required
          type={visibilityCP ? 'password' : 'text'}
          style={{ marginBottom: '14px' }}
          autoComplete='off'
          fullWidth
          error={error.confirmPassword}
          name='confirmPassword'
          label={t('Confirm new password again')}
          value={payload.confirmPassword}
          variant='outlined'
          size='small'
          handleChange={handleChange}
          helperText={error.confirmPassword}
          validation='password'
          InputProps={{
            endAdornment: visibilityCP ? (
              <VisibilityOffIcon
                onClick={() => {
                  setVisibilityCP(!visibilityCP);
                }}
              />
            ) : (
              <VisibilityIcon
                onClick={() => {
                  setVisibilityCP(!visibilityCP);
                }}
              />
            ),
          }}
        />
      );
    
    const UserActivation = () =>(
        <Grid>
            <Grid>
                <Typography style={{color: '#304669', fontSize: '36px'}}>User Activation</Typography>
            </Grid>
            {FieldConfirmPassword()}
            <br/>
            {FieldReConfirmPassword()}
            <Button  onClick={() => handleSubmit()} style={{ width: '100%', backgroundColor: '#36c96d', height: '48px', color: '#fff'}}>
            {t('Activate User')}
            </Button>
            <Grid style={{ display: 'flex', justifyContent: 'flex-end', margin: '14px 0px' }}>
        <Typography onClick={() => history.push({ pathname: REACT_LOGIN })} style={{ color: '#36c96d', fontSize: '12px', cursor: 'pointer', margin: '0px 4px' }}>
          {' '}
          {t('SIOT00050')}{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='center' m={1} p={1}>
          <span style={{ color: 'red' }}>{error.common}</span>
        </Box>
      </Grid>
        </Grid>
    )
    return(
      <>
      {success ? (
        <UserConfirmMessage header='Confirmation,' message='User activated successfully' />
      ) : (
      <OnBordlayout>
        <Grid style={{height: '100%'}}>{UserActivation()}</Grid>
      </OnBordlayout>
      )};
      </>
    )
  

}